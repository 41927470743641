import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../state';
import appTheme from "../../../../app-theme.json";


const DeleteImage = ({ onDeleteImage }) => {

    const dispatch = useDispatch();
    const { deleteImage } = bindActionCreators(actionCreator, dispatch);
    const showModal = useSelector((state) => state.deleteImage);

    const handleClose = () => { deleteImage(false); }

    const deleteHandler = () => {
        onDeleteImage();
        handleClose();
    }

    return (
        <Modal show={showModal} onHide={handleClose} className='delete_image_modal'>
            <Modal.Header closeButton>
                <Modal.Title>{appTheme.galleryDetails.deleteModalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{appTheme.galleryDetails.deleteModalParagraph}</Modal.Body>
            <Modal.Footer>
                <Button size='sm' variant="success" onClick={handleClose}>{appTheme.galleryDetails.deleteModalCancelButton}</Button>
                <Button size='sm' variant="danger" onClick={deleteHandler}>{appTheme.galleryDetails.deleteModalConfirmButton}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteImage
