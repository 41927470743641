import React from 'react';
import "../../styles/common/metro-cities.scss";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaCaretRight } from "react-icons/fa";
import appTheme from "../../../app-theme.json";


const MetroCities = () => {

    return (
        <div className='cities_sec section_padding'>
            <Container>
                <div className="main_title">
                    <h1 className="title">{appTheme.metroCities.mainTitle}</h1>
                    <p className="subtitle">{appTheme.metroCities.mainSubTitle}</p>
                </div>
                <Row>
                    {
                        appTheme.metroCities.citiesData.map((item) => {
                            return (
                                <Col md={3} className='mb-3 mb-md-0' data-aos="fade-up" key={item.id}>
                                    <ul className="cities_link_box">
                                        <li>
                                            <h4 className='cities_title'>{item.title} <FaCaretRight /></h4>
                                            {item.cityLink.map((items) => {
                                                return (
                                                    <Link className='cities_link' key={items.id} to={items.path}>{items.title}</Link>
                                                )
                                            })}
                                        </li>
                                    </ul>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}

export default MetroCities;
