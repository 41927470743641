import axios from "axios";
import AppSetting from '../../../appConfig.json';
import { httpHeaders } from '../../utils/http-requests';
import { errorHandler } from "../validation/error-handler";


export const IsExistingNumber = async (payload, isLoader) => {
    isLoader(true);
    try {
        const url = AppSetting.SERVER_URL + "/Authentication/IsExistingNumber";
        const res = await axios.post(url, payload, httpHeaders);
        isLoader(false);
        return res.data;
    } catch (error) {
        isLoader(false);
        return errorHandler(error)
    }
}

export const SentOtp = async (payload, isLoader) => {
    isLoader(true);
    try {
        const url = AppSetting.SERVER_URL + "/Authentication/SentOtp";
        const res = await axios.post(url, payload, httpHeaders);
        isLoader(false);
        return res.data;
    } catch (error) {
        isLoader(false);
        return errorHandler(error)
    }
}

export const ValidateOtpAndLogin = async (payload, isLoader) => {
    isLoader(true);
    try {
        const url = AppSetting.SERVER_URL + "/Authentication/ValidateOtpAndLogin";
        const res = await axios.post(url, payload, httpHeaders);
        isLoader(false);
        return res.data;
    } catch (error) {
        isLoader(false);
        return errorHandler(error)
    }
}

export const CredentialLogin = async (payload, isLoader) => {
    isLoader(true);
    try {
        const url = AppSetting.SERVER_URL + "/Authentication/CredentialLogin";
        const res = await axios.post(url, payload, httpHeaders);
        isLoader(false);
        return res.data;
    } catch (error) {
        isLoader(false);
        return errorHandler(error)
    }
}


export const Register = async (payload, isLoader) => {
    isLoader(true);
    try {
        const url = AppSetting.SERVER_URL + "/Authentication/Register";
        const res = await axios.post(url, payload, httpHeaders);
        isLoader(false);
        return res.data;
    } catch (error) {
        isLoader(false);
        return errorHandler(error)
    }
}