import React from "react";
import "../../../styles/others/faqs.scss";
import appTheme from "../../../../app-theme.json"
import Banner from "../../../components/common/banner";
import FAQsSection from "./components/faqs-section";
import BottomTopButton from "../../../components/common/bottom-top-button";

export const FAQs = () => {

    const addFAQsComponent = (FAQsComponentItem) => {
        switch (FAQsComponentItem.componentName) {
            case "Banner":
                return { ...FAQsComponentItem, component: <Banner bannerContent={appTheme.FAQsPage.FAQsBannerContents} /> }
            case "FAQsSection":
                return { ...FAQsComponentItem, component: <FAQsSection /> }
            case "BottomTopButton":
                return { ...FAQsComponentItem, component: <BottomTopButton /> }
            default:
                break;
        }
    }

    let FAQsComponents = appTheme.FAQsPage.FAQsComponents.map((FAQsComponentItem) => addFAQsComponent(FAQsComponentItem));
    FAQsComponents.sort((a, b) => a.position - b.position);

    return <>
        {
            FAQsComponents.map((item) => {
                if (item && item.isPublished)
                    return (
                        <section key={item.position}>{item.component}</section>
                    )
            })
        }
    </>
};
