import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Modal, Button, Spinner, Row, Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { initialRemoveTenant } from '../../../../helper/profile/property-listing';
import { selectReasonSchema, enterReasonSchema } from '../../../../helper/validations/profile/property-listing/remove-tenant';
import { MarkDelete } from '../../../../services/api/tenants';
import { toast } from 'react-toastify';
import { toastStyle } from '../../../../utils/http-requests';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../../state';
import isTokenExpired from '../../../../utils/jwt-auth';
import appTheme from "../../../../../app-theme.json";

const RemoveTenantConfirmation = ({ tenant }) => {

    const [seleactReasonId, setSeleactReasonId] = useState("");
    const { removeTenant, setRemoveTenant } = tenant;

    const hideModal = () => { setRemoveTenant({ showRemoveTenant: false, listingItemId: "" }); resetForm(); }

    const dispatch = useDispatch();
    const { isLoader, redirectModal } = bindActionCreators(actionCreator, dispatch);
    const showLoader = useSelector((state) => state.isLoader);

    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setValues, resetForm } = useFormik({
        initialValues: initialRemoveTenant,
        validationSchema: parseInt(seleactReasonId) === 3 ? enterReasonSchema : selectReasonSchema,
        onSubmit: async (values) => {
            const response = await MarkDelete(removeTenant.listingItemId, isLoader, isTokenExpired, redirectModal);
            if (response != null) {
                if (response.isSuccess) { hideModal(); }
                else { response.errorMessages.forEach((error) => { toast.error(error.message, toastStyle) }) }
            }
        }
    });

    const selectreasonHandler = (e) => {
        setSeleactReasonId(e.target.value);
        setValues({ ...values, reasonId: e.target.value });
    }

    const { title, selectReason, enterReason, cancelButton, okButton } = appTheme.myListingPage.property.propertyTenant.removeTenant;

    return (
        <Modal show={removeTenant.showRemoveTenant} onHide={hideModal} className="confirmation_modal remove_tenant_modal" centered>
            <Modal.Header closeButton><p className='title'>{title}</p></Modal.Header>
            <Modal.Body>
                <Form className="remove_tentant_form">
                    <Form.Group className="mb-3" >
                        <Form.Label>{selectReason.label}</Form.Label>
                        <Form.Select size="sm" name="reasonId" value={values.reasonId} onChange={selectreasonHandler} onBlur={handleBlur}>
                            <option value="">{selectReason.label}</option>
                            {
                                selectReason.selectReasonValues.map((reasonItem) => {
                                    return (
                                        <option key={reasonItem.selectReasonId} value={reasonItem.selectReasonValue}>{reasonItem.selectReasonType}</option>
                                    )
                                })
                            }
                        </Form.Select>
                        {errors.reasonId && touched.reasonId ? (<p className="form-error">{errors.reasonId}</p>) : null}
                    </Form.Group>
                    {parseInt(values.reasonId) === 3 &&
                        <Form.Group className="mb-3" >
                            <Form.Label>{enterReason.label}</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Enter reason" name="otherReason" value={values.otherReason} onChange={handleChange} onBlur={handleBlur} />
                            {errors.otherReason && touched.otherReason ? (<p className="form-error">{errors.otherReason}</p>) : null}
                        </Form.Group>
                    }
                    <Form.Group className="mb-3" >
                        <Form.Check
                            name="confirmReason" onChange={handleChange} onBlur={handleBlur}
                            value="1"
                            type="checkbox"
                            id="Are you sure ? you want to remove tenant"
                            label="Are you sure ? you want to remove tenant"
                        />
                        {errors.confirmReason && touched.confirmReason ? (<p className="form-error">{errors.confirmReason}</p>) : null}
                    </Form.Group>
                    <div className="confirmation_btn">
                        <Button size="sm" className="cancel" onClick={hideModal}>{cancelButton}</Button>
                        <Button size="sm" className="confirm" onClick={handleSubmit}>
                            {showLoader ? <Spinner animation="border" className='spinner_sm' /> : <>{okButton}</>}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default RemoveTenantConfirmation;
