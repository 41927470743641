import React, { useEffect, useState } from 'react';
import "../styles/common/property-layout.scss";
import "../styles/common/addEdit-property.scss";
import { Container } from 'react-bootstrap';
import { NavLink, Outlet } from "react-router-dom";
import RouteLinks from '../utils/route';
import { FaCamera, FaHandshake, FaMapMarkerAlt, FaWallet, FaUniversity } from "react-icons/fa";
import { MdPublishedWithChanges } from "react-icons/md";
import UserNameAndImage from '../components/common/user-image';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../state';
import appTheme from "../../app-theme.json";

export const AddPropertyLayout = () => {

    const [localityData, setLocalityData] = useState({});
    const [pathName, setPathName] = useState();

    const dispatch = useDispatch();
    const { hideFooter, showPropertyPanel } = bindActionCreators(actionCreator, dispatch);
    const showPanel = useSelector((state) => state.showPropertyPanel);

    const showPropertyDetails = localityData.city ? "tab_link" : "tab_link disabled_details";

    useEffect(() => {
        hideFooter(true);
        return () => { hideFooter(false); }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setPathName(window.location.pathname);
        }, 100);
        showPropertyPanel(false);
        return () => clearInterval(interval);
    }, [pathName]);


    const panelClass = showPanel ? "tab_btn show_panel" : "tab_btn";


    return (
        <>
            <div className="property_layout">
                <Container fluid className='px-0'>
                    <div className={panelClass}>
                        <UserNameAndImage />
                        <div className="nav_links">
                            <NavLink className='tab_link' to={`${RouteLinks.AddProperty}${RouteLinks.PropertyLocality}`}><FaMapMarkerAlt />{appTheme.propertyLayout.localityDetails}</NavLink>
                            {
                                localityData.city ? <NavLink className={showPropertyDetails} to={`${RouteLinks.AddProperty}${RouteLinks.PropertyDetail}`}><FaUniversity />{appTheme.propertyLayout.propertyDetails}</NavLink>
                                    : <p className='tab_link disabled_link'><FaWallet />{appTheme.propertyLayout.propertyDetails}</p>
                            }
                            <p className='tab_link disabled_link'><FaWallet />{appTheme.propertyLayout.rentalDetails}</p>
                            <p className='tab_link disabled_link' ><FaHandshake />{appTheme.propertyLayout.amenities}</p>
                            <p className='tab_link disabled_link' ><FaCamera />{appTheme.propertyLayout.gallery}</p>
                            <p className='tab_link disabled_link' ><MdPublishedWithChanges />{appTheme.propertyLayout.publish}</p>
                        </div>
                    </div>
                    <div className="property_tabs">
                        <Outlet context={[localityData, setLocalityData]} />
                    </div>
                </Container>
            </div>

            {showPanel && <div className='back_drop' onClick={() => showPropertyPanel(!showPanel)} />}

        </>
    )
}

