import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../../state';
import { Publish, MarkDraft, MarkActive, MarkInactive } from '../../../../services/api/residential-rent';
import isTokenExpired from '../../../../utils/jwt-auth';
import appTheme from "../../../../../app-theme.json";

const StatusConfirmation = ({ allStatus }) => {
    const [confirmationContent, setConfirmationContent] = useState("");
    const [show, setShow] = useState(false);
    const { activeStatus, propertyListingId } = allStatus.status;

    const { confirmationMessage, draft, published, inactive } = appTheme.myListingPage.property.propertyStatus;

    const dispatch = useDispatch();
    const { confirmAndStatus, isLoader, redirectModal } = bindActionCreators(actionCreator, dispatch);
    const showLoader = useSelector((state) => state.isLoader);
    const hideModal = () => { setShow(false); confirmAndStatus(true); allStatus.setStatus({ activeStatus: "", propertyListingId: "" }) }

    useEffect(() => {
        if (parseInt(activeStatus) >= 1 && parseInt(activeStatus) <= 6) { setShow(true); }
        switch (parseInt(activeStatus)) {
            case 1:
                setConfirmationContent(draft.inactive);
                break;
            case 2:
                setConfirmationContent(draft.publish);
                break;
            case 3:
                setConfirmationContent(published.inactive);
                break;
            case 4:
                setConfirmationContent(published.draft);
                break;
            case 5:
                setConfirmationContent(inactive.activeAndPublish);
                break;
            case 6:
                setConfirmationContent(inactive.activeAndDraft);
                break;
            default:
                setConfirmationContent("");
                break;
        }
    }, [activeStatus]);

    const _Publish = async (propertyId) => {
        const response = await Publish(propertyId, isLoader, isTokenExpired, redirectModal);
        if (response != null) { if (response.isSuccess) { hideModal(); } }
    }

    const _MarkDraft = async (propertyId) => {
        const response = await MarkDraft(propertyId, isLoader, isTokenExpired, redirectModal);
        if (response != null) { if (response.isSuccess) { hideModal(); } }
    }

    const _MarkActive = async (propertyId) => {
        const response = await MarkActive(propertyId, isLoader, isTokenExpired, redirectModal);
        if (response != null) { if (response.isSuccess) { hideModal(); } }
    }

    const _MarkInactive = async (propertyId) => {
        const response = await MarkInactive(propertyId, isLoader, isTokenExpired, redirectModal);
        if (response != null) { if (response.isSuccess) { hideModal(); } }
    }

    const confirmHandler = (propertyId) => {
        switch (parseInt(activeStatus)) {
            case 1: _MarkInactive(propertyId);
                break;
            case 2: _Publish(propertyId);
                break;
            case 3: _MarkInactive(propertyId);
                break;
            case 4: _MarkDraft(propertyId);
                break;
            case 5: _MarkActive(propertyId); _Publish(propertyId);
                break;
            case 6: _MarkActive(propertyId); _MarkDraft(propertyId);
                break;
            default:
                break;
        }
    }

    return (
        <Modal show={show} onHide={hideModal} className="confirmation_modal" centered>
            <Modal.Header closeButton>
                <h4 className="title">{confirmationContent}</h4>
            </Modal.Header>
            <Modal.Body>{confirmationMessage.title}{confirmationContent}</Modal.Body>
            <div className="confirmation_btn">
                <Button size="sm" className="cancel" onClick={hideModal}>{confirmationMessage.cancelButton}</Button>
                <Button size="sm" className="confirm" onClick={() => confirmHandler(propertyListingId)}>
                    {showLoader ? <Spinner animation="border" className='spinner_sm' /> : <>{confirmationMessage.okButton}</>}
                </Button>
            </div>
        </Modal>
    )
}

export default StatusConfirmation;
