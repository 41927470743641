import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Detail } from '../../services/api/client';
import { Image, Spinner } from 'react-bootstrap';
import "../../styles/common/user-image.scss";
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../state';
import appTheme from "../../../app-theme.json";
import isTokenExpired from '../../utils/jwt-auth';

const UserNameAndImage = () => {

    const [clientDetail, setClientDetail] = useState({});
    const [loadingUser, setLoadingUser] = useState(false);

    const dispatch = useDispatch();
    const { isLoading, redirectModal } = bindActionCreators(actionCreator, dispatch);
    const profileImage = useSelector((state) => state.showProfileImage);
    const auth = useSelector((state) => state.isAuthenticated)

    const getDetail = async () => {
        setLoadingUser(true);
        const response = await Detail(isTokenExpired, redirectModal);
        if (response != null) { setClientDetail(response.data); setLoadingUser(false); }
        setLoadingUser(false);
    }
    useEffect(() => { if (auth) { getDetail(); } }, [profileImage, auth]);

    return (
        <>
            {loadingUser ? <div className="user_spinner"><Spinner /></div> :
                <div className="user_img">
                    <Image src={clientDetail.profilePicture ? clientDetail.profilePicture : appTheme.userDummyImage} />
                    <div className="user_name">
                        <p>Welcome</p>
                        <h5 className='name'>{clientDetail.firstName} {clientDetail.lastName}</h5>
                    </div>
                </div>
            }

        </>

    )
}

export default UserNameAndImage;
