import React from 'react';
import "../../../../../styles/others/career_details.scss";
import appTheme from "../../../../../../app-theme.json";
import { Row, Col, Image, Container, Form, Button } from 'react-bootstrap';
import { FaMapMarkerAlt, FaClock, FaRegMoneyBillAlt, FaAngleRight } from "react-icons/fa";

const JobDetailsSection = () => {

    const { mainTitle, mainSubTitile, jobOverview, jobDiscription, jobApplyForms, jobSummery } = appTheme.careerDetailsPage.CareerDetailsSection;
    const { formTitle, formButton, name, email, portfolio, resume, message } = jobApplyForms;

    return (
        <div className="career_section section_padding">
            <Container>
                <div className="main_title">
                    <h1 className="title">{mainTitle}</h1>
                    <p className="subtitle">{mainSubTitile}</p>
                </div>
            </Container>
            <div className="career_details">
                <Container>
                    <Row>
                        <Col lg={8} className="mb-4 mb-lg-0">

                            <div className="Job_overview">
                                <Image className="img-fluid border rounded" src={jobOverview.image} alt="" />
                                <div className="overview_content">
                                    <h3 className="overview_title">{jobOverview.jobPost}</h3>
                                    <span className="overview_text"><FaMapMarkerAlt />{jobOverview.jobLocation}</span>
                                    <span className="overview_text"><FaClock />{jobOverview.jobStatus}</span>
                                    <span className="overview_text"><FaRegMoneyBillAlt /> &#8377;{parseInt(jobOverview.minSalary).toLocaleString()} - &#8377;{parseInt(jobOverview.maxSalary).toLocaleString()}</span>
                                </div>
                            </div>

                            {
                                jobDiscription.map((items) => {
                                    return (
                                        <div className="job_discription" key={items.discriptionId}>
                                            <h4 className="discription_title">{items.discriptionTitle}</h4>
                                            <p className="discription_para">{items.discriptionPara}</p>
                                            {items.discriptionList &&
                                                <ul className="discription_list">
                                                    {items.discriptionList.map((item) => {
                                                        return (
                                                            <li key={item.discriptionListId}><FaAngleRight /><p>{item.discriptionListPara}</p></li>
                                                        )
                                                    })}
                                                </ul>
                                            }
                                        </div>
                                    )
                                })
                            }

                            <div className="job_discription">
                                <h4 className="discription_title">{formTitle}</h4>
                                <Form className="job_apply_form">
                                    <Row>
                                        <Col lg={6} className="mb-3">
                                            <Form.Group>
                                                <Form.Label>{name.label}</Form.Label>
                                                <Form.Control type="text" placeholder={name.placeHolder} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} className="mb-3">
                                            <Form.Group >
                                                <Form.Label>{email.label}</Form.Label>
                                                <Form.Control type="email" placeholder={email.placeHolder} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} className="mb-3">
                                            <Form.Group>
                                                <Form.Label>{portfolio.label}</Form.Label>
                                                <Form.Control type="text" placeholder={portfolio.placeHolder} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} className="mb-3">
                                            <Form.Group>
                                                <Form.Label>{resume.label}</Form.Label>
                                                <Form.Control type="file" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} className="mb-3">
                                            <Form.Group >
                                                <Form.Label>{message.label}</Form.Label>
                                                <Form.Control as="textarea" rows={3} placeholder={message.placeHolder} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Button className="default_btn">{formButton}</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>

                        <Col lg={4}>
                            <div className="summery_detail">
                                {jobSummery.map((items) => {
                                    return (
                                        <div className="job_Summery" key={items.jobSummeryId}>
                                            <h4 className="Summery_title">{items.jobSummeryTitle}</h4>
                                            {items.jobSummeryPara && <p className="Summery_para">{items.jobSummeryPara}</p>}
                                            {items.jobSummeryList &&
                                                <ul className="Summery_list">
                                                    {items.jobSummeryList.map((item) => {
                                                        return (
                                                            <li key={item.jobSummeryListId}><FaAngleRight /><p>{item.jobSummeryListPara}</p></li>
                                                        )
                                                    })}
                                                </ul>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div >
    )
}

export default JobDetailsSection;
