import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import '../styles/index.scss';
import { GetAllLookups } from '../services/api/Lookups';
import appTheme from "../../app-theme.json";
import Aos from 'aos';
import { GetResidentialRentProperty } from '../services/api/properties';
import { useDispatch, useSelector } from 'react-redux';
import HomeBanner from '../components/home/home-banner';
import AboutSection from '../components/home/about';
import PropertyTypes from "../components/home/property-types";
import MetroCities from "../components/common/metro-cities";
import PropertyClients from "../components/common/property-counter";
import { bindActionCreators } from 'redux';
import { actionCreator } from '../state';
import Property from "../components/home/property";
import BottomTopButton from '../components/common/bottom-top-button';

const Index = () => {

    const [listingItems, setListingItems] = useState([]);
    const [favLoadProperty, setFavLoadProperty] = useState(false);

    const dispatch = useDispatch();
    const { isPropertyLoading } = bindActionCreators(actionCreator, dispatch);

    const favouritLoader = useSelector((state) => state.favouritePropertyLoader);

    const getAllLookups = async () => {
        const response = await GetAllLookups();
        if (response != null) { if (response.isSuccess) { localStorage.setItem("lookups", JSON.stringify(response.data)); } }
    }

    // const getUserLocation = () => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(
    //             (position) => {
    //                 const { latitude, longitude } = position.coords;
    //                 setUserLocation({ latitude, longitude });
    //             },
    //         );
    //     }
    // }

    const allLookups = JSON.parse(localStorage.getItem("lookups"));
    useEffect(() => {
        if (allLookups === null) { getAllLookups(); }
    }, [allLookups]);

    const _GetResidentialRentProperty = async (payload) => {
        isPropertyLoading(true);
        const response = await GetResidentialRentProperty(payload);
        if (response != null) {
            if (response.isSuccess) {
                isPropertyLoading(false);
                setListingItems(response.data.map((item) => { return ({ ...item, isFavourite: false }) }));
            }
        } else isPropertyLoading(false);
    }


    const _GetResidentialRentPropertyFavourite = async (payload) => {
        const response = await GetResidentialRentProperty(payload);
        if (response != null) {
            if (response.isSuccess) {
                setListingItems(response.data.map((item) => { return ({ ...item, isFavourite: false }) }));
            }
        }
    }

    useEffect(() => { _GetResidentialRentProperty({ cityId: 7 }); }, []);

    useEffect(() => {
        if (favLoadProperty)
            _GetResidentialRentPropertyFavourite({ cityId: 7 });
    }, [favouritLoader, favLoadProperty]);


    useEffect(() => { Aos.init(); }, []);

    const addComponents = (items) => {
        switch (items.componentName) {
            case "HomeBanner":
                return { ...items, component: <HomeBanner /> }
            case "AboutSection":
                return { ...items, component: <AboutSection /> }
            case "PropertyTypes":
                return { ...items, component: <PropertyTypes listingItems={listingItems} /> }
            case "Property":
                return { ...items, component: <Property listingItems={listingItems} setFavLoadProperty={setFavLoadProperty} /> }
            case "PropertyClients":
                return { ...items, component: <PropertyClients /> }
            case "MetroCities":
                return { ...items, component: <MetroCities /> }
            case "BottomTopButton":
                return { ...items, component: <BottomTopButton /> }
            default:
                break;
        }
    }

    let homeComponentsData = appTheme.homeComponentsData.map((items) => addComponents(items));

    homeComponentsData.sort((a, b) => (a.position > b.position) ? 1 : -1);


    return (<>
        {
            homeComponentsData.map((items) => {
                if (items.component && items.isPublished)
                    return (
                        <section key={items.position}>{items.component}</section>

                    )
            })
        }
    </>);
};

export default Index;