import React from 'react';
import { Image } from 'react-bootstrap';
import AppSetting from '../../../../../appConfig.json';
import { FaFileImage, FaVideo, FaFilePdf, FaFileWord } from "react-icons/fa";

export const UserList = (inputs) => {

    const chatsHandler = (user) => {
        inputs.params.loadChatHistory(user);
        inputs.params.setShowMessage(true);
    }

    return <>
        <div className='message_list'>
            {
                inputs.params.userList.map((user, index) => {
                    return (
                        <div key={index}>
                            <button type='button' className={`chat_list ${user.isActiveChatUser === true && "active"}`} onClick={() => chatsHandler(user)}>
                                <div className="message_sender_img">
                                    <Image src={user.profilePicture ? user.profilePicture : inputs.params._defaultProfilePicture} />
                                    {user.isOnline && <span className="is_online"></span>}
                                </div>
                                <div className="message_sender_name">
                                    <span className={`name ${user.unreadCount > 0 && 'unread'}`}>{user.firstName} {user.lastName}</span>
                                    <span className={`last_message ${user.unreadCount > 0 && 'unread'}`}>{printLastMessage(user.lastMessage)}</span>
                                </div>
                            </button>
                        </div>
                    )
                })
            }
        </div>
    </>
}

const printLastMessage = (content) => {
    if (content) {
        const extension = content.split('.').pop();
        if (AppSetting.ALLOWED_FILE_EXTENSION.includes(extension)) {
            if (extension === 'pdf') { return <> <FaFilePdf className='chat-doc' /></> }
            else if (extension === 'doc') { return <> <FaFileWord className='chat-doc' /></> }
        }
        else if (AppSetting.ALLOWED_VIDEO_EXTENSION.includes(extension)) { return <> <FaVideo className='chat-doc' /></> }
        else if (AppSetting.ALLOWED_IMAGE_EXTENSION.includes(extension)) { return <> <FaFileImage className='chat-doc' /></> }
        else { return <>{content}</> }
    }
}