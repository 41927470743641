import * as Yup from 'yup';

export const selectReasonSchema = Yup.object({
    reasonId: Yup.string().required("please select reason"),
    confirmReason: Yup.array().length(1, "Please check the final confirmation").required("Please check the final confirmation"),
})

export const enterReasonSchema = Yup.object({
    reasonId: Yup.string().required("please select reason"),
    otherReason: Yup.string().min(20, "please enter reason atlest 20 charator").required("please enter reason"),
    confirmReason: Yup.array().length(1, "Please check the final confirmation").required("Please check the final confirmation"),

})