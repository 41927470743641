import React from 'react';
import "../../../../styles/profile/tenent-details.scss";
import { Image, Accordion, Button } from "react-bootstrap";
import { FaArrowLeft, FaFilePdf, FaTrashAlt, FaCheck, FaTimes, FaCaretDown } from "react-icons/fa";
import appTheme from "../../../../../app-theme.json";
import OwlCarousel from 'react-owl-carousel';
import { mytenantyOptions } from "../../../../utils/owl-carousel/options";


const dummyData = [
    {
        img: "/assets/img/card/card_1.jpg"
    },
    {
        img: "/assets/img/card/card_2.jpg"
    },
    {
        img: "/assets/img/card/card_3.jpg"
    },
    {
        img: "/assets/img/card/card_4.jpg"
    },
    {
        img: "/assets/img/card/card_5.jpg"
    }
]

const TenentDetails = ({ showDetails, setShowDetails }) => {

    const { title, removeButton, userDetails, personalDetails, agreement, documentKyc, tenantHistory } = appTheme.myTenantyPage.tenantDetails;

    return (
        <div className={`message_content_container padding_bottom ${showDetails && "show_details"}`}>
            <div className="message_title">
                <FaArrowLeft onClick={() => setShowDetails(false)} />
                <div className="tenant_view">{title}</div>
            </div>
            <div className="message_content h-100 pb-5">

                <div className="tenant_card_detail">
                    <div className="tenant_name_img pb-2">
                        <span className="tenant_name_location">
                            <span className="tenant_name">2 BHK Flat in Ashok Building</span>
                            <p className="property_location_text">East End Apartment, near Ashok Nagar Metro Station</p>
                        </span>
                    </div>
                    <div className="tenant_name_img">
                        <div className="property_owner_slider">
                            <OwlCarousel className='owl-theme' {...mytenantyOptions}>
                                {
                                    dummyData.map((imgItem) => {
                                        return (
                                            <Image src={imgItem.img} key={imgItem.img} />
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div>
                    </div>
                </div>

                <div className="tenant_card_detail">
                    <div className="tenant_name_img">
                        <span className="tenant_name_location">
                            <span className="tenant_name">{personalDetails.title}</span>
                        </span>
                    </div>

                    <div className="rent_detail">
                        <span className='rent_label'>{personalDetails.ownerName}</span>
                        <span className='rent_value'>Hukum Singh</span>
                    </div>

                    <div className="rent_detail">
                        <span className='rent_label'>{personalDetails.mobileNo}</span>
                        <span className='rent_value'>9717309081</span>
                    </div>

                </div>

                {/* <div className="message_input small_device">
                    <Button className="remove_tenant"><FaTrashAlt />{removeButton}</Button>
                </div> */}
            </div>
            {/* <div className="message_input large_device">
                <Button className="remove_tenant"><FaTrashAlt />{removeButton}</Button>
            </div> */}
        </div>
    )
}

export default TenentDetails
