import React, { useEffect, useState } from 'react';
import "../../../styles/mobile-nav.scss";
import "../../../styles/common/user-dropdown.scss";
import { SiteLogoSvg } from '../../svg';
import { Container, Nav, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreator } from '../../../state';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import RouteLinks from '../../../utils/route';
import UserDropdown from '../../common/user-dropdown';
import { Detail } from '../../../services/api/client';
import isTokenExpired from '../../../utils/jwt-auth';
import { FaBell } from "react-icons/fa";
import appTheme from "../../../../app-theme.json";
import { MarkAllRead, Notification } from '../../../services/api/notification';
import NotificationCanvas from '../components/notifaction-canvas';

const MobileMenu = () => {

    const [clientDetail, setClientDetail] = useState({});
    const [showSidebar, setShowSidebar] = useState(false);
    const [pathName, setPathName] = useState();
    const [notificationList, setNotificationList] = useState([]);
    const [showNotification, setShowNotification] = useState(false)
    const [reloadNotification, setReloadNotification] = useState(false);

    const dispatch = useDispatch();
    const { toggleMobileVarificationModal, clientLoader, redirectModal } = bindActionCreators(actionCreator, dispatch);
    const authentication = useSelector((state) => state.isAuthenticated);
    const profileImage = useSelector((state) => state.showProfileImage);
    const showLoader = useSelector((state) => state.clientLoader);

    const getDetail = async () => {
        clientLoader(true);
        const response = await Detail(isTokenExpired, redirectModal);
        if (response != null) { setClientDetail(response.data); clientLoader(false); }
        else clientLoader(false);
    }

    const _Notification = async () => {
        const response = await Notification(isTokenExpired);
        if (response != null) {
            if (response.isSuccess) { setNotificationList(response.data) }
        }
    }

    const _MarkAllRead = async () => {
        const response = await MarkAllRead(isTokenExpired);
        if (response) {
            setShowNotification(false); setReloadNotification(!reloadNotification);
        }
    }

    useEffect(() => { _Notification(); }, [pathName]);

    useEffect(() => { getDetail(); }, [profileImage, authentication]);

    useEffect(() => {
        setShowSidebar(false);
        const interval = setInterval(() => {
            setPathName(window.location.pathname);
        }, 100);
        return () => clearInterval(interval);
    }, [pathName]);

    const showNotifiationHandler = () => {
        setShowNotification(true);
    }

    const mobileSidebar = showSidebar ? "mobile_sidebar" : "mobile_sidebar mobile_disable";

    return (

        <>
            <div className="mobile_menu">
                <Container fluid className='h-100'>
                    <div className="nav_row">
                        <Link className="logo" to={RouteLinks.Home}>
                            <SiteLogoSvg />
                        </Link>
                        {
                            authentication ?
                                <div className='after_login'>
                                    {showLoader ? <div className="user_spinner"><Spinner /></div> : <UserDropdown clientDetail={clientDetail} />}
                                    {
                                        notificationList.length > 0 ?
                                            <Nav.Link className='px-0 bell_icon' onClick={showNotifiationHandler}>
                                                <FaBell />
                                                <span className="notification">{notificationList.length}</span>
                                            </Nav.Link>
                                            : <Nav.Link className='px-0 no_notify'><FaBell /></Nav.Link>
                                    }
                                </div>
                                :
                                <Nav className='before_login'>
                                    {
                                        pathName === "/account" ? "" : <Link className='login_btn' to={RouteLinks.Login}>{appTheme.login.title}</Link>
                                    }
                                </Nav>
                        }
                    </div>
                </Container>
            </div>

            <NotificationCanvas
                NotificationItems={{ _MarkAllRead, notificationList, showNotification, setShowNotification }}
            />

        </>
    )
}

export default MobileMenu
