import React, { useEffect, useState } from 'react';
import "../../../styles/profile/profile.scss";
import ProfileTab from './components/profile-tab';
import { Detail } from '../../../services/api/client';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../state';
import isTokenExpired from '../../../utils/jwt-auth';

export const Profile = () => {

    const [clientDetail, setClientDetail] = useState({});

    const dispatch = useDispatch();
    const { isLoading, redirectModal, } = bindActionCreators(actionCreator, dispatch);
    const profileImage = useSelector((state) => state.showProfileImage);

    const getDetail = async () => {
        isLoading(true);
        const response = await Detail(isTokenExpired, redirectModal);
        if (response != null) { setClientDetail(response.data); isLoading(false); }
        isLoading(false);
    }

    useEffect(() => { getDetail(); }, [profileImage]);

    return (
        <>
            <ProfileTab clientDetail={clientDetail} />
        </>
    );
}
