import React, { useState } from "react";
import { Button, Col, Form, Row, Image, Card, Spinner } from "react-bootstrap";
import { GetGallery, UploadGallery, DeleteGallery } from "../../services/api/residential-rent";
import { initialGallery } from "../../helper/tabs/gallery";
import { useFormik } from "formik";
import isTokenExpired from "../../utils/jwt-auth";
import { toast } from "react-toastify";
import { toastStyle } from "../../utils/http-requests";
import { GallerySchema } from "../../helper/validations/gallery-schema";
import { useDispatch, useSelector } from "react-redux";
import { actionCreator } from "../../state";
import { bindActionCreators } from "redux";
import { useOutletContext, useNavigate } from "react-router-dom";
import configData from "../../../appConfig.json"
import DeleteImage from "./Modals/delete-image";
import ChangeImage from "./Modals/change-image";
import convertToBase64 from "../../utils/convert-image/base-64";
import { useEffect } from "react";
import appTheme from "../../../app-theme.json";
import RouteLinks from "../../utils/route";


const Gallery = () => {

  const [deleteImageDetail, setDeleteImageDetail] = useState("");
  const [changeImageDetail, setChangeImageDetail] = useState("");
  const [previewImg, setPreviewImg] = useState([]);
  const [galleries, setGalleries] = useState([]);
  const [refreshGallery, setRefreshGallery] = useState(false);
  const [localityData, setLocalityData, propertyListingData] = useOutletContext();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { redirectModal, deleteImage, changeImage, isLoader, isLoading } = bindActionCreators(actionCreator, dispatch);
  const showLoader = useSelector((state) => state.isLoader);

  const { propertyListingId } = propertyListingData;


  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setValues, resetForm } = useFormik({
    initialValues: initialGallery,
    validationSchema: GallerySchema,
    onSubmit: async (values, action) => {
      if (values) {
        let formData = new FormData();
        values.files.forEach((file) => { formData.append('Files', file); });
        formData.append('PropertyListingId', propertyListingData.propertyListingId);

        const response = await UploadGallery(formData, isLoader, isTokenExpired, redirectModal);
        if (response != null) {
          const { isSuccess, errorMessages, successMessage } = response;
          if (isSuccess) { toast.success(successMessage, toastStyle); setRefreshGallery(!refreshGallery); resetForm(); }
          else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle); }); }
        }
      }
    }
  });

  const nextNavigate = () => {
    navigate(`${RouteLinks.PropertyListing}/${propertyListingId}${RouteLinks.Publish}`);
  }

  const getAllGallery = async (payload) => {
    const response = await GetGallery(payload, isLoading, isTokenExpired, redirectModal);
    if (response != null) {
      if (response.isSuccess) { setGalleries(response.data); setPreviewImg([]); }
    }
  }

  useEffect(() => {
    if (propertyListingData.propertyListingId) {
      getAllGallery(propertyListingData.propertyListingId);
    }
  }, [propertyListingData, refreshGallery]);

  const handleFiles = async (e) => {
    const files = Array.from(e.target.files);
    setValues({ files: files });
    files.forEach(async (item) => {
      const base64 = await convertToBase64(item);
      setPreviewImg((prevImg) => [...prevImg, base64]);
    });
  }

  const deleteImageDetails = (item) => {
    deleteImage(true);
    setDeleteImageDetail(item);
  }

  const changeImageDetails = (item) => {
    changeImage(true);
    setChangeImageDetail(item);
  }

  const deleteImageHandler = async () => {
    const payload = [{
      propertyListingGalleryId: deleteImageDetail.propertyListingGalleryId,
      propertyListingId: deleteImageDetail.propertyListingId,
      name: deleteImageDetail.name
    }]
    const response = await DeleteGallery(payload, isLoader, isTokenExpired, redirectModal);
    if (response != null) { if (response.isSuccess) { setRefreshGallery(!refreshGallery); } }
  }

  return (
    <>
      <h6 className="tab_title">{appTheme.galleryDetails.title}</h6>
      <div className="tab_content">
        <Row>
          {galleries &&
            galleries.map((item, index) => {
              return (
                <Col md={3} key={index}>
                  <Card className="gallery_card">
                    <Card.Img variant="top" src={`${configData.GALLERY_URL}/${item.name}`} />
                    <Card.Body>
                      <Button size="sm" variant="primary" onClick={() => changeImageDetails(item)}>{appTheme.galleryDetails.changeButton}</Button>
                      <Button size="sm" variant="danger" className="ms-3" onClick={() => deleteImageDetails(item)}>{appTheme.galleryDetails.deleteButton}</Button>
                    </Card.Body>
                  </Card>
                </Col>
              )
            })
          }

        </Row>
        <Form className="image_form">
          <Form.Group className="image_upload">
            <strong>{appTheme.galleryDetails.addPhotosTitle}</strong>
            <small className="mt-1">{appTheme.galleryDetails.addPhotosParagraph}</small>
            <small className="mt-1 mb-3">{appTheme.galleryDetails.addPhotosSizes}</small>
            <Form.Label>
              <span className="btn btn-success">{appTheme.galleryDetails.addPhotosButton}</span>
              <Form.Control type="file" name="file" multiple onChange={handleFiles} onBlur={handleBlur} accept=".jpeg, .png, .jpg" />
            </Form.Label>
            {errors.files && touched.files ? (<p className="form-error">{errors.files}</p>) : null}
          </Form.Group>
          <div className="preview_img">
            {
              previewImg && previewImg.map((item) => {
                return (
                  <Image src={item} />
                )
              })
            }
          </div>
          <div className="next_btn">
            <Button size="sm" className="mt-5 save" onClick={handleSubmit}>
              {showLoader ? <Spinner animation="border" className='spinner_sm' /> : <>{appTheme.galleryDetails.submitButton}</>}
            </Button>
            <Button size="sm" variant="primary" className="ms-auto mt-5 next" onClick={nextNavigate}>{appTheme.galleryDetails.nextButton}</Button>
          </div>
        </Form>
      </div>

      <DeleteImage onDeleteImage={deleteImageHandler} />
      <ChangeImage changeImageDetail={changeImageDetail} refreshGallery={refreshGallery} setRefreshGallery={setRefreshGallery} />

    </>
  );
};

export default Gallery;