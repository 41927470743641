import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useOutletContext } from 'react-router-dom';
import StatusConfirmation from '../../components/property-listing/Modals/status-confirmation';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../state';
import { useState } from 'react';
import RouteLinks from '../../utils/route';
import appTheme from "../../../app-theme.json";

export const PublishTab = () => {
    const [, , propertyListingData,] = useOutletContext();
    const [buttonValue, setButtonValue] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { confirmAndStatus } = bindActionCreators(actionCreator, dispatch);


    const navigateHandler = () => {
        navigate(RouteLinks.PropertyListing);
    }

    if (!propertyListingData) {
        return
    }
    const { isDraft, isActive, isPublished } = propertyListingData;

    const { publishedStatus, draftStatus, inactiveStatus } = appTheme.publishStatusPage;

    return (
        <>
            <div className="tab_content">
                <div className="status_btn">

                    {isDraft && isActive && !isPublished && <>
                        <Button className="keep_btn" onClick={navigateHandler}>{draftStatus.keepDraft}</Button>
                        <Button className="publish_btn" onClick={() => { setButtonValue(1); confirmAndStatus(true); }}>{draftStatus.publish}</Button>
                        <Button className="delete_btn" onClick={() => { setButtonValue(2); confirmAndStatus(true); }}>{draftStatus.inactive}</Button>
                    </>}

                    {isActive && isPublished && !isDraft && <>
                        <Button className="keep_btn" onClick={navigateHandler}>{publishedStatus.keepPublish}</Button>
                        <Button className="draft_btn" onClick={() => { setButtonValue(3); confirmAndStatus(true); }}>{publishedStatus.draft}</Button>
                        <Button className="delete_btn" onClick={() => { setButtonValue(4); confirmAndStatus(true); }}>{publishedStatus.inactive}</Button>
                    </>}

                    {!isDraft && !isActive && !isPublished && <>
                        <Button className="keep_btn" onClick={navigateHandler}>{inactiveStatus.keepInactive}</Button>
                        <Button className="publish_btn" onClick={() => { setButtonValue(5); confirmAndStatus(true); }}>{inactiveStatus.publish}</Button>
                        <Button className="draft_btn" onClick={() => { setButtonValue(6); confirmAndStatus(true); }}>{inactiveStatus.draft}</Button>
                    </>
                    }

                </div>
            </div>

            <StatusConfirmation propertyListingData={propertyListingData} buttonValue={buttonValue} />
        </>
    )
}
