import { httpHeadersWithToken } from '../../utils/http-requests';
import AppSetting from '../../../appConfig.json';
import axios from 'axios';
import { errorHandler } from '../validation/error-handler';

export const SendQuery = async (payload, isLoader) => {
    isLoader(true);
    try {
        const url = AppSetting.SERVER_URL + `/SendQuery`;
        const res = await axios.post(url, payload, httpHeadersWithToken());
        isLoader(false);
        return res.data;
    } catch (error) {
        isLoader(false);
        return errorHandler(error);
    }
}