import React, { useEffect } from 'react';
import "../styles/common/property-layout.scss";
import { Container } from 'react-bootstrap';
import { NavLink, Outlet } from "react-router-dom";
import { FaUserAlt, FaListUl, FaHeart, FaUsers, FaFileAlt, FaComment } from "react-icons/fa";
import UserNameAndImage from '../components/common/user-image';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../state';
import appTheme from "../../app-theme.json";

export const ProfileLayout = () => {

    const dispatch = useDispatch();
    const { hideFooter } = bindActionCreators(actionCreator, dispatch);


    useEffect(() => {
        hideFooter(true);
        return () => { hideFooter(false); }
    }, []);

    return (
        <>
            <div className="property_layout">
                <Container fluid className='px-0'>
                    <div className="tab_btn">
                        <UserNameAndImage />
                        <div className="nav_links">
                            <NavLink className='tab_link' to={appTheme.dashboard.path}><FaUserAlt />{appTheme.dashboard.title}</NavLink>
                            <NavLink className='tab_link' to={appTheme.myProfile.path}><FaUserAlt />{appTheme.myProfile.title}</NavLink>
                            <NavLink className='tab_link' to={appTheme.myListing.path}><FaListUl />{appTheme.myListing.title}</NavLink>
                            <NavLink className='tab_link' to={appTheme.myFavourite.path}><FaHeart />{appTheme.myFavourite.title}</NavLink>
                            <NavLink className='tab_link' to={appTheme.chats.path}><FaComment />{appTheme.chats.title}</NavLink>
                            <NavLink className='tab_link' to={appTheme.documentKyc.path}><FaFileAlt />{appTheme.documentKyc.title}</NavLink>
                            <NavLink className='tab_link' to={appTheme.myTenents.path}><FaUsers />{appTheme.myTenents.title}</NavLink>
                            <NavLink className='tab_link' to={appTheme.myTenenty.path}><FaUsers />{appTheme.myTenenty.title}</NavLink>
                        </div>
                    </div>
                    <div className="property_tabs p-0">
                        <Outlet />
                    </div>
                </Container>
            </div>
        </>
    )
}
