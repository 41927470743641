import React, { useState } from 'react';
import { Button, Spinner, Modal, Form, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../../state';
import { initialAddTenant } from '../../../../helper/profile/property-listing';
import { useFormik } from 'formik';
import { addTenantEmailSchema, addTenantMobileSchema } from '../../../../helper/validations/profile/property-listing/add-tenant';
import { Add } from '../../../../services/api/tenants';
import moment from 'moment';
import isTokenExpired from '../../../../utils/jwt-auth';
import { toast } from 'react-toastify';
import { toastStyle } from '../../../../utils/http-requests';
import appTheme from "../../../../../app-theme.json";

const AddTenant = ({ tenant }) => {

    const [showEmailAndMobile, setShowEmailAndMobile] = useState(true);
    const { listingItemId, showAddTenant } = tenant.addTenant;
    const dispatch = useDispatch();
    const { isLoader, redirectModal } = bindActionCreators(actionCreator, dispatch);
    const showLoader = useSelector((state) => state.isLoader);

    const hideModal = () => { tenant.setAddTenant({ showAddTenant: false, listingItemId: "" }); setShowEmailAndMobile(true); resetForm(); }

    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setValues, resetForm } = useFormik({
        initialValues: initialAddTenant,
        validationSchema: showEmailAndMobile ? addTenantEmailSchema : addTenantMobileSchema,
        onSubmit: async (values) => {
            const payload = {
                agreementEndDate: moment.utc(values.agreementEndDate).format('YYYY-MM-DD'),
                agreementStartDate: moment.utc(values.agreementEndDate).format('YYYY-MM-DD'),
                propertyListingId: listingItemId,
                rent: parseFloat(values.rent),
                contactNumber: values.contactNumber,
                countryCode: values.countryCode
            }
            const response = await Add(payload, isLoader, isTokenExpired, redirectModal);
            if (response != null) {
                if (response.isSuccess) { hideModal(); }
                else { response.errorMessages.forEach((error) => { toast.error(error.message, toastStyle) }) }
            }
        }
    });

    const emailAndMobileHandle = (e) => {
        let value = e.target.value;
        const regMatch = /^[a-zA-Z0-9._%+-@]*$/.test(value);
        const regMatchMobile = /^[0-9]*$/.test(value);
        if (regMatchMobile) {
            if (value === "") {
                setShowEmailAndMobile(true);
                setValues({
                    ...values,
                    emailId: "",
                    contactNumber: ""
                }); return
            }
            setShowEmailAndMobile(false);
            setValues({ ...values, contactNumber: value });
        }
        else if (regMatch) {
            setShowEmailAndMobile(true);
            setValues({ ...values, emailId: value });
        }
        else {
            setShowEmailAndMobile(true);
            setValues({ ...values, emailId: value });
        }
    }

    const { title, email, rent, agreementStart, agreementEnd, cancelButton, okButton } = appTheme.myListingPage.property.propertyTenant.addTenant;

    return (
        <Modal show={showAddTenant} onHide={hideModal} className="confirmation_modal add_tenant_modal" centered>
            <Modal.Header closeButton><h4 className="title">{title}</h4></Modal.Header>
            <Modal.Body>
                <Form>
                    <Row className="mb-4">
                        <Col lg={6} className="mb-3">
                            <Form.Group >
                                <Form.Label>{email.label}</Form.Label>
                                <Form.Control size="sm" type={`${showEmailAndMobile ? "text" : "number"}`} placeholder={email.placeHolder} value={showEmailAndMobile ? values.emailId : values.contactNumber} name={`${showEmailAndMobile ? "emailId" : "contactNumber"}`} onChange={emailAndMobileHandle} onBlur={handleBlur} />
                                {
                                    showEmailAndMobile ? errors.emailId && touched.emailId ? (<p className="form-error">{errors.emailId}</p>) : null
                                        : errors.contactNumber && touched.contactNumber ? (<p className="form-error">{errors.contactNumber}</p>) : null
                                }

                            </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <Form.Group >
                                <Form.Label>{rent.label}</Form.Label>
                                <Form.Control size="sm" type="number" placeholder={rent.placeHolder} value={values.rent} name="rent" onChange={handleChange} onBlur={handleBlur} />
                                {errors.rent && touched.rent ? (<p className="form-error">{errors.rent}</p>) : null}
                            </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <Form.Group >
                                <Form.Label>{agreementStart.label}</Form.Label>
                                <Form.Control size="sm" type="date" value={values.agreementStartDate} name="agreementStartDate" onChange={handleChange} onBlur={handleBlur} />
                                {errors.agreementStartDate && touched.agreementStartDate ? (<p className="form-error">{errors.agreementStartDate}</p>) : null}
                            </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <Form.Group >
                                <Form.Label>{agreementEnd.label}</Form.Label>
                                <Form.Control size="sm" type="date" value={values.agreementEndDate} name="agreementEndDate" onChange={handleChange} onBlur={handleBlur} />
                                {errors.agreementEndDate && touched.agreementEndDate ? (<p className="form-error">{errors.agreementEndDate}</p>) : null}
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                <div className="confirmation_btn">
                    <Button size="sm" className="cancel" onClick={hideModal}>{cancelButton}</Button>
                    <Button size="sm" className="confirm" onClick={handleSubmit}>
                        {showLoader ? <Spinner animation="border" className='spinner_sm' /> : <>{okButton}</>}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AddTenant
