import { BrowserRouter, useNavigate } from 'react-router-dom';
import AppRoutes from './app-routes';
import Footer from './app/components/footer';
import AppLoader from './app/components/loader';
import AppNav from './app/components/navigation';
import './app/styles/app.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from './app/state';
import { useEffect, useState } from 'react';
import { PageTitle } from './app/utils/page-title';
import RedirectModal from './app/components/forms/rediect-modal';
import isTokenExpired from './app/utils/jwt-auth';
import MobileMenu from './app/components/navigation/mobile-menu';
import BottomNav from './app/components/navigation/mobile-menu/bottom-nav';
import ProfileSideNav from './app/components/navigation/mobile-menu/profile-side-nav';

const App = () => {

  const [pathName, setPathName] = useState();
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const dispatch = useDispatch();
  const { isAuthenticated } = bindActionCreators(actionCreator, dispatch);
  const showAndHideFooter = useSelector((state) => state.hideFooter);

  useEffect(() => {
    window.onresize = () => {
      setWindowSize(window.innerWidth);
    };
  }, [windowSize]);

  useEffect(() => {

    if (pathName) {
      PageTitle(pathName);
    }

    if (isTokenExpired() === true || isTokenExpired() === undefined) { isAuthenticated(false); }
    else { isAuthenticated(true); }

    const interval = setInterval(() => {
      setPathName(window.location.pathname);
    }, 100);
    return () => clearInterval(interval);
  }, [pathName]);

  return (
    <>
      <BrowserRouter>
        {
          windowSize > 992 ? <AppNav pathName={pathName} /> : <MobileMenu pathName={pathName} />
        }
        <AppLoader />
        <main className="main-container">
          <AppRoutes />
        </main>
        {showAndHideFooter ? "" : <Footer />}
        <ProfileSideNav />
        <BottomNav pathName={pathName} />
        <RedirectModal />
      </BrowserRouter>
      <ToastContainer />
    </>
  );
};

export default App;