import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../styles/footer.scss';
import RouteLinks from '../../utils/route';
import { FaFacebookF, FaInstagram, FaYoutube, FaWhatsapp, FaLinkedinIn } from "react-icons/fa";
import appTheme from "../../../app-theme.json";

const AfterLogin = () => {

    return <>
        <footer >
            <Container>
                <Row>
                    {
                        appTheme.footer.footerAfterLogin.map((item) => {
                            return (
                                <Col md={3} className='mb-3 mb-md-0' key={item.id}>
                                    <ul className='link_box'>
                                        <li>
                                            <h4 className="link_title">{item.title}</h4>
                                            {item.footerLinks.map((items) => {
                                                return (
                                                    <Link className="link_text" key={items.id} to={items.path}>{items.title}</Link>
                                                )
                                            })}
                                        </li>
                                    </ul>
                                </Col>
                            )
                        })
                    }
                </Row>
                <hr className='border-white' />
                <Row>
                    <Col sm={6} className='mb-3 mb-sm-0'>
                        <div className="copyright">
                            <p>© Copyright {new Date().getFullYear()} , <Link to={RouteLinks.Home}>{appTheme.footer.company}</Link></p>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="social_link">
                            <Link className='social_btn'><FaInstagram className='insta' /></Link>
                            <Link className='social_btn'>< FaWhatsapp className='whatapp' /></Link>
                            <Link className='social_btn'>< FaFacebookF className='fb' /></Link>
                            <Link className='social_btn'>< FaLinkedinIn /></Link>
                            <Link className='social_btn'>< FaYoutube /></Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    </>

};

export default AfterLogin;