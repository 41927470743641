import React from 'react';
import { Col, Image, Row, Spinner } from 'react-bootstrap';
import RouteLinks from '../../../../utils/route';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { searchGrid } from '../../../../utils/owl-carousel/options';
import configData from "../../../../../appConfig.json";
import { FaBed, FaBuilding, FaHome, FaBath, FaClock, FaStore, FaRegHeart, FaHeart } from "react-icons/fa";
import appTheme from "../../../../../app-theme.json";
import { useSelector } from 'react-redux';


const ListCard = ({ item, openHeartHandler, fullHeartHandler }) => {

    const [bhkType, setBhkType] = useState([]);
    const [apartmentType, setApartmentType] = useState("");
    const [preferredTenant, setPreferredTenantId] = useState("");
    const [propertyAge, setPropertyAge] = useState("");
    const { builtUpArea, apartmentName, apartmentTypeId, amenities, propertyAgeId, floorId, rentalDetails, bhkTypeId, propertyListingId, galleries, isFavourite } = item;
    const newRentaldetail = rentalDetails ? rentalDetails : "";
    const newAmenities = amenities ? amenities : "";
    const { expectedRent, preferredTenantsId, availableFrom } = newRentaldetail;
    const { numberOfBalcony, numberOfBathrooms } = newAmenities;

    const authentication = useSelector((state) => state.isAuthenticated);
    const showLoader = useSelector((state) => state.isLoader);

    const allLookups = JSON.parse(localStorage.getItem("lookups"));
    useEffect(() => {
        if (allLookups) {
            allLookups.bhkTypes.forEach((items) => {
                if (items.bhkTypeId === bhkTypeId) {
                    setBhkType(items.type);
                }
            });
            allLookups.apartmentTypes.forEach((items) => {
                if (items.apartmentTypeId === apartmentTypeId)
                    setApartmentType(items.type);
            });
            allLookups.preferredTenants.forEach((items) => {
                if (items.preferredTenantsId === preferredTenantsId)
                    setPreferredTenantId(items.type);
            });
            allLookups.propertyAges.forEach((items) => {
                if (items.propertyAgeId === propertyAgeId)
                    setPropertyAge(items.ageRange);
            });
        }
    }, [item]);

    const fixImage = {
        width: "100%",
        minHeight: "130px",
        maxHeight: "150px",
        objectFit: "cover",
        objectPosition: "center",
        borderRadius: "5px",
    }

    const { propertySearchDetails, propertyDetails } = appTheme;

    return (
        <>
            <Col xs={12} className='list_col mb-4'>
                <Row className="list_row" as={Link} target="_blank" to={`${RouteLinks.PropertyDetail}/${propertyListingId}`}>
                    <Col md={3} className='img_col mb-4 mb-md-0'>
                        <OwlCarousel className='owl-theme' {...searchGrid}>
                            {galleries.length > 0 ?
                                galleries.map((items, index) => {
                                    if (index <= 5)
                                        return (
                                            <Image style={fixImage} src={`${configData.GALLERY_URL}${items.name}`} key={index} rounded />
                                        )
                                }) :
                                <Image style={fixImage} src={appTheme.propertySearch.noImage} rounded />
                            }
                        </OwlCarousel>
                    </Col>
                    <Col md={9} className='content_col'>
                        <p className="price">&#8377;{newRentaldetail.expectedRent} <span className='sub'>per month</span></p>
                        <span className="title">{apartmentName}</span>
                        <Row className='discription_content'>
                            <Col xs={6} md={4} className="discription_col mb-3">
                                <FaBed />
                                <div className="discription_box">
                                    <p className='label'>{propertySearchDetails.propertyDetails.bedroom}</p>
                                    <h4 className="value">{bhkType}</h4>
                                </div>
                            </Col>
                            <Col xs={6} md={4} className="discription_col mb-3">
                                <FaBuilding />
                                <div className="discription_box">
                                    <p className='label'>{propertyDetails.appartmentType}</p>
                                    <h4 className="value">{apartmentType}</h4>
                                </div>
                            </Col>
                            <Col xs={6} md={4} className="discription_col mb-3">
                                <FaHome />
                                <div className="discription_box">
                                    <p className='label'>{appTheme.rentalDetails.preferredTenants}</p>
                                    <h4 className="value">{preferredTenant}</h4>
                                </div>
                            </Col>
                            <Col xs={6} md={4} className="discription_col">
                                <FaBath />
                                <div className="discription_box">
                                    <p className='label'>{propertySearchDetails.propertyDetails.bathroom}</p>
                                    <h4 className="value">{numberOfBathrooms} Bathroom</h4>
                                </div>
                            </Col>
                            <Col xs={6} md={4} className="discription_col">
                                <FaClock />
                                <div className="discription_box">
                                    <p className='label'>{propertyDetails.propertyAge}</p>
                                    <h4 className="value">{propertyAge}</h4>
                                </div>
                            </Col>
                            <Col xs={6} md={4} className="discription_col">
                                <FaStore />
                                <div className="discription_box">
                                    <p className='label'>{propertySearchDetails.propertyDetails.balcony}</p>
                                    <h4 className="value">{numberOfBalcony} Balcony</h4>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {authentication && <>
                    {showLoader ? <div className="interested_loader"><Spinner /></div> :
                        <div className="interested">
                            {isFavourite ? <FaHeart className="full_heart" onClick={() => fullHeartHandler(propertyListingId)} /> :
                                <FaRegHeart className="open_heart" onClick={() => openHeartHandler(propertyListingId)} />
                            }
                        </div>
                    }
                </>
                }
            </Col>
        </>
    )
}

export default ListCard
