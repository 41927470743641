import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { propertyDetails } from "../../helper/tabs/property-details";
import { PropertyDetailsSchema } from "../../helper/validations/property-details";
import { ApartmentTypes, BHKTypes, Floors, PropertyAges, PropertyFacing, TotalFloors } from "../../services/api/Lookups";
import { PropertyAndLocalityDetail } from "../../services/api/residential-rent";
import { toast } from "react-toastify";
import { toastStyle } from "../../utils/http-requests";
import { useNavigate, useOutletContext } from "react-router-dom";
import RouteLinks from "../../utils/route";
import isTokenExpired from "../../utils/jwt-auth";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreator } from "../../state";
import appTheme from "../../../app-theme.json";

const PropertyDetails = () => {

  const [apartmentType, setApartmentType] = useState([]);
  const [bhkType, setBhkType] = useState([]);
  const [floor, setFloor] = useState([]);
  const [totalFloor, setTotalFloor] = useState([]);
  const [propertyAge, setPropertyAge] = useState([]);
  const [propertyFacing, setPropertyFacing] = useState([]);

  const navigate = useNavigate();
  const [localityData] = useOutletContext();

  const dispatch = useDispatch();
  const { redirectModal, isLoader } = bindActionCreators(actionCreator, dispatch);
  const showLoader = useSelector((state) => state.isLoader);


  const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: propertyDetails,
    validationSchema: PropertyDetailsSchema,
    onSubmit: async (values, action) => {
      if (values) {
        const payload = {
          bhkTypeId: parseInt(values.bhkTypes),
          latitudeAndLongitude: `${localityData.localityAddress.latitude},${localityData.localityAddress.langitude}`,
          locality: localityData.localityAddress.name,
          cityId: parseInt(localityData.city),
          landmarkStreet: localityData.street,
          apartmentTypeId: parseInt(values.apartmentType),
          apartmentName: values.propertyName,
          floorId: parseInt(values.floor),
          totalFloorId: parseInt(values.totalFloor),
          propertyAgeId: parseInt(values.propertyAge),
          propertyFacingId: parseInt(values.propertyFacing),
          builtUpArea: parseInt(values.area)
        }

        const response = await PropertyAndLocalityDetail(payload, isLoader, isTokenExpired, redirectModal);
        if (response != null) {
          const { isSuccess, data, errorMessages, successMessage } = response;
          if (isSuccess) { toast.success(successMessage, toastStyle); navigate(`${RouteLinks.PropertyListing}/${data.propertyListingId}${RouteLinks.RentalDetail}`); }
          else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle); }); }
        }
      }
    }
  });

  const getApartmentTypes = async () => {
    const response = await ApartmentTypes();
    if (response != null) {
      const { isSuccess, data, errorMessages } = response;
      if (isSuccess) { setApartmentType(data); }
      else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle) }) }
    }
  }

  const getBHKTypes = async () => {
    const response = await BHKTypes();
    if (response != null) {
      const { isSuccess, data, errorMessages } = response;
      if (isSuccess) { setBhkType(data); }
      else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle) }) }
    }
  }

  const getFloors = async () => {
    const response = await Floors();
    if (response != null) {
      const { isSuccess, data, errorMessages } = response;
      if (isSuccess) { setFloor(data); }
      else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle) }) }
    }
  }

  const getTotalFloors = async () => {
    const response = await TotalFloors();
    if (response != null) {
      const { isSuccess, data, errorMessages } = response;
      if (isSuccess) { setTotalFloor(data); }
      else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle) }) }
    }
  }

  const getPropertyAges = async () => {
    const response = await PropertyAges();
    if (response != null) {
      const { isSuccess, data, errorMessages } = response;
      if (isSuccess) { setPropertyAge(data); }
      else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle) }) }
    }
  }

  const getPropertyFacing = async () => {
    const response = await PropertyFacing();
    if (response != null) {
      const { isSuccess, data, errorMessages } = response;
      if (isSuccess) { setPropertyFacing(data); }
      else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle) }) }
    }
  }

  useEffect(() => {

    getApartmentTypes();
    getBHKTypes();
    getFloors();
    getTotalFloors();
    getPropertyAges();
    getPropertyFacing();

  }, []);


  return (
    <>
      <h6 className="tab_title">{appTheme.propertyDetails.title}</h6>
      <div className="tab_content">
        <Form >
          <Row className="mt-4">
            <Col md={5}>
              <Form.Group>
                <Form.Label className="form_label">{appTheme.propertyDetails.propertyName} *</Form.Label>
                <Form.Control size="sm" className="form_input" type="text" placeholder={appTheme.propertyDetails.propertyName} name="propertyName" value={values.propertyName} onChange={handleChange} onBlur={handleBlur} />
              </Form.Group>
            </Col>
            <Col md={5} className="mt-4 mt-md-0">
              <Form.Group>
                <Form.Label className="form_label">{appTheme.propertyDetails.appartmentType} *</Form.Label>
                <Form.Select size="sm" className="form_select" aria-label="Default select example" name="apartmentType" value={values.apartmentType} onChange={handleChange} onBlur={handleBlur}>
                  <option value=""> {appTheme.propertyDetails.appartmentType}</option>
                  {apartmentType.map((items) => {
                    return (
                      <option key={items.apartmentTypeId} value={items.apartmentTypeId}>{items.type}</option>
                    )
                  })}
                </Form.Select>
                {errors.apartmentType && touched.apartmentType ? (<p className="form-error">{errors.apartmentType}</p>) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={5}>
              <Form.Group>
                <Form.Label className="form_label">{appTheme.propertyDetails.bhkType} *</Form.Label>
                <Form.Select size="sm" className="form_select" aria-label="Default select example" name="bhkTypes" value={values.bhkTypes} onChange={handleChange} onBlur={handleBlur}>
                  <option value=""> {appTheme.propertyDetails.bhkType} </option>
                  {bhkType.map((items) => {
                    return (
                      <option key={items.bhkTypeId} value={items.bhkTypeId}>{items.type}</option>
                    )
                  })}
                </Form.Select>
                {errors.bhkTypes && touched.bhkTypes ? (<p className="form-error">{errors.bhkTypes}</p>) : null}
              </Form.Group>
            </Col>
            <Col md={5} className="mt-4 mt-md-0">
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className="form_label">{appTheme.propertyDetails.floor} *</Form.Label>
                    <Form.Select size="sm" className="form_select" aria-label="Default select example" name="floor" value={values.floor} onChange={handleChange} onBlur={handleBlur}>
                      <option value="" > {appTheme.propertyDetails.floor}</option>
                      {floor.map((items) => {
                        return (
                          <option key={items.floorId} value={items.floorId}>{items.floor}</option>
                        )
                      })}
                    </Form.Select>
                    {errors.floor && touched.floor ? (<p className="form-error">{errors.floor}</p>) : null}
                  </Form.Group>
                </Col>
                <Col md={6} className="mt-4 mt-md-0">
                  <Form.Group>
                    <Form.Label className="form_label">{appTheme.propertyDetails.totalFloor} *</Form.Label>
                    <Form.Select size="sm" className="form_select" aria-label="Default select example" name="totalFloor" value={values.totalFloor} onChange={handleChange} onBlur={handleBlur}>
                      <option value="" >{appTheme.propertyDetails.totalFloor} </option>
                      {totalFloor.map((items) => {
                        return (
                          <option key={items.totalFloorId} value={items.totalFloorId}>{items.totalFloor}</option>
                        )
                      })}
                    </Form.Select>
                    {errors.totalFloor && touched.totalFloor ? (<p className="form-error">{errors.totalFloor}</p>) : null}
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={5}>
              <Form.Group>
                <Form.Label className="form_label">{appTheme.propertyDetails.propertyAge} *</Form.Label>
                <Form.Select size="sm" className="form_select" aria-label="Default select example" name="propertyAge" value={values.propertyAge} onChange={handleChange} onBlur={handleBlur}>
                  <option value="" > {appTheme.propertyDetails.propertyAge}</option>
                  {propertyAge.map((items) => {
                    return (
                      <option key={items.propertyAgeId} value={items.propertyAgeId}>{items.ageRange}</option>
                    )
                  })}
                </Form.Select>
                {errors.propertyAge && touched.propertyAge ? (<p className="form-error">{errors.propertyAge}</p>) : null}
              </Form.Group>
            </Col>
            <Col md={5} className="mt-4 mt-md-0">
              <Form.Group>
                <Form.Label className="form_label">{appTheme.propertyDetails.facing} *</Form.Label>
                <Form.Select size="sm" className="form_select" aria-label="Default select example" name="propertyFacing" value={values.propertyFacing} onChange={handleChange} onBlur={handleBlur}>
                  <option value="" hidden>{appTheme.propertyDetails.facing}</option>
                  {propertyFacing.map((items) => {
                    return (
                      <option key={items.propertyFacingId} value={items.propertyFacingId}>{items.facing}</option>
                    )
                  })}
                </Form.Select>
                {errors.propertyFacing && touched.propertyFacing ? (<p className="form-error">{errors.propertyFacing}</p>) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={5}>
              <Form.Group>
                <Form.Label className="form_label">{appTheme.propertyDetails.builtUpArea} *</Form.Label>
                <Form.Control size="sm" className="form_input" type="text" placeholder={appTheme.propertyDetails.builtUpArea} name="area" value={values.area} onChange={handleChange} onBlur={handleBlur} />
                {errors.area && touched.area ? (<p className="form-error">{errors.area}</p>) : null}
              </Form.Group>
            </Col>
          </Row>
          <div className="next_btn">
            <Button size="sm" className="mt-5" onClick={handleSubmit}>
              {showLoader ? <Spinner animation="border" className='spinner_sm' /> : <>{appTheme.propertyDetails.submitButton}</>}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default PropertyDetails;
