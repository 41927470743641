
import React, { useEffect } from "react";
import "../../../../styles/others/blog.scss";
import Aos from "aos";
import appTheme from "../../../../../app-theme.json";
import Banner from "../../../../components/common/banner";
import BlogSection from "./components/blog-section";
import BottomTopButton from "../../../../components/common/bottom-top-button";

export const Blog = () => {

    const addBlogComponents = (blogPageItem) => {

        switch (blogPageItem.componentName) {
            case "BlogBanner":
                return { ...blogPageItem, component: <Banner bannerContent={appTheme.blogPage.blogBannerContents} /> }
            case "BlogSection":
                return { ...blogPageItem, component: <BlogSection /> }
            case "BottomTopButton":
                return { ...blogPageItem, component: <BottomTopButton /> }
            default:
                break;
        }
    }

    let blogPageComponents = appTheme.blogPage.blogPageComponents.map((blogPageItem) => addBlogComponents(blogPageItem));
    blogPageComponents.sort((a, b) => a.position - b.position);

    useEffect(() => { Aos.init(); }, []);

    return (
        <>
            {blogPageComponents.map((items) => {
                if (items && items.isPublished)
                    return (
                        <section key={items.position}>{items.component}</section>
                    )
            })}
        </>
    );
};

