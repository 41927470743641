import React, { useEffect, useState } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { FaRegEdit } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../../state';
import UpdateAvatarModal from './update-avatar';
import UpdateInformationModal from './update-information';
import UpdateAddressModal from './update-address';
import appTheme from "../../../../../app-theme.json";
import { AllStates, AllCities, Countries } from '../../../../services/api/Lookups';


const ProfileTab = ({ clientDetail }) => {
    const [countries, setCountries] = useState([]);
    const [allStates, setAllStates] = useState([]);
    const [allCities, setAllCities] = useState([]);
    const [stateName, setStateName] = useState("");
    const [cityName, setCityName] = useState("");

    const dispatch = useDispatch();
    const { showAvatarModal, showPersonalInfoModal, showAddressModal } = bindActionCreators(actionCreator, dispatch);

    const { firstName, lastName, email, contacts, profilePicture, address } = clientDetail;
    if (contacts) {
        var { contactNumber, countryCode } = contacts[0];
    }

    const getCountries = async () => {
        const response = await Countries();
        if (response != null) { setCountries(response.data[0]) }
    }
    const getAllStates = async () => {
        const response = await AllStates();
        if (response != null) { setAllStates(response.data) }
    }
    const getAllCities = async () => {
        const response = await AllCities();
        if (response != null) { setAllCities(response.data) }
    }

    useEffect(() => {
        getCountries();
        getAllStates();
        getAllCities();
    }, []);

    useEffect(() => {
        if (address) {
            allStates.forEach((item) => {
                if (item.stateId === address.stateId)
                    setStateName(item.stateName);
            });
            allCities.forEach((item) => {
                if (item.cityId === address.cityId)
                    setCityName(item.cityName);
            });
        }
    }, [address, allStates, allCities]);


    return (
        <div className='profile'>
            <h4 className='title'>{appTheme.profilePage.title}</h4>
            <div className="profile_img_sec">
                <div className="img_box">
                    <div className="image">
                        <Image src={profilePicture ? profilePicture : appTheme.userDummyImage} />
                    </div>
                    <div className="name_box">
                        <h3 className="name">{firstName} {lastName}</h3>
                        <p>{cityName} {stateName}</p>
                    </div>
                </div>
                <Button className="edit_btn" onClick={() => showAvatarModal(true)}><FaRegEdit />{appTheme.profilePage.button}</Button>
            </div>

            <div className="personal_info">
                <h6 className='per_title'>{appTheme.profilePage.personalInfo.title}</h6>
                <Row>
                    <Col sm={5} className='mb-3'>
                        <p className='per_label'>{appTheme.profilePage.personalInfo.firstName}</p>
                        <h6 className='per_value'>{firstName}</h6>
                    </Col>
                    <Col sm={7} className='mb-3'>
                        <p className='per_label'>{appTheme.profilePage.personalInfo.lastName}</p>
                        <h6 className='per_value'>{lastName}</h6>
                    </Col>
                    <Col sm={5}>
                        <p className='per_label'>{appTheme.profilePage.personalInfo.email}</p>
                        <h6 className='per_value'>{email}</h6>
                    </Col>
                    <Col sm={7}>
                        <p className='per_label'>{appTheme.profilePage.personalInfo.mobile}</p>
                        <h6 className='per_value'>{countryCode}{contactNumber}</h6>
                    </Col>
                </Row>
                <Button className="edit_btn" onClick={() => showPersonalInfoModal(true)}><FaRegEdit />{appTheme.profilePage.button}</Button>
            </div>

            <div className="address_info">
                <h6 className='per_title'>{appTheme.profilePage.address.title}</h6>
                <Row>
                    <Col sm={5} className='mb-3'>
                        <p className='per_label'>{appTheme.profilePage.address.country}</p>
                        <h6 className='per_value'>{countries.name}</h6>
                    </Col>
                    <Col sm={7} className='mb-3'>
                        <p className='per_label'>{appTheme.profilePage.address.state}</p>
                        <h6 className='per_value'>{stateName}</h6>
                    </Col>
                    <Col sm={5}>
                        <p className='per_label'>{appTheme.profilePage.address.city}</p>
                        <h6 className='per_value'>{cityName}</h6>
                    </Col>
                    <Col sm={7}>
                        <p className='per_label'>{appTheme.profilePage.address.pincode}</p>
                        <h6 className='per_value'>{address ? address.zipCode : ""}</h6>
                    </Col>
                </Row>
                <Button className="edit_btn" onClick={() => showAddressModal(true)}><FaRegEdit />{appTheme.profilePage.button}</Button>
            </div>

            <UpdateAvatarModal profilePicture={profilePicture} />
            <UpdateInformationModal clientDetail={clientDetail} />
            <UpdateAddressModal addressData={{ countries, allStates, allCities, address }} />
        </div>
    )
}

export default ProfileTab
