import axios from 'axios';
import AppSetting from '../../../appConfig.json';
import { errorHandler } from '../validation/error-handler';
import { httpHeaders } from '../../utils/http-requests';

export const GetAllLookups = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/GetAll";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}

export const Countries = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/Countries";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}

export const Cities = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/Cities";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}

export const AllStates = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/AllStates";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}

export const AllCities = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/AllCities";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}

export const PropertySector = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/PropertySector";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}

export const PropertyType = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/PropertyType";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}

export const ApartmentTypes = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/ApartmentTypes";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}

export const BHKTypes = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/BHKTypes";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}

export const Floors = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/Floors";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}
export const TotalFloors = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/TotalFloors";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}
export const PropertyAges = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/PropertyAges";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}

export const PropertyFacing = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/PropertyFacing";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}

export const PropertyAvailabilities = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/PropertyAvailabilities";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}

export const MonthlyMaintenance = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/MonthlyMaintenance";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}

export const PreferredTenants = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/PreferredTenants";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}

export const FurnishingTypes = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/FurnishingTypes";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}

export const ParkingTypes = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/ParkingTypes";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}

export const WaterSupplies = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/WaterSupplies";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}

export const PropertyShownBy = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/PropertyShownBy";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}

export const Amenities = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/Lookups/Amenities";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}
