const RouteLinks = {
  Login: "/account",
  Home: "/",
  AboutUs: "/about-us",
  FAQs: "/faqs",
  PrivacyPolicy: "/privacy-policy",
  TermAndConditions: "/terms&condition",
  Contact: "/contact",
  Career: "/career",
  CareerDetails: "/career-details",
  ReferAndEarn: "/refer&earn",
  Blog: "/blog",
  BlogDetail: "/blog-detail",
  AddProperty: "/add",
  PropertyListing: "/listing",
  EditProperty: "/:id",
  PropertyLocality: "/property-locality",
  PropertyDetail: "/property-detail",
  PropertyDetailId: "/:id",
  RentalDetail: "/rental-detail",
  Amenities: "/amenities",
  Gallery: "/gallery",
  Publish: "/publish",
  Profile: "/profile",
  Favourite: "/favourite",
  Chats: "/chats",
  DocumentKyc: "/document-kyc",
  Tenent: "/tenant",
  Tenency: "/tenancy",
  Dashboard: "/dashboard",
  PropertySearch: "/property-search",
  NotFound: "*",
};

export default RouteLinks;
