import * as Yup from 'yup';

const errorMessage = {
    apartmentType: "Please select apartment type",
    bhkTypes: "Please select BHK type",
    floor: "Please select floor",
    totalFloor: "Please select total floor",
    propertyAge: "Please select property age",
    propertyFacing: "Please select property facing",
    area: "Please Enter property area",
}


export const PropertyDetailsSchema = Yup.object({
    apartmentType: Yup.string().required(errorMessage.apartmentType),
    bhkTypes: Yup.string().required(errorMessage.bhkTypes),
    floor: Yup.string().required(errorMessage.floor),
    totalFloor: Yup.string().required(errorMessage.totalFloor),
    propertyAge: Yup.string().required(errorMessage.propertyAge),
    propertyFacing: Yup.string().required(errorMessage.propertyFacing),
    area: Yup.string().required(errorMessage.area),
})