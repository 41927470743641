import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Spinner, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../../state';
import { useFormik } from 'formik';
import { address } from '../../../../helper/profile';
import isTokenExpired from '../../../../utils/jwt-auth';
import appTheme from "../../../../../app-theme.json";
import { profileAddressSchema } from '../../../../helper/validations/profile';
import { UpdateAddress } from '../../../../services/api/client';

const UpdateAddressModal = ({ addressData }) => {
    const [stateWiseCities, setStateWiseCities] = useState([]);
    const dispatch = useDispatch();
    const { showAddressModal, isLoader, redirectModal, showProfileImage } = bindActionCreators(actionCreator, dispatch);
    const showAddress = useSelector((state) => state.showAddressModal);
    const showLoader = useSelector((state) => state.isLoader);
    const profileImage = useSelector((state) => state.showProfileImage);


    const closeAddressModal = () => { showAddressModal(false); }

    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setValues, resetForm } = useFormik({
        initialValues: address,
        validationSchema: profileAddressSchema,
        onSubmit: async (values) => {
            const payload = { cityId: parseInt(values.city), stateId: parseInt(values.state), zipCode: `${values.pinCode}` }
            const response = await UpdateAddress(payload, isLoader, isTokenExpired, redirectModal);
            if (response != null) { closeAddressModal(); showProfileImage(!profileImage); }
        }
    });

    useEffect(() => {
        if (addressData.address) {
            setValues({ ...values, state: addressData.address.stateId, city: addressData.address.cityId, pinCode: addressData.address.zipCode });
        }
    }, [addressData.address, showAddress]);

    useEffect(() => {
        const filteredCities = addressData.allCities.filter(item => {
            return item.stateId === parseInt(values.state);
        });
        setStateWiseCities(filteredCities);
    }, [values.state]);


    const stateChagnehandler = (e) => {
        setValues({ ...values, state: e.target.value, city: "", pinCode: "" });
    }
    const cityChagnehandler = (e) => {
        setValues({ ...values, city: e.target.value });
    }

    if (!addressData) { return; }

    return (
        <Modal show={showAddress} onHide={closeAddressModal} centered>
            <Modal.Header closeButton>
                <h4 className='title'>{appTheme.profilePage.address.modalTitle}</h4>
            </Modal.Header>
            <Modal.Body>
                <Form className='fs--1'>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3" >
                                <Form.Label>{appTheme.profilePage.address.country}</Form.Label>
                                <Form.Control size='sm' type="text" name='country' disabled value={values.country} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3" >
                                <Form.Label>{appTheme.profilePage.address.state}</Form.Label>
                                <Form.Select size='sm' aria-label="Default select example" name='state' value={values.state} onChange={stateChagnehandler} onBlur={handleBlur}>
                                    <option value="">state</option>
                                    {addressData.allStates.map((item) => {
                                        return (
                                            <option key={item.stateId} value={item.stateId}>{item.stateName}</option>
                                        )
                                    })}
                                </Form.Select>
                                {errors.state && touched.state ? (<p className="form-error">{errors.state}</p>) : null}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3" >
                                <Form.Label>{appTheme.profilePage.address.city}</Form.Label>
                                <Form.Select size='sm' aria-label="Default select example" disabled={values.state ? false : true} name='city' value={values.city} onChange={cityChagnehandler} onBlur={handleBlur}>
                                    <option value="">city</option>
                                    {stateWiseCities.map((item) => {
                                        return (
                                            <option key={item.cityId} value={item.cityId}>{item.cityName}</option>
                                        )
                                    })}
                                </Form.Select>
                                {errors.city && touched.city ? (<p className="form-error">{errors.city}</p>) : null}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3" >
                                <Form.Label>{appTheme.profilePage.address.pincode}</Form.Label>
                                <Form.Control size='sm' type="number" placeholder="Enter Pincode" name='pinCode' value={values.pinCode} onChange={handleChange} onBlur={handleBlur} />
                                {errors.pinCode && touched.pinCode ? (<p className="form-error">{errors.pinCode}</p>) : null}
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="btn_box">
                        <Button className="cancel_btn" onClick={closeAddressModal}>Cancel</Button>
                        <Button size='sm' className='submit_btn' onClick={handleSubmit} disabled={showLoader}>
                            {showLoader ? <Spinner animation="border" className='spinner_sm' /> : <>{appTheme.profilePage.address.submitButton}</>}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal >
    )
}

export default UpdateAddressModal
