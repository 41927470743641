import React from 'react';
import { Form } from 'react-bootstrap';
import { FaChevronDown } from "react-icons/fa";

export const SearchUser = (inputs) => {

    return <>
        <div className="message_title">
            <div className="messages">Messages <FaChevronDown /></div>
            <span className='notification'>{inputs.params.unreadCount}</span>
        </div>

        {/* <Form className='search_form'>
            <Form.Control name="" placeholder="Search messages" />
        </Form> */}
    </>
}