import { httpHeadersWithToken } from '../../utils/http-requests';
import AppSetting from '../../../appConfig.json';
import axios from 'axios';
import { errorHandler } from '../validation/error-handler';

export const Notification = async (isTokenExpired) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { return; }
    else {
        try {
            const url = AppSetting.SERVER_URL + `/Notification`;
            const res = await axios.get(url, httpHeadersWithToken());
            return res.data;
        } catch (error) {
            return errorHandler(error);
        }
    }
}

export const MarkAllRead = async (isTokenExpired) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { return; }
    else {
        try {
            const url = AppSetting.SERVER_URL + `/Notification/MarkAllRead`;
            const res = await axios.get(url, httpHeadersWithToken());
            return res.data;
        } catch (error) {
            return errorHandler(error);
        }
    }
}