import React from 'react';
import Gallery from '../../components/property-listing/Gallery';

export const GalleryTab = () => {
    return (
        <>
            <Gallery />
        </>
    )
}

