const showAndHideModalAction = {
    mobileVaification: "mobileVaification",
    toggleAppLoader: "toggleAppLoader",
    toggleLoginModal: "toggleLoginModal",
    toggleSignupModal: "toggleSignupModal",
    toggleForgotPasswordModal: "toggleForgotPasswordModal",
    showSaveAndDraft: "showSaveAndDraft",
    showSaveAndPost: "showSaveAndPost",
    showSaveAndPostLater: "showSaveAndPostLater",
    showDiscardModal: "showDiscardModal",
    isAuthenticated: "isAuthenticated",
    confirmProfileModal: "confirmProfileModal",
    redirectModal: "redirectModal",
    showProfileImage: "showProfileImage",
    showAvatarModal: "showAvatarModal",
    showPersonalInfoModal: "showPersonalInfoModal",
    showAddressModal: "showAddressModal",
    hideFooter: "hideFooter",
    isLoading: "isLoading",
    isLoader: "isLoader",
    isPropertyLoading: "isPropertyLoading",
    clientLoader: "clientLoader",
    deleteImage: "deleteImage",
    changeImage: "changeImage",
    changeDocument: "changeDocument",
    showProfileSidebar: "showProfileSidebar",
    showPropertyPanel: "showPropertyPanel",
    showOwnerDetail: "showOwnerDetail",
    confirmAndStatus: "confirmAndStatus",
    statusConfirmation: "statusConfirmation",
    favouritePropertyLoader: "favouritePropertyLoader",
    recentPath: "recentPath",
    ownerDetailModal: "ownerDetailModal",
    uploadPhotosModal: "uploadPhotosModal"

}
export default showAndHideModalAction