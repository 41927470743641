import { useEffect } from "react";
import "../../../../styles/others/career.scss";
import Aos from "aos";
import appTheme from "../../../../../app-theme.json";
import Banner from "../../../../components/common/banner";
import CareerCategories from "./components/career-categories";
import BottomTopButton from "../../../../components/common/bottom-top-button";

export const Career = () => {

    const addCareerComponents = (careerItems) => {

        switch (careerItems.componentName) {
            case "CareerBanner":
                return { ...careerItems, component: <Banner bannerContent={appTheme.careerPage.careerBannerContents} /> }
            case "CareerCategories":
                return { ...careerItems, component: <CareerCategories /> }
            case "BottomTopButton":
                return { ...careerItems, component: <BottomTopButton /> }
            default:
                break;
        }
    }

    let careerPageComponents = appTheme.careerPage.careerPageComponents.map((careerItems) => addCareerComponents(careerItems));
    careerPageComponents.sort((a, b) => a.position - b.position);

    useEffect(() => { Aos.init(); }, []);

    return (
        <>
            {careerPageComponents.map((items) => {
                if (items && items.isPublished)
                    return (
                        <section key={items.position}>{items.component}</section>
                    )
            })}
        </>
    );
};
