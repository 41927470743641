import React from 'react';
import { Modal, Form, Button, Spinner, } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../../state';
import appTheme from "../../../../../app-theme.json";

const UploadPhotos = () => {

    const dispatch = useDispatch();
    const { uploadPhotosModal } = bindActionCreators(actionCreator, dispatch);

    const show = useSelector((state) => state.uploadPhotosModal);
    const showLoader = useSelector((state) => state.isLoader);

    const handleClose = () => { uploadPhotosModal(false) }

    const allLookups = JSON.parse(localStorage.getItem("lookups"));

    const { modal } = appTheme.referAndEarnPage.referAndEarnSection.uploadPhotos;

    return (
        <Modal show={show} onHide={handleClose} centered >
            <Modal.Header closeButton>
                <h4 className='title'>{modal.title}</h4>
            </Modal.Header>
            <Modal.Body>
                <div className="owner_detail">
                    <Form className='fs--1'>
                        <Form.Group className="mb-3">
                            <Form.Label>{modal.city.label}</Form.Label>
                            <Form.Select size="sm">
                                <option value="">{modal.city.placeholder}</option>
                                {allLookups.cities.map((citiesItem) => <option key={citiesItem.citiesLookupId} value={citiesItem.citiesLookupId}>{citiesItem.name}</option>)}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{modal.photos.label}</Form.Label>
                            <Form.Control size="sm" type="file" />
                        </Form.Group>
                        <div className="btn_box">
                            <Button className="cancel_btn" onClick={handleClose}>{modal.cancelButton}</Button>
                            <Button size='sm' className='submit_btn' disabled={showLoader}>
                                {showLoader ? <Spinner animation="border" className='spinner_sm' /> : <>{modal.submitButton}</>}
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default UploadPhotos
