import React from 'react';
import { Modal, Form, Button, Spinner, } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../../state';
import appTheme from "../../../../../app-theme.json";

const OwnerDetailsModal = () => {

    const dispatch = useDispatch();
    const { ownerDetailModal } = bindActionCreators(actionCreator, dispatch);

    const show = useSelector((state) => state.ownerDetailModal);
    const showLoader = useSelector((state) => state.isLoader);

    const handleClose = () => { ownerDetailModal(false) }

    const allLookups = JSON.parse(localStorage.getItem("lookups"));

    const { modalDetail } = appTheme.referAndEarnPage.referAndEarnSection.ownerDetails;

    return (
        <Modal show={show} onHide={handleClose} centered >
            <Modal.Header closeButton>
                <h4 className='title'>{modalDetail.title}</h4>
            </Modal.Header>
            <Modal.Body>
                <div className="owner_detail">
                    <Form className='fs--1'>
                        <Form.Group className="mb-3">
                            <Form.Label>{modalDetail.city.label}</Form.Label>
                            <Form.Select size="sm">
                                <option value="">{modalDetail.city.placeholder}</option>
                                {allLookups.cities.map((citiesItem) => <option key={citiesItem.citiesLookupId} value={citiesItem.citiesLookupId}>{citiesItem.name}</option>)}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{modalDetail.mobileNumber.label}</Form.Label>
                            <Form.Control size="sm" type="number" placeholder={modalDetail.mobileNumber.placeholder} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{modalDetail.ownerName.label}</Form.Label>
                            <Form.Control size="sm" type="text" placeholder={modalDetail.ownerName.placeholder} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{modalDetail.message.label}</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder={modalDetail.message.placeholder} />
                        </Form.Group>
                        <div className="btn_box">
                            <Button className="cancel_btn" onClick={handleClose}>{modalDetail.cancelButton}</Button>
                            <Button size='sm' className='submit_btn' disabled={showLoader}>
                                {showLoader ? <Spinner animation="border" className='spinner_sm' /> : <>{modalDetail.submitButton}</>}
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default OwnerDetailsModal
