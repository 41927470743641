export const profileInitialValue = {
    clientId: "",
    firstName: "",
    lastName: "",
    email: "",
    isVerifiedEmail: false,
    profilePicture: null,
    contacts: [
        {
            contactNumber: "",
            countryCode: "",
            isPrimaryContact: true,
            isVerifiedContactNumber: true
        }
    ]
}

export const address = {
    country: "India",
    state: "",
    city: "",
    pinCode: ""
}