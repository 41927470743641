import React, { useEffect } from "react";
import PropertyClients from "../../../components/common/property-counter";
import ChooseUs from "./components/choose-us";
import "../../../styles/others/about-us.scss";
import Aos from "aos";
import appTheme from "../../../../app-theme.json";
import Banner from "../../../components/common/banner";
import BottomTopButton from "../../../components/common/bottom-top-button";

export const AboutUs = () => {

    const addAboutComponents = (aboutPageItem) => {

        switch (aboutPageItem.componentName) {
            case "ChooseUs":
                return { ...aboutPageItem, component: <ChooseUs /> }
            case "PropertyClients":
                return { ...aboutPageItem, component: <PropertyClients /> }
            case "AboutBanner":
                return { ...aboutPageItem, component: <Banner bannerContent={appTheme.aboutPage.AboutBannerContents} /> }
            case "BottomTopButton":
                return { ...aboutPageItem, component: <BottomTopButton /> }
            default:
                break;
        }
    }

    let aboutPageComponents = appTheme.aboutPage.aboutPageComponents.map((aboutPageItem) => addAboutComponents(aboutPageItem));
    aboutPageComponents.sort((a, b) => a.position - b.position);

    useEffect(() => { Aos.init(); }, []);

    return (
        <>
            {aboutPageComponents.map((items) => {
                if (items && items.isPublished)
                    return (
                        <section key={items.position}>{items.component}</section>
                    )
            })}
        </>
    );
};
