
import ReducerActions from "../../utils/reducer-actions";

export const toggleAppLoader = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.toggleAppLoader,
      payload: show,
    });
  };
};

export const toggleLoginModal = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.toggleLoginModal,
      payload: show,
    });
  };
};

export const toggleSignupModal = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.toggleSignupModal,
      payload: show,
    });
  };
};

export const toggleForgotPasswordModal = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.toggleForgotPasswordModal,
      payload: show,
    });
  };
};

export const toggleMobileVarificationModal = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.mobileVaification,
      payload: show,
    });
  };
};

export const showSaveAndDraft = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.showSaveAndDraft,
      payload: show,
    });
  };
};

export const showSaveAndPost = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.showSaveAndPost,
      payload: show,
    });
  };
};

export const showSaveAndPostLater = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.showSaveAndPostLater,
      payload: show,
    });
  };
};

export const showDiscardModal = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.showDiscardModal,
      payload: show,
    });
  };
};

export const isAuthenticated = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.isAuthenticated,
      payload: show,
    });
  };
};

export const confirmProfileModal = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.confirmProfileModal,
      payload: show,
    });
  };
};

export const redirectModal = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.redirectModal,
      payload: show,
    });
  };
};

export const showProfileImage = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.showProfileImage,
      payload: show,
    });
  };
};

export const showAvatarModal = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.showAvatarModal,
      payload: show,
    });
  };
};

export const showPersonalInfoModal = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.showPersonalInfoModal,
      payload: show,
    });
  };
};

export const showAddressModal = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.showAddressModal,
      payload: show,
    });
  };
};

export const hideFooter = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.hideFooter,
      payload: show,
    });
  };
};

export const isLoading = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.isLoading,
      payload: show,
    });
  };
};

export const isLoader = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.isLoader,
      payload: show,
    });
  };
};

export const isPropertyLoading = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.isPropertyLoading,
      payload: show,
    });
  };
};

export const clientLoader = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.clientLoader,
      payload: show,
    });
  };
};

export const deleteImage = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.deleteImage,
      payload: show,
    });
  };
};

export const changeImage = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.changeImage,
      payload: show,
    });
  };
};

export const changeDocument = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.changeDocument,
      payload: show,
    });
  };
};

export const showProfileSidebar = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.showProfileSidebar,
      payload: show,
    });
  };
};

export const showPropertyPanel = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.showPropertyPanel,
      payload: show,
    });
  };
};

export const showOwnerDetail = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.showOwnerDetail,
      payload: show,
    });
  };
};

export const confirmAndStatus = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.confirmAndStatus,
      payload: show,
    });
  };
};

export const favouritePropertyLoader = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.favouritePropertyLoader,
      payload: show,
    });
  };
};

export const recentPath = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.recentPath,
      payload: show,
    });
  };
};

export const ownerDetailModal = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.ownerDetailModal,
      payload: show,
    });
  };
};

export const uploadPhotosModal = (show) => {
  return (dispatch) => {
    dispatch({
      type: ReducerActions.uploadPhotosModal,
      payload: show,
    });
  };
};