import React from 'react';
import PropertyLocation from '../../components/property-listing/PropertyLocation';


export const PropertyLocalityTab = () => {
    return (
        <>
            <PropertyLocation />
        </>
    )
}

