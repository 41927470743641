import React from 'react';
import "../../../../styles/others/terms-and-condition.scss";
import { Container } from 'react-bootstrap';
import appTheme from "../../../../../app-theme.json";


const TermsAndCondition = () => {

    const { termAndCondition } = appTheme.termAndConditionPage;

    return (
        <div className="terms_condition_sec section_padding">
            <Container>
                <div className="main_title">
                    <h1 className="title">{termAndCondition.mainTitle}</h1>
                    <p className="subtitle">{termAndCondition.mainSubTitile}</p>
                </div>
                {
                    termAndCondition.termAndConditionContent.map((items) => {
                        return (
                            <div className="terms_condition" key={items.id}>
                                <h3 className="TC_title">{items.title}</h3>
                                <p className="TC_paragraph">{items.paragraph}</p>
                            </div>
                        )
                    })
                }

            </Container>
        </div>
    )
}

export default TermsAndCondition
