import React from 'react';
import { Dropdown, Figure } from 'react-bootstrap';
import { FaChevronDown } from "react-icons/fa";
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../state';
import appTheme from "../../../app-theme.json";

const UserDropdown = ({ clientDetail }) => {

    const previousPage = useNavigate();
    const logOut = useNavigate();

    const dispatch = useDispatch();
    const { isAuthenticated, redirectModal } = bindActionCreators(actionCreator, dispatch);

    const logoutHandler = () => {
        logOut(appTheme.home.path);
        isAuthenticated(false);
        localStorage.removeItem("token");
        redirectModal(false);
    }

    if (!clientDetail.firstName) { return }

    return (
        <Dropdown className='profile-dropdown'>
            <Dropdown.Toggle id="profile-dropdown">
                <Figure.Image src={clientDetail.profilePicture ? clientDetail.profilePicture : "images/user.png"} className='mb-0' />
                <p className='user_name'>{clientDetail.firstName.charAt(0)}{clientDetail.lastName.charAt(0)}<span> <FaChevronDown /> </span></p>
            </Dropdown.Toggle>
            <Dropdown.Menu className='dropdown_menu'>
                <Dropdown.Item as={Link} to={appTheme.myProfile.path}>{appTheme.myProfile.title}</Dropdown.Item>
                <Dropdown.Item as={Link} to={appTheme.myListing.path}>{appTheme.myListing.title}</Dropdown.Item>
                <Dropdown.Item as={Link} to={appTheme.myFavourite.path}>{appTheme.myFavourite.title}</Dropdown.Item>
                <Dropdown.Item as={Link} to={appTheme.documentKyc.path}>{appTheme.documentKyc.title}</Dropdown.Item>
                <Dropdown.Item as={Link} to={appTheme.myTenents.path}>{appTheme.myTenents.title}</Dropdown.Item>
                <Dropdown.Item onClick={() => previousPage(-1)}>{appTheme.recentlyViewed.title}</Dropdown.Item>
                <hr className='my-0' />
                <Dropdown.Item onClick={logoutHandler}>{appTheme.signOut.title}</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default UserDropdown
