export const initialAddTenant = {
    emailId: "",
    contactNumber: "",
    countryCode: "+91",
    rent: "",
    agreementStartDate: "",
    agreementEndDate: "",
    files: []
}

export const initialRemoveTenant = {
    reasonId: "",
    otherReason: "",
    confirmReason: []
}