import React from 'react';
import "../../../../styles/profile/tenent-list.scss";
import { Button, Image } from 'react-bootstrap';
import appTheme from "../../../../../app-theme.json";

const TenentList = ({ item, isActive, setActiveList, setShowDetails }) => {

    const activeHandler = (id) => {
        setActiveList(id)
        setShowDetails(true);
    }


    return (
        <li className={`tenant_detail ${isActive && "active_list"}`} onClick={() => activeHandler(item.id)}>
            <div className="tenant_name_img" >
                <span className="tenant_img ">
                    <Image src={item.img} className="rounded-1" />
                </span>
                <span className="tenant_name_location">
                    <span className="tenant_location fw-bold">{item.address}</span>
                </span>
            </div>
            <div>{item.date}</div>
            <div><span className={item.active}>{item.status}</span></div>
            <div >{item.rent}</div>
            <div><Button className='view_btn'>{appTheme.myTenantyPage.button}</Button></div>
        </li>
    )
}

export default TenentList
