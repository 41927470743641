import React from 'react';
import { Container, Spinner } from 'react-bootstrap';
import appTheme from "../../../../app-theme.json";
import PropertyItems from './property-items';
import { useSelector } from 'react-redux';

const Property = ({ listingItems, setFavLoadProperty }) => {

    const showPropertyLoader = useSelector((state) => state.isPropertyLoading);

    return (
        <div className='property_sec section_padding'>
            <Container>
                <div className="main_title">
                    <h1 className="title">{appTheme.regionalPropertyHomepage.mainTitle}</h1>
                    <p className="subtitle">{appTheme.regionalPropertyHomepage.mainSubTitile}</p>
                </div>
                {showPropertyLoader ? <div className="lazyLoader"><Spinner /></div>
                    : <PropertyItems listingItems={listingItems} setFavLoadProperty={setFavLoadProperty} />
                }

            </Container>
        </div>
    )
}

export default Property
