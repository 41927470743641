import React, { useState } from 'react';
import { Form, Row, Dropdown } from 'react-bootstrap';
import { FaBars, FaThLarge, FaSortAmountDownAlt } from "react-icons/fa";
import ListCard from './list-card';
import PropertyGridCard from './property-grid';
import appTheme from "../../../../../app-theme.json"
import PropertyCardItem from './property-card-item';

const PropertyResults = ({ listingItems, openHeartHandler, fullHeartHandler }) => {

    const [list, setList] = useState(false);
    const [grid, setGrid] = useState(true);
    const [sortTextContent, setsortTextContent] = useState({ sortValue: 0, sortType: "Select Sorting" });


    const listHandler = () => {
        setList(true);
        setGrid(false);
    }

    const gridHandler = () => {
        setList(false);
        setGrid(true);
    }

    const getSortHandler = (sortItem) => {
        setsortTextContent({ sortValue: sortItem.sortValue, sortType: sortItem.sortType });
    }

    const listClasses = list ? "list active_btn" : "list";
    const menuClasses = grid ? "menu active_btn" : "menu";

    return (
        <>
            <div className="property_results">
                <div className="scroll_box">
                    <div className="top_bar">
                        <div className='result_title'>
                            <h1 className='heading'>{appTheme.propertySearch.results} : {listingItems.length} {appTheme.propertySearch.label}</h1>
                        </div>
                        <div className="filter_col">
                            <div className="filter_box">
                                <FaBars className={listClasses} onClick={listHandler} />
                                <FaThLarge className={menuClasses} onClick={gridHandler} />
                                <div className="sort">
                                    <FaSortAmountDownAlt />
                                    <p>{appTheme.propertySearch.sortLabel}</p>
                                </div>
                                <div className="sort_dropdown">
                                    <Dropdown>
                                        <Dropdown.Toggle >
                                            <span className="select_text">{sortTextContent.sortType}</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {appTheme.propertySearch.sortBy.map((sortItem) => {
                                                return (
                                                    <Dropdown.Item key={sortItem.sortId} onClick={() => getSortHandler(sortItem)}>{sortItem.sortType}</Dropdown.Item>
                                                )
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Row className="card_row">
                        {grid &&
                            listingItems.map((items) => {
                                return (
                                    <PropertyCardItem
                                        propertyCardItems={items}
                                        openHeartHandler={openHeartHandler}
                                        fullHeartHandler={fullHeartHandler}
                                        key={items.propertyListingId}
                                    />
                                )
                            })
                        }
                        {list &&
                            listingItems.map((item) => {
                                return (
                                    <ListCard
                                        item={item}
                                        key={item.propertyListingId}
                                        openHeartHandler={openHeartHandler}
                                        fullHeartHandler={fullHeartHandler}
                                    />
                                )
                            })
                        }
                    </Row>
                </div>
            </div>
        </>
    )
}

export default PropertyResults
