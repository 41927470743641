import React from 'react';
import appTheme from "../../../../../../app-theme.json";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import RouteLinks from "../../../../../utils/route";
import { FaComments, FaHeadset, FaUserTie, FaList, FaDraftingCompass, FaChartLine, FaHandsHelping, FaBookReader } from "react-icons/fa";

const CareerCategories = () => {

    const { mainTitle, mainSubTitile, careerData } = appTheme.careerPage.careerSection;

    const addCategoriesIcon = (CategoriesItem) => {

        switch (CategoriesItem.careerGridId) {
            case 1:
                return { ...CategoriesItem, icon: <FaComments /> }
            case 2:
                return { ...CategoriesItem, icon: <FaHeadset /> }
            case 3:
                return { ...CategoriesItem, icon: <FaUserTie /> }
            case 4:
                return { ...CategoriesItem, icon: <FaList /> }
            case 5:
                return { ...CategoriesItem, icon: <FaChartLine /> }
            case 6:
                return { ...CategoriesItem, icon: <FaHandsHelping /> }
            case 7:
                return { ...CategoriesItem, icon: <FaBookReader /> }
            case 8:
                return { ...CategoriesItem, icon: <FaDraftingCompass /> }
            default:
                break;
        }
    }

    let _careerData = careerData.map((CategoriesItem) => addCategoriesIcon(CategoriesItem));

    _careerData.sort((a, b) => a.position - b.position);


    return (
        <div className="career_section section_padding">
            <Container>
                <div className="main_title">
                    <h1 className="title">{mainTitle}</h1>
                    <p className="subtitle">{mainSubTitile}</p>
                </div>
                <div className="career_category">
                    <Row>
                        {_careerData.map((items) => {
                            return (
                                <Col lg={3} sm={6} key={items.careerGridId} className="mb-4">
                                    <Link className="cart_item" to={RouteLinks.CareerDetails}>
                                        <div className="icon">{items.icon}</div>
                                        <h6 className="cart_post">{items.postName}</h6>
                                        <p className="cart_vacancy">{items.postVacancy}</p>
                                    </Link>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default CareerCategories
