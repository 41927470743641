import { Navigate, Outlet } from "react-router-dom";
import RouteLinks from "../../utils/route";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreator } from "../../state";
import isTokenExpired from "../../utils/jwt-auth";
import { useEffect } from "react";

export const RootLayout = () => {

    const dispatch = useDispatch();
    const { isAuthenticated } = bindActionCreators(actionCreator, dispatch);


    if (isTokenExpired() === true || isTokenExpired() === undefined) {
        isAuthenticated(false);
        return <Navigate to={RouteLinks.Login} replace />;

    }
    else { isAuthenticated(true); }

    return <Outlet />;
};