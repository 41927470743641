import React from 'react';
import "../../../../styles/others/terms-and-condition.scss";
import { Col, Container, Row, Image, Button } from 'react-bootstrap';
import appTheme from "../../../../../app-theme.json";
import "../../../../styles/others/refer-and-earrn.scss";
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from "../../../../state";
import OwnerDetailsModal from './owner-details';
import UploadPhotos from './upload-photos';

const ReferAndEarnSection = () => {

    const dispatch = useDispatch();
    const { ownerDetailModal, uploadPhotosModal } = bindActionCreators(actionCreator, dispatch);

    const allLookups = JSON.parse(localStorage.getItem("lookups"));

    const { mainTitle, mainSubTitile, ownerDetails, uploadPhotos } = appTheme.referAndEarnPage.referAndEarnSection;

    return (
        <>
            <div className="refer_earn_sec section_padding">
                <Container>
                    <div className="main_title">
                        <h1 className="title">{mainTitle}</h1>
                        <p className="subtitle">{mainSubTitile}</p>
                    </div>
                    <div className="refer_earn">
                        <Row>
                            <Col lg={6} className="mb-4">
                                <div className="refer_img">
                                    <Image className="img-fluid" src={ownerDetails.image} />
                                </div>
                            </Col>
                            <Col lg={6} className="d-flex justfy-content-center align-items-center mb-4">
                                <div className="refer_content">
                                    <h4 className="refer_title">{ownerDetails.title}</h4>
                                    <p className="refer_para">{ownerDetails.subTitle}</p>
                                    <Button className="default_btn" onClick={() => ownerDetailModal(true)}>{ownerDetails.button}</Button>
                                </div>
                            </Col>
                            <Col lg={6} className="d-flex justfy-content-center align-items-center mb-4">
                                <div className="refer_content">
                                    <h4 className="refer_title">{uploadPhotos.title}</h4>
                                    <p className="refer_para">
                                        {allLookups.cities.map((citiesItem) => <>{citiesItem.name}, </>)}
                                    </p>
                                    <Button className="default_btn" onClick={() => uploadPhotosModal(true)} >{uploadPhotos.button}</Button>
                                </div>
                            </Col>
                            <Col lg={6} className="mb-4">
                                <div className="refer_img">
                                    <Image className="img-fluid" src={uploadPhotos.image} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <OwnerDetailsModal />
            <UploadPhotos />

        </>
    )
}

export default ReferAndEarnSection
