import React, { useEffect, useRef, useState } from 'react';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "../../../../styles/common/image-gallery.scss";
import { Image } from 'react-bootstrap';
import appTheme from "../../../../../app-theme.json";

const images = [

    {
        original: "assets/img/card/card_1.jpg",
        thumbnail: "assets/img/card/card_1.jpg",
        originalClass: "original_image",
    }
    , {
        original: "assets/img/card/card_2.jpg",
        thumbnail: "assets/img/card/card_2.jpg",
        originalClass: "original_image",

    }
    , {
        original: "assets/img/card/card_3.jpg",
        thumbnail: "assets/img/card/card_3.jpg",
        originalClass: "original_image",

    }
    , {
        original: "assets/img/card/card_4.jpg",
        thumbnail: "assets/img/card/card_4.jpg",
        originalClass: "original_image",

    }
    , {
        original: "assets/img/card/card_5.jpg",
        thumbnail: "assets/img/card/card_5.jpg",
        originalClass: "original_image",

    }
    , {
        original: "assets/img/card/card_6.jpg",
        thumbnail: "assets/img/card/card_6.jpg",
        originalClass: "original_image",
    }
    , {
        original: "assets/img/card/card_2.jpg",
        thumbnail: "assets/img/card/card_2.jpg",
        originalClass: "original_image",

    }
]


const PropertyGallery = ({ sliderImg }) => {
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const imageGalleryRef = useRef(null);

    const options = {
        autoPlay: false,
        showPlayButton: false,
        showThumbnails: true,
        // thumbnailPosition: windowSize > 768 ? "right" : "bottom",
        showIndex: true,
        showFullscreenButton: false,
        ref: imageGalleryRef,
        onClick: () => imageGalleryRef.current.toggleFullScreen(),

    }

    useEffect(() => {
        window.onresize = () => {
            setWindowSize(window.innerWidth);
        };
    }, []);

    return (

        <div className="image_gallery_box">
            {sliderImg.length > 0 ?
                <ImageGallery items={sliderImg} {...options} />
                : <Image className="not_available" src={appTheme.propertySearch.noImage} />
            }
        </div>
    )
}

export default PropertyGallery
