import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import AddressMap from '../../../../components/common/address-map';
import appTheme from "../../../../../app-theme.json";

const ContactUs = () => {

    const { addressMap, contactForm } = appTheme.contactPage.contactUs;

    return (
        <div className="contact_querys section_padding">
            <Container>
                <Row>
                    <Col lg={6} className="mb-3 mt-lg-0" data-aos="fade-up">
                        <div className="address_map">
                            <AddressMap addressMap={addressMap} />
                        </div>
                    </Col>
                    <Col lg={6} className="" data-aos="fade-down">
                        <Form className="contact_forms">
                            <Row>
                                <Col lg={6} className="mb-3">
                                    <Form.Group>
                                        <Form.Label>{contactForm.name.label}</Form.Label>
                                        <Form.Control type="text" placeholder={contactForm.name.placeHolder} />
                                    </Form.Group>
                                </Col>
                                <Col lg={6} className="mb-3">
                                    <Form.Group>
                                        <Form.Label>{contactForm.email.label}</Form.Label>
                                        <Form.Control type="email" placeholder={contactForm.email.placeHolder} />
                                    </Form.Group>
                                </Col>
                                <Col lg={12} className="mb-3">
                                    <Form.Group>
                                        <Form.Label>{contactForm.subject.label}</Form.Label>
                                        <Form.Control type="text" placeholder={contactForm.subject.placeHolder} />
                                    </Form.Group>
                                </Col>
                                <Col lg={12} className="mb-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label>{contactForm.message.label}</Form.Label>
                                        <Form.Control as="textarea" rows={3} placeholder={contactForm.message.placeHolder} />
                                    </Form.Group>
                                </Col>
                                <Col lg={12}>
                                    <Button className="send_btn">{contactForm.submitButton}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ContactUs
