import React from 'react';
import { Image, Spinner } from 'react-bootstrap';
import appTheme from "../../../../../app-theme.json";
import { useSelector } from 'react-redux';

export const Owner = ({ chatUser, chatLoader }) => {

    const authentication = useSelector((state) => state.isAuthenticated);


    return (
        <>
            {
                authentication ?
                    chatLoader ? <div className="owner_spin"><Spinner /></div> :
                        <div className="owner">
                            <div className="owner_img">
                                <Image src={chatUser.profilePicture ? chatUser.profilePicture : appTheme.userDummyImage} />
                            </div>
                            <div className="owner_address">
                                <span className="name">{chatUser.firstName} {chatUser.lastName}</span>
                            </div>
                        </div>
                    :
                    <div className="owner">
                        <div className="owner_img">
                            <Image src={appTheme.userDummyImage} />
                        </div>
                    </div>
            }

        </>

    )
}
