import * as Yup from 'yup';
import { validationMessage } from '../auth-error-message';

export const IsExistingNumberSchema = Yup.object({
    mobile: Yup.string().min(12, validationMessage.mobie.valid).required(validationMessage.mobie.required)
});

export const ValidateOtpAndLoginSchema = Yup.object({
    mobile: Yup.string().min(12, validationMessage.mobie.valid).required(validationMessage.mobie.required),
    otp: Yup.string().min(6, validationMessage.otp.valid).required(validationMessage.otp.required)
});

export const ValidatePasswordAndLoginSchema = Yup.object({
    mobile: Yup.string().min(11, validationMessage.mobie.valid).required(validationMessage.mobie.required),
    password: Yup.string().min(13, validationMessage.password.valid).required(validationMessage.password.required)
});


const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const RegisterSchema = Yup.object({
    firstName: Yup.string().min(3, validationMessage.firstName).required(validationMessage.firstName),
    lastName: Yup.string().min(3, validationMessage.lastName).required(validationMessage.lastName),
    email: Yup.string().matches(emailRegex, validationMessage.email.valid).required(validationMessage.email.required),
    mobile: Yup.string().min(12, validationMessage.mobie.valid).required(validationMessage.mobie.required),
    password: Yup.string().min(13, validationMessage.password.valid).required(validationMessage.password.required),
});