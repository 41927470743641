import React, { useEffect, useState } from 'react';
import "../../../../styles/common/property-card-item.scss";
import { Col, Image, Spinner } from 'react-bootstrap';
import { MdLocationOn } from "react-icons/md";
import { FaRulerCombined, FaBed, FaBath, FaHeart, FaRegHeart } from "react-icons/fa";
import { useSelector } from 'react-redux';
import configData from "../../../../../appConfig.json";
import appTheme from "../../../../../app-theme.json";
import { Link } from 'react-router-dom';
import RouteLinks from '../../../../utils/route';

const PropertyCardItem = ({ propertyCardItems, openHeartHandler, fullHeartHandler }) => {

    const [bhkType, setBhkType] = useState("");
    const [apartmentType, setApartmentType] = useState("");
    const { builtUpArea, locality, apartmentName, amenities, floorId, rentalDetails, apartmentTypeId, bhkTypeId, propertyListingId, galleries, isFavourite } = propertyCardItems;
    const newRentaldetail = rentalDetails ? rentalDetails : "";
    const _locality = locality && locality.length > 30 ? `${locality.slice(0, 30)}...` : locality;

    const authentication = useSelector((state) => state.isAuthenticated);
    const showLoader = useSelector((state) => state.isLoader);

    const allLookups = JSON.parse(localStorage.getItem("lookups"));

    useEffect(() => {
        if (allLookups) {
            allLookups.bhkTypes.forEach((items) => {
                if (items.bhkTypeId === bhkTypeId) { setBhkType(items.type); }
            });
            allLookups.apartmentTypes.forEach((items) => {
                if (items.apartmentTypeId === apartmentTypeId) { setApartmentType(items.type); }
            });
        }
    }, []);

    return (
        <Col xl={4} md={6} className="property_col mb-4">
            <div className="property_item">
                <div className="property_image">
                    {galleries.length > 0 ?
                        galleries.map((items, index) => {
                            if (index === 0)
                                return (
                                    <Link className="img_link" target="_blank" to={`${RouteLinks.PropertyDetail}/${propertyListingId}`} key={items.propertyListingGalleryId}>
                                        <Image className="img-fluid" src={`${configData.GALLERY_URL}${items.name}`} />
                                    </Link>
                                )
                        })
                        : <Link className="img_link" target="_blank" to={`${RouteLinks.PropertyDetail}/${propertyListingId}`}>
                            <Image className="img-fluid" src={appTheme.propertySearch.noImage} />
                        </Link>
                    }
                    <div className="sell_badge">for rent</div>
                    <div className="sector_badge">{apartmentType}</div>
                </div>
                <div className="property_titles">
                    <h5 className="property_price">&#8377;{newRentaldetail.expectedRent.toLocaleString()}</h5>
                    <Link className="property_name" target="_blank" to={`${RouteLinks.PropertyDetail}/${propertyListingId}`}>{apartmentName}</Link>
                    <p className="property_locality"><MdLocationOn /> {_locality}</p>
                    {
                        authentication && <>
                            {showLoader ? <div className="interested_loader"><Spinner /></div> :
                                <div className="interested">
                                    {isFavourite ? <FaHeart className="full_heart" onClick={() => fullHeartHandler(propertyListingId)} /> :
                                        <FaRegHeart className="open_heart" onClick={() => openHeartHandler(propertyListingId)} />
                                    }
                                </div>
                            }
                        </>
                    }
                </div>
                <div className="property_amenities">
                    <p className="property_area"><FaRulerCombined />{builtUpArea} Sqft</p>
                    <p className="property_bed"><FaBed />{bhkType}</p>
                    <p className="property_bath"><FaBath />{amenities ? amenities.numberOfBathrooms : ""} Bath</p>
                </div>
            </div>
        </Col>
    )
}

export default PropertyCardItem
