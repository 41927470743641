import { Col, Container, Row } from "react-bootstrap";
import "../../styles/error.scss";
import RoutesLinks from "../../utils/route";
import { Link } from "react-router-dom";
import { BsExclamationTriangle } from "react-icons/bs";
import appTheme from "../../../app-theme.json";

export const NotFound = () => {

    return <>

        <div className="error_box">
            <Container className="error_container">
                <Row className="error_row">
                    <Col lg={6} className="error_col">
                        <div className="error_icon"><BsExclamationTriangle /></div>
                        <h1 className="h1_404">{appTheme.notFoundPage.title}</h1>
                        <h1 className="h1_title">{appTheme.notFoundPage.subTitle}</h1>
                        <p className="p_para">{appTheme.notFoundPage.para}</p>
                        <Link className="back_btn" to={RoutesLinks.Home}>{appTheme.notFoundPage.button}</Link>
                    </Col>
                </Row>
            </Container>
        </div>
    </>
};
