import React, { useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import googleApiKey from "../../../google-api-key";

const AddressMap = ({ addressMap }) => {

    const [libraries] = useState(["places"]);

    const containerStyle = {
        width: addressMap.style.width,
        height: addressMap.style.height
    };

    const center = {
        lat: parseFloat(addressMap.center.latitude),
        lng: parseFloat(addressMap.center.langitude)
    };

    const { isLoaded } = useJsApiLoader({ googleMapsApiKey: googleApiKey.key, libraries });

    const [map, setMap] = useState(/** @type google.maps.Map*/(null));

    if (!isLoaded) {
        return;
    }

    return (

        <GoogleMap center={center} zoom={parseInt(addressMap.zoom)} mapContainerStyle={containerStyle} onLoad={((map) => setMap(map))}>
            <Marker position={center} />
        </GoogleMap>
    )
}

export default AddressMap
