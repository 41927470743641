import React from 'react';
import BeforeLogin from './before-login';
import AfterLogin from "./after-login";
import { useSelector } from 'react-redux';

const Footer = () => {

    const authentication = useSelector((state) => state.isAuthenticated);

    return (
        <>
            {authentication ? <AfterLogin /> : <BeforeLogin />}
        </>
    )
}

export default Footer
