import { Image } from 'react-bootstrap';
import AppSetting from '../../../../../appConfig.json';
import { useEffect } from 'react';
import { FaFileImage, FaVideo, FaFilePdf, FaFileWord } from "react-icons/fa";

export const Conversation = (inputs) => {
    useEffect(() => { }, []);

    return <>{
        inputs.params.chatHistory.map((item, index) => {
            return (
                <div key={index}>
                    <div className={item.isCurrentUser ? "sender-message" : "reciever-message"} >
                        {parseMessage(item.message, item.mediaUrl)}
                    </div>
                </div>
            )
        })
    }
    </>
}

const parseMessage = (message, mediaUrl) => {
    if (message) { return printMedia(message); }
    else if (mediaUrl) { return printMedia(mediaUrl); }
}

const printMedia = (content) => {

    // get file extension
    const extension = content.split('.').pop();
    const isDomainUrl = isDominUrl(content);

    if (AppSetting.ALLOWED_IMAGE_EXTENSION.includes(extension)) {
        if (!isDomainUrl) { content = `${AppSetting.GALLERY_URL}${content}` }
        return <>
            <a href={content} target="_blank" download>
                <Image src={content} className='content-image' />
            </a>
        </>
    } else if (AppSetting.ALLOWED_VIDEO_EXTENSION.includes(extension)) {
        return <>
            <video width="320" height="240" controls className="video_tag">
                <source src={content} type={`video/${extension}`} />
                Your browser does not support the video tag.
            </video>
        </>
    }
    else if (AppSetting.ALLOWED_FILE_EXTENSION.includes(extension)) {
        if (extension === 'pdf') {
            return <>
                <a href={content} target="_blank" download>
                    <FaFilePdf className='doc-pdf' />
                </a >
            </>
        } else if (extension === 'doc') {
            return <>
                <a href={content} target="_blank" download>
                    <FaFileWord className='doc-doc' />
                </a >
            </>
        }
    }
    else { return <> <span className="content">{content}</span></> }
}

const isDominUrl = (content) => { return (content && (content.startsWith("http://") || content.startsWith("https://"))); }