import React from 'react';
import RentalDetails from '../../components/property-listing/RentalDetails';

export const RentalDetailTab = () => {
    return (
        <>
            <RentalDetails />
        </>
    )
}
