import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import LocalityMaps from "../common/LocalityMaps";
import { useFormik } from "formik";
import { LocalityDetails } from "../../helper/tabs/locality-details";
import { LocalityDetailsSchema } from "../../helper/validations/locality-details-schema";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import googleApiKey from "../../../google-api-key"
import { Cities, PropertyType, PropertySector } from "../../services/api/Lookups";
import { toast } from "react-toastify";
import { searchErrroMessage, toastStyle } from "../../utils/http-requests";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import RouteLinks from "../../utils/route";
import appTheme from "../../../app-theme.json";
import { searchCity } from "../../utils/google-maps";
import { PropertyAndLocalityDetail } from "../../services/api/residential-rent";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreator } from "../../state";
import isTokenExpired from "../../utils/jwt-auth";
import { geocode, RequestType, setDefaults } from "react-geocode";


let findCity = "";

const PropertyLocation = () => {
    const [libraries] = useState(['places']);
    const [searchLocation, setSearchLocation] = useState();
    const [cities, setCities] = useState([]);
    const [propertySector, setPropertySector] = useState([]);
    const [propertyType, setPropertyType] = useState([]);
    const [localityText, setLocalityText] = useState();
    const [reloadGeoCode, setReloadGeoCode] = useState(false);

    const navigate = useNavigate();
    const listingId = useParams();

    const dispatch = useDispatch();
    const { redirectModal, isLoader } = bindActionCreators(actionCreator, dispatch);
    const showLoader = useSelector((state) => state.isLoader)

    const [localityData, setLocalityData, propertyListingData] = useOutletContext();

    // const { cityId, landmarkStreet, locality, latitudeAndLongitude } = propertyListingData;
    const { cityId, latitudeAndLongitude, locality, landmarkStreet, propertyListingId, apartmentName, apartmentTypeId, bhkTypeId, floorId, totalFloorId, propertyAgeId, propertyFacingId, builtUpArea } = propertyListingData;


    const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm, setValues } = useFormik({
        initialValues: LocalityDetails,
        validationSchema: LocalityDetailsSchema,
        onSubmit: async (values, action) => {
            // if (values) {
            //     setLocalityData(values);
            //     navigate(`${RouteLinks.PropertyListing}/${listingId.id}${RouteLinks.PropertyDetail}`);
            // }
            if (values) {
                const payload = {
                    propertyListingId: propertyListingId,
                    bhkTypeId: bhkTypeId,
                    latitudeAndLongitude: values.localityAddress ? `${values.localityAddress.latitude},${values.localityAddress.langitude}` : latitudeAndLongitude,
                    locality: values.localityAddress ? values.localityAddress.name : locality,
                    cityId: values.city ? values.city : cityId,
                    landmarkStreet: values.street ? values.street : landmarkStreet,
                    apartmentTypeId: apartmentTypeId,
                    apartmentName: apartmentName,
                    floorId: floorId,
                    totalFloorId: totalFloorId,
                    propertyAgeId: propertyAgeId,
                    propertyFacingId: propertyFacingId,
                    builtUpArea: builtUpArea
                }
                const response = await PropertyAndLocalityDetail(payload, isLoader, isTokenExpired, redirectModal);
                if (response != null) {
                    const { isSuccess, data, errorMessages, successMessage } = response;
                    if (isSuccess) { toast.success(successMessage, toastStyle); navigate(`${RouteLinks.PropertyListing}/${data.propertyListingId}${RouteLinks.PropertyDetail}`); }
                    else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle); }); }
                }
            }
        }
    });

    const nextNavigate = () => {
        navigate(`${RouteLinks.PropertyListing}/${listingId.id}${RouteLinks.PropertyDetail}`);
    }

    const _Cities = async () => {
        const response = await Cities();
        if (response != null) {
            if (response.isSuccess) { setCities(response.data); }
        }
    }

    const _PropertySector = async () => {
        const response = await PropertySector();
        if (response != null) {
            if (response.isSuccess) { setPropertySector(response.data); }
        }
    }

    const _PropertyType = async () => {
        const response = await PropertyType();
        if (response != null) {
            if (response.isSuccess) { setPropertyType(response.data); }
        }
    }

    const { isLoaded } = useJsApiLoader({ googleMapsApiKey: googleApiKey.key, libraries });

    const handleLoad = (ref) => {
        setSearchLocation(ref);
    }

    const handlePlaceChanged = () => {
        const [place] = searchLocation.getPlaces();
        if (place) {
            const filteredCity = place.formatted_address.search(findCity);
            if (filteredCity >= 0) {
                if (!findCity) { setLocalityText(""); toast.warning(searchErrroMessage.cityText, toastStyle); }
                else {
                    setValues({
                        ...values,
                        localityAddress: {
                            name: place.formatted_address,
                            latitude: place.geometry.location.lat(),
                            langitude: place.geometry.location.lng()
                        }
                    });
                    setLocalityText(place.formatted_address);
                }
            }
            else { setLocalityText(""); toast.warning(`${searchErrroMessage.localityText} ${findCity}`, toastStyle); };

        }
    }
    const latLangHandler = (latLang) => {
        setReloadGeoCode(!reloadGeoCode);
        setValues({
            ...values,
            localityAddress: {
                name: "",
                latitude: latLang.latLng.lat(),
                langitude: latLang.latLng.lng()
            }
        });
        setLocalityText("");
    }

    const GetGeoCode = () => {
        setDefaults({ key: "AIzaSyCOdYrG7uYmR7yUcK4EQ4znlnT5IiuSBOQ" });
        geocode(RequestType.LATLNG, `${values.localityAddress.latitude}, ${values.localityAddress.langitude}`)
            .then(({ results }) => {
                const address = results[0].formatted_address;
                const filteredCity = address.search(findCity);
                if (filteredCity >= 0) {
                    if (!findCity) { setLocalityText(""); toast.warning(searchErrroMessage.cityText, toastStyle); }
                    else {
                        setValues({
                            ...values,
                            localityAddress: { ...values.localityAddress, name: address, }
                        });
                        setLocalityText(address);
                    }
                }
                else { setLocalityText(""); toast.warning(`${searchErrroMessage.localityText} ${findCity}`, toastStyle); };
            })
            .catch(console.error);
    }

    useEffect(() => {
        if (values.localityAddress.latitude && values.localityAddress.langitude) { GetGeoCode(); }
    }, [reloadGeoCode]);

    const cityChangeHandler = (e) => {
        setValues({
            ...values,
            city: e.target.value,
            localityAddress: ""
        });
        setLocalityText("");
    }

    useEffect(() => {
        _Cities();
        _PropertySector();
        _PropertyType();
    }, []);

    useEffect(() => { findCity = searchCity(values.city); }, [values.city]);

    useEffect(() => {
        setLocalityText(locality);
        const _latitudeAndLongitude = latitudeAndLongitude ? latitudeAndLongitude.split(",") : "";
        setValues({
            city: cityId,
            street: landmarkStreet,
            propertySector: 1,
            propertyType: 1,
            localityAddress: {
                name: locality,
                latitude: _latitudeAndLongitude[0],
                langitude: _latitudeAndLongitude[1]
            }
        });
    }, [propertyListingData]);

    if (!isLoaded) {
        return;
    }

    let propertyAddContent =
        <>
            <option value="">{appTheme.localityDetails.propertyType}</option>
            {propertyType.map((items) => {
                return (
                    <option key={items.propertyTypeId} value={items.propertyTypeId}>{items.type}</option>
                )
            })}
        </>

    if (values.propertySector === "2") {
        const commercial = propertyType.filter((items) => {
            return items.propertyTypeId <= 2;
        });
        propertyAddContent =
            <>
                <option value="">{appTheme.localityDetails.propertyType}</option>
                {commercial.map((items) => {
                    return (
                        <option key={items.propertyTypeId} value={items.propertyTypeId}>{items.type}</option>
                    )
                })}
            </>
    }

    if (values.propertySector === "3") {
        const landPlot = propertyType.filter((items) => {
            return items.propertyTypeId === 2;
        });
        propertyAddContent =
            <>
                <option value="">{appTheme.localityDetails.propertyType}</option>
                {landPlot.map((items) => {
                    return (
                        <option key={items.propertyTypeId} value={items.propertyTypeId}>{items.type}</option>
                    )
                })}
            </>
    }

    return (
        <>
            <h6 className="tab_title">{appTheme.localityDetails.title}</h6>
            <div className="tab_content">
                <Row className="mt-4">
                    <Col md={5}>
                        <Form.Group>
                            <Form.Label className="form_label">{appTheme.localityDetails.city} *</Form.Label>
                            <Form.Select size="sm" className="form_select" aria-label="Default select example" name="city" value={values.city} onChange={cityChangeHandler} onBlur={handleBlur}>
                                <option value="" hidden>{appTheme.localityDetails.city}</option>
                                {cities.map((items) => {
                                    return (
                                        <option key={items.citiesLookupId} value={items.citiesLookupId}>{items.name}</option>
                                    );
                                })}
                            </Form.Select>
                            {errors.city && touched.city ? (<p className="form-error">{errors.city}</p>) : null}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={5}>
                        <Form.Group>
                            <Form.Label className="form_label">{appTheme.localityDetails.propertySector} *</Form.Label>
                            <Form.Select size="sm" className="form_select" aria-label="Default select example" name="propertySector" value={values.propertySector} onBlur={handleBlur}>
                                <option value="" hidden>{appTheme.localityDetails.propertySector}</option>
                                {propertySector.map((items) => {
                                    return (
                                        <option key={items.propertySectorId} value={items.propertySectorId}>{items.type}</option>
                                    )
                                })}
                            </Form.Select>
                            {errors.propertySector && touched.propertySector ? (<p className="form-error">{errors.propertySector}</p>) : null}
                        </Form.Group>
                    </Col>
                    <Col md={5} className="mt-4 mt-md-0">
                        <Form.Group>
                            <Form.Label className="form_label">{appTheme.localityDetails.propertyType} *</Form.Label>
                            <Form.Select size="sm" className="form_select" aria-label="Default select example" name="propertyType" value={values.propertyType} onBlur={handleBlur}>
                                {propertyAddContent}
                            </Form.Select>
                            {errors.propertyType && touched.propertyType ? (<p className="form-error">{errors.propertyType}</p>) : null}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={5}>
                        <Form.Group>
                            <Form.Label className="form_label">{appTheme.localityDetails.landmarkStreet} *</Form.Label>
                            <Form.Control size="sm" className="form_input" type="text" placeholder="e.g. Evergreen street" name="street" value={values.street} onChange={handleChange} onBlur={handleBlur} />
                            {errors.street && touched.street ? (<p className="form-error">{errors.street}</p>) : null}
                        </Form.Group>
                    </Col>
                    <Col md={5} className="mt-4 mt-md-0">
                        <Form.Group>
                            <Form.Label className="form_label">{appTheme.localityDetails.locality} *</Form.Label>
                            <StandaloneSearchBox className="form_input" onLoad={handleLoad} onPlacesChanged={handlePlaceChanged}>
                                <Form.Control size="sm" type="text" className="form_input" placeholder="Enter location/society name" name="localityAddress" value={localityText} onChange={(e) => setLocalityText(e.target.value)} />
                            </StandaloneSearchBox>
                            {errors.localityAddress && touched.localityAddress ? (<p className="form-error">{errors.localityAddress}</p>) : null}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={12}>
                        <LocalityMaps values={values} latLangHandler={latLangHandler} />
                    </Col>
                </Row>
                <div className="next_btn">
                    <Button size="sm" variant="primary" className="ms-auto mt-4 save" onClick={handleSubmit}>
                        {showLoader ? <Spinner animation="border" className='spinner_sm' /> : <>{appTheme.localityDetails.submitButton}</>}
                    </Button>
                    <Button size="sm" variant="primary" className="ms-auto mt-4 next" onClick={nextNavigate}>{appTheme.localityDetails.nextButton}</Button>
                </div>
            </div>
        </>
    );
};

export default PropertyLocation;
