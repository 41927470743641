import React from 'react';
import { Container } from 'react-bootstrap';
import appTheme from "../../../../../app-theme.json";

const PrivacyPolicySection = () => {

    const { mainTitle, mainSubTitile, privacyPolicyData } = appTheme.privacyPolicyPage.privacyPolicySection;

    return (
        <div className="privacy_policy section_padding">
            <Container>
                <div className="main_title">
                    <h1 className="title">{mainTitle}</h1>
                    <p className="subtitle">{mainSubTitile}</p>
                </div>
                <div className="privacy_policy_box">
                    {
                        privacyPolicyData.map((items) => {
                            return (
                                <div className="privacy_policy_item" key={items.PPDId}>
                                    <h3 className="privacy_title">{items.PPTitle}</h3>
                                    {
                                        items.PPParagraphData.map((item) => {
                                            return (
                                                <p className="privacy_para" key={item.PPPDId}>{item.paragraph}</p>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </Container>
        </div>
    )
}

export default PrivacyPolicySection
