import React, { useEffect, useState } from 'react';
import "../../../styles/profile/favourite.scss";
import { Row, Container } from 'react-bootstrap';
import { Add, ClientInterestedListing, Remove } from '../../../services/api/client-interested-listing';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../state';
import isTokenExpired from '../../../utils/jwt-auth';
import PropertyFavouriteGrid from './components/property-favourite-grid';
import { toast } from 'react-toastify';
import { toastStyle } from '../../../utils/http-requests';
import PropertyCardItem from './components/property-card-item';


export const Favourite = () => {

    const [favouriteList, setFavouriteList] = useState([]);
    const [reloadFavorite, setReloadFavorite] = useState(false);

    const dispatch = useDispatch();
    const { isLoading, isLoader, redirectModal } = bindActionCreators(actionCreator, dispatch);

    const _ClientInterestedListing = async () => {
        isLoading(true);
        const response = await ClientInterestedListing(isTokenExpired, redirectModal);
        if (response != null) {
            if (response.isSuccess) { setFavouriteList(response.data); isLoading(false); }
        } isLoading(false);
    }

    useEffect(() => {
        _ClientInterestedListing();
    }, [reloadFavorite]);

    const fullHeartHandler = async (payload) => {
        isLoader(true);
        const response = await Remove({ propertyListingId: payload }, isTokenExpired, redirectModal);
        if (response != null) {
            if (response.isSuccess) { setReloadFavorite(!reloadFavorite); isLoader(false); toast.success(response.successMessage, toastStyle); }
            else { response.errorMessages.forEach((error) => { isLoader(false); toast.error(error.message, toastStyle); }); }
        } else isLoader(false);
    }

    return (
        <>
            <div className="favourite">
                <Container fluid>
                    <Row>
                        {
                            favouriteList.map((items) => {
                                return (
                                    // <PropertyFavouriteGrid
                                    //     propertyFavouriteItem={items}
                                    //     removePropertyHandler={removePropertyHandler}
                                    //     key={items.propertyListingId}
                                    // />
                                    <PropertyCardItem
                                        propertyCardItems={items}
                                        fullHeartHandler={fullHeartHandler}
                                        key={items.propertyListingId}
                                    />
                                )
                            })

                        }
                    </Row>
                </Container>
            </div>
        </>
    )
}
