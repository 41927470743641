import React, { useEffect, useRef, useState } from 'react';
import { FaArrowUp } from "react-icons/fa";
import "../../styles/common/bottom-top-button.scss";

const BottomTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);
    const prevScrollPos = useRef(0);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        const toggleVisibility = () => {
            const currentScrollPos = window.pageYOffset;

            if (currentScrollPos > 400 && currentScrollPos > prevScrollPos.current) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }

            prevScrollPos.current = currentScrollPos;
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, [isVisible]);

    return (
        <div className={`top_arrow_box ${isVisible && "trans_class"}`}>
            {isVisible && <div className="top_arrow" onClick={scrollToTop}>
                <FaArrowUp />
            </div>}
        </div>
    )
}

export default BottomTopButton;
