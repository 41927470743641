export const searchFilter = {
    minPrice: 0,
    maxPrice: 15000,
    minBuiltUpArea: 0,
    maxBuiltUpArea: 2000,
    localityAddress: {
        name: "",
        latitude: "",
        langitude: ""
    },
    citiesTypes: "",
    bhkTypes: [],
    apartmentTypes: [],
    preferredTenants: [],
    furnishingTypes: [],
    parkingTypes: [],
    propertyAges: [],

}