import React, { useRef } from 'react';
import { FaPaperPlane, FaPaperclip } from "react-icons/fa";

export const SendMessage = (inputs) => {

    const hiddenFileInput = useRef(null);
    const chatInput = useRef(null);

    const openFileDialog = event => { hiddenFileInput.current.click(); };

    const handleChange = async (event) => {

        const fileList = event.target.files;

        if (fileList && fileList.length > 0) {
            let formData = new FormData();
            for (let file of fileList) { formData.append('Files', file); }

            formData.append('ConversationRoomId', inputs.params.activeConversationRoomId);
            formData.append('Message', null);
            formData.append('NewUserId', inputs.params.userInformation.clientId);

            const response = await inputs.params.sendChat(formData, true, inputs.params.activeConversationRoomId);
            if (response != null) { inputs.params.setChatHistory(response.data); }
        }
    };

    const sendChat = async (event) => {
        event.preventDefault();
        var message = chatInput.current.value;
        if (message !== "") {
            var payload = {
                conversationRoomId: inputs.params.activeConversationRoomId,
                message: message,
                newUserId: inputs.params.userInformation.clientId
            };
            chatInput.current.value = null;
            const response = await inputs.params.sendChat(payload, false, inputs.params.activeConversationRoomId);
            if (response != null) { inputs.params.setChatHistory(response.data); }
        }
    }

    return <>
        <form className="message-input" onSubmit={sendChat}>
            <button className="attachment transition-speed fa-solid fa-paperclip" type="button" onClick={openFileDialog} >
                <FaPaperclip />
            </button>
            <input
                type="file"
                onChange={handleChange}
                multiple
                accept='application/pdf, application/doc, image/png, image/gif, image/jpeg, image/jpg,image/bmp'
                ref={hiddenFileInput}
                style={{ display: 'none' }}
            />
            <input className="border rounded" type="text" name="" id="" placeholder="Type a message" ref={chatInput} />
            <span className="message-send-btn">
                <input className="send-btn" type="submit" name="sendMessage" value="submit" />
                <FaPaperPlane className="message-send-btn-icon fa-solid fa-paper-plane" roll="sendMessage"></FaPaperPlane>
            </span>
        </form>
    </>
}