import React from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import '../../styles/footer.scss';
import RouteLinks from '../../utils/route';
import { FaFacebookF, FaInstagram, FaYoutube, FaWhatsapp, FaLinkedinIn } from "react-icons/fa";
import appTheme from "../../../app-theme.json";
import { useDispatch } from "react-redux";
import { actionCreator } from "../../state/index";
import { bindActionCreators } from "redux";

const BeforeLogin = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { recentPath } = bindActionCreators(actionCreator, dispatch);

    const navigateHandler = (to) => {

        switch (to) {
            case RouteLinks.AboutUs:
                navigate(RouteLinks.AboutUs);
                break;
            case RouteLinks.TermAndConditions:
                navigate(RouteLinks.TermAndConditions);
                break;
            case RouteLinks.PrivacyPolicy:
                navigate(RouteLinks.PrivacyPolicy);
                break;
            case RouteLinks.FAQs:
                navigate(RouteLinks.FAQs);
                break;
            case RouteLinks.Contact:
                navigate(RouteLinks.Contact);
                break;
            case RouteLinks.Career:
                navigate(RouteLinks.Career);
                break;
            case RouteLinks.ReferAndEarn:
                navigate(RouteLinks.ReferAndEarn);
                break;
            case RouteLinks.Blog:
                navigate(RouteLinks.Blog);
                break;
            default:
                recentPath(to);
                navigate(RouteLinks.Login);
                break;
        }

    }

    const { footerBeforeLogin, copyRights, company } = appTheme.footer;

    return <>

        <footer >
            <Container>
                <Row>
                    {
                        footerBeforeLogin.map((item) => {
                            return (
                                <Col md={3} className='mb-3 mb-md-0' key={item.id}>
                                    <ul className='link_box'>
                                        <li>
                                            <h4 className="link_title">{item.title}</h4>
                                            {item.footerLinks.map((items) => {
                                                return (
                                                    <p className="link_text" key={items.id} onClick={() => navigateHandler(items.path)}>{items.title}</p>
                                                )
                                            })}
                                        </li>
                                    </ul>
                                </Col>
                            )
                        })
                    }
                </Row>
                <hr className='border-white' />
                <Row>
                    <Col sm={6} className='mb-3 mb-sm-0'>
                        <div className="copyright">
                            <p>{copyRights}{new Date().getFullYear()} , <Link to={RouteLinks.Home}>{company}</Link></p>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="social_link">
                            <Link className='social_btn'><FaInstagram className='insta' /></Link>
                            <Link className='social_btn'>< FaWhatsapp className='whatapp' /></Link>
                            <Link className='social_btn'>< FaFacebookF className='fb' /></Link>
                            <Link className='social_btn'>< FaLinkedinIn /></Link>
                            <Link className='social_btn'>< FaYoutube /></Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    </>

};

export default BeforeLogin;