
export const toastStyle = { theme: 'colored', position: 'top-right', autoClose: 2000, };
export const tokenExpire = { error: "Your session has expired, please login" }
export const searchErrroMessage = {
    cityText: "please select city first",
    localityText: "please select locality in"
}

export const httpHeaders = {
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
    }
}

export const httpHeadersWithToken = () => {
    return {
        'headers': {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        }
    }
}

export const config = () => {
    return {
        'headers': {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'content-type': 'multipart/form-data',
        }
    }
};