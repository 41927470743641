import React from 'react';
import RentFilter from '../property-filter/rent-filter';
import appTheme from "../../../app-theme.json";

const HomeBanner = () => {

    return (
        <div className="home-banner">
            <div className="banner-container">
                <div className="banner-inner-content">
                    <h1 className="banner-heading">{appTheme.heroSectionTitle}</h1>
                    <div className="banner-property-filter">
                        <RentFilter />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeBanner;
