import React from 'react';
import "../../../styles/mobile/bottom-menu.scss";
import { NavLink, useNavigate } from 'react-router-dom';
import { FaBars, FaUserAlt, FaHome, FaCommentDots, FaListUl } from "react-icons/fa";
import RouteLinks from '../../../utils/route';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../state';
import { useState } from 'react';
import { useEffect } from 'react';
import listingPanel from '../../../utils/bottom-nav';
import appTheme from "../../../../app-theme.json";

const BottomNav = ({ pathName }) => {

    const [showListingPanel, setShowListingPanel] = useState(false);
    const [showOwner, setShowOwner] = useState(false);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { showProfileSidebar, recentPath, showPropertyPanel, toggleMobileVarificationModal, showOwnerDetail } = bindActionCreators(actionCreator, dispatch);

    const toggleSidebar = useSelector((state) => state.showProfileSidebar);
    const togglePanel = useSelector((state) => state.showPropertyPanel);
    const authentication = useSelector((state) => state.isAuthenticated);
    const toggleOwner = useSelector((state) => state.showOwnerDetail)

    useEffect(() => {
        if (pathName) {
            const path = pathName.split("/");
            path.forEach((item) => {
                const showPanel = listingPanel(item);
                setShowListingPanel(showPanel);
                if (RouteLinks.PropertyDetail === `/${item}`)
                    setShowOwner(true);
            });
        }
        return () => setShowOwner(false);
    }, [pathName]);

    const chatNavigateHandler = () => {
        recentPath(RouteLinks.Chats);
        navigate(RouteLinks.Login);
    }

    const listNavigateHandler = () => {
        recentPath(RouteLinks.PropertyListing);
        navigate(RouteLinks.Login);
    }

    const profileNavigateHandler = () => {
        recentPath(RouteLinks.Profile);
        navigate(RouteLinks.Login);
    }

    const togglPanelClass = togglePanel ? "active_panel" : "";
    const togglSidebarClass = toggleSidebar ? "active_panel" : "";


    return (
        <>
            {
                authentication ?
                    <div className="bottom_menu">
                        <NavLink className="bottom_link" to={appTheme.home.path}><FaHome />{appTheme.home.title}</NavLink>
                        {showOwner ? <p className="bottom_link" onClick={() => showOwnerDetail(!toggleOwner)}><FaListUl />Owner Detail</p> :
                            <NavLink className="bottom_link" to={appTheme.chats.path}><FaCommentDots />{appTheme.chats.title}</NavLink>
                        }
                        {showListingPanel ? <p className={`bottom_link ${togglPanelClass}`} onClick={() => showPropertyPanel(!togglePanel)}><FaBars />{appTheme.listingPanel.title}</p>
                            : <NavLink className="bottom_link" to={appTheme.myListing.path}><FaBars />{appTheme.myListing.title}</NavLink>
                        }
                        {authentication ? <p className={`bottom_link ${togglSidebarClass}`} onClick={() => showProfileSidebar(!toggleSidebar)}><FaUserAlt />{appTheme.myProfile.title}</p>
                            : <p className={`bottom_link ${togglSidebarClass}`} onClick={() => toggleMobileVarificationModal(true)}><FaUserAlt />{appTheme.myProfile.title}</p>
                        }

                    </div>
                    : <div className="bottom_menu">
                        <NavLink className="bottom_link" to={appTheme.home.path}><FaHome />{appTheme.home.title}</NavLink>
                        {showOwner ? <p className="bottom_link" onClick={() => showOwnerDetail(!toggleOwner)}><FaListUl />Owner Detail</p> :
                            <p className="bottom_link" onClick={chatNavigateHandler}><FaCommentDots />{appTheme.chats.title}</p>
                        }
                        {showListingPanel ? <p className={`bottom_link ${togglPanelClass}`} onClick={() => showPropertyPanel(!togglePanel)}><FaBars />{appTheme.listingPanel.title}</p>
                            : <p className="bottom_link" onClick={listNavigateHandler}><FaBars />{appTheme.myListing.title}</p>
                        }

                        <p className={`bottom_link ${togglSidebarClass}`} onClick={profileNavigateHandler}><FaUserAlt />{appTheme.myProfile.title}</p>

                    </div>}
        </>
    )
}

export default BottomNav
