import React from "react";
import "../../../styles/others/privacy-policy.scss"
import appTheme from "../../../../app-theme.json"
import Banner from "../../../components/common/banner";
import PrivacyPolicySection from "./components/privacy-policy-sec";
import BottomTopButton from "../../../components/common/bottom-top-button";

export const PrivacyPolicy = () => {

    const addPPComponent = (PPCItem) => {
        switch (PPCItem.componentName) {
            case "Banner":
                return { ...PPCItem, component: <Banner bannerContent={appTheme.privacyPolicyPage.privacyPolicyBanner} /> }
            case "PrivacyPolicySection":
                return { ...PPCItem, component: <PrivacyPolicySection /> }
            case "BottomTopButton":
                return { ...PPCItem, component: <BottomTopButton /> }

            default:
                break;
        }
    }

    let privacyPolicyComponents = appTheme.privacyPolicyPage.privacyPolicyComponents.map((PPCItem) => addPPComponent(PPCItem));
    privacyPolicyComponents.sort((a, b) => a.position - b.position);

    return <>
        {
            privacyPolicyComponents.map((item) => {
                if (item && item.isPublished)
                    return (
                        <section key={item.position}>{item.component}</section>
                    )
            })
        }
    </>
};
