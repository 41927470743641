import { Nav, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import RouteLinks from '../../../utils/route';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Detail } from '../../../services/api/client';
import isTokenExpired from "../../../utils/jwt-auth";
import { FaBell } from "react-icons/fa";
import UserDropdown from '../../common/user-dropdown';
import appTheme from "../../../../app-theme.json";
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../state';

const AppNavAfterLogin = ({ showNotifiationHandler, notificationList }) => {

    const [clientDetail, setClientDetail] = useState({});

    const dispatch = useDispatch();
    const { clientLoader, redirectModal } = bindActionCreators(actionCreator, dispatch);
    const profileImage = useSelector((state) => state.showProfileImage);
    const showLoader = useSelector((state) => state.clientLoader);

    const getDetail = async () => {
        clientLoader(true);
        const response = await Detail(isTokenExpired, redirectModal);
        if (response != null) { setClientDetail(response.data); clientLoader(false); }
        clientLoader(false);
    }

    useEffect(() => { getDetail(); }, [profileImage]);
    // useEffect(() => { }, [notificationList]);

    return (
        <>
            <Nav.Link className="post_btn" as={Link} to={`${RouteLinks.AddProperty}${RouteLinks.PropertyLocality}`}>{appTheme.postProperty.title}</Nav.Link>
            <div className="after_login_dropdown">
                {showLoader ? <div className="user_spinner"><Spinner /></div> : <UserDropdown clientDetail={clientDetail} />}
            </div>
            {
                notificationList.length > 0 ?
                    <Nav.Link className='px-0 bell_icon' onClick={showNotifiationHandler}>
                        <FaBell />
                        <span className="notification">{notificationList.length}</span>
                    </Nav.Link>
                    : <Nav.Link className='px-0 no_notify'><FaBell /></Nav.Link>
            }
        </>
    );
};

export default AppNavAfterLogin;