export const SiteLogoSvg = (props) => {
    return <>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 422.000000 80.000000" preserveAspectRatio="xMidYMid meet" style={props.style}>
            <g transform="translate(0.000000,80.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M335 705 c-14 -13 -25 -31 -25 -40 0 -21 44 -65 65 -65 21 0 65 44 65 65 0 21 -44 65 -65 65 -9 0 -27 -11 -40 -25z" />
                <path d="M815 705 c-14 -13 -25 -31 -25 -40 0 -9 11 -27 25 -40 13 -14 31 -25 40 -25 9 0 27 11 40 25 14 13 25 31 25 40 0 9 -11 27 -25 40 -13 14 -31 25 -40 25 -9 0 -27 -11 -40 -25z" />
                <path d="M2675 705 c-14 -13 -25 -31 -25 -40 0 -21 44 -65 65 -65 21 0 65 44 65 65 0 21 -44 65 -65 65 -9 0 -27 -11 -40 -25z" />
                <path d="M72 511 c3 -173 5 -192 24 -217 33 -45 66 -64 122 -71 l52 -6 0 71 c0 70 0 71 -27 74 l-28 3 -3 168 -2 167 -71 0 -71 0 4 -189z" />
                <path d="M1820 460 l0 -240 70 0 70 0 0 90 c0 83 1 90 20 90 18 0 20 7 20 65 0 58 -2 65 -20 65 -18 0 -20 7 -20 85 l0 85 -70 0 -70 0 0 -240z" />
                <path d="M2030 460 l0 -240 65 0 65 0 0 240 0 240 -65 0 -65 0 0 -240z" />
                <path d="M3280 460 l0 -240 65 0 65 0 0 111 c0 109 0 110 23 107 21 -3 22 -8 25 -110 l3 -108 59 0 60 0 0 139 c0 135 -1 140 -26 170 -29 34 -76 47 -117 32 l-27 -11 0 75 0 75 -65 0 -65 0 0 -240z" />
                <path d="M3650 531 c0 -96 5 -182 11 -198 15 -40 48 -73 94 -94 69 -32 75 -28 75 45 0 56 -3 65 -21 71 -18 6 -39 41 -39 66 0 5 14 9 30 9 l30 0 0 70 0 70 -30 0 -30 0 0 65 0 65 -60 0 -60 0 0 -169z" />
                <path d="M2352 566 c-174 -62 -149 -312 34 -342 42 -6 119 9 129 27 4 5 -8 26 -27 47 -27 30 -39 36 -65 34 -37 -4 -63 26 -63 73 0 59 54 97 88 63 21 -21 13 -37 -20 -40 -28 -2 -33 -7 -33 -28 0 -25 1 -25 98 -28 l97 -3 0 33 c0 40 -30 106 -58 131 -25 23 -88 46 -122 46 -14 0 -40 -6 -58 -13z" />
                <path d="M310 395 l0 -175 65 0 65 0 0 175 0 175 -65 0 -65 0 0 -175z" />
                <path d="M463 563 c2 -5 37 -83 77 -175 41 -93 77 -165 81 -160 4 4 41 82 82 175 l76 167 -59 0 -58 0 -19 -45 c-10 -25 -21 -45 -24 -45 -4 0 -14 20 -23 45 l-18 45 -59 0 c-33 0 -58 -3 -56 -7z" />
                <path d="M790 395 l0 -175 65 0 65 0 0 175 0 175 -65 0 -65 0 0 -175z" />
                <path d="M1106 560 c-16 -5 -40 -19 -54 -32 -42 -39 -52 -78 -52 -198 l0 -110 65 0 65 0 0 110 0 110 25 0 25 0 0 -110 0 -110 66 0 66 0 -3 124 c-4 134 -12 155 -70 198 -29 21 -94 30 -133 18z" />
                <path d="M1493 559 c-75 -22 -133 -116 -119 -192 15 -82 93 -145 161 -133 l35 7 0 59 c0 56 -1 59 -26 62 -59 7 -56 70 3 76 47 5 55 -15 51 -122 -4 -115 -17 -136 -85 -136 l-43 0 0 -54 0 -54 39 -7 c85 -14 177 42 201 123 7 22 10 87 8 159 -3 105 -6 126 -24 150 -42 56 -131 84 -201 62z" />
                <path d="M2650 395 l0 -175 65 0 65 0 0 175 0 175 -65 0 -65 0 0 -175z" />
                <path d="M2963 559 c-75 -22 -133 -116 -119 -192 15 -82 93 -145 161 -133 l35 7 0 59 c0 56 -1 59 -26 62 -59 7 -56 70 3 76 47 5 55 -15 51 -122 -4 -115 -17 -136 -85 -136 l-43 0 0 -54 0 -54 39 -7 c85 -14 177 42 201 123 7 22 10 87 8 159 -3 105 -6 126 -24 150 -42 56 -131 84 -201 62z" />
                <path d="M4024 559 c-50 -14 -76 -51 -86 -120 -9 -68 -21 -89 -49 -89 -17 0 -19 -8 -19 -70 0 -78 0 -78 74 -61 68 16 104 64 121 165 4 26 12 40 26 43 16 5 19 15 19 74 0 69 0 69 -27 68 -16 0 -42 -5 -59 -10z" />
            </g>
        </svg>
    </>
}

export const FaceBookIconSvg = (prop) => {
    return <>
        <svg style={prop.prop} viewBox="0 0 200 200" aria-hidden="true" fill="currentColor" preserveAspectRatio="xMinYMin meet" focusable="false">
            <title>Facebook</title>
            <path d="M200 100a100 100 0 1 0-115.6 98.8v-69.9H59V100h25.4V78c0-25 14.9-39 37.7-39 11 0 22.4 2 22.4 2v24.6H132c-12.4 0-16.3 7.7-16.3 15.6V100h27.8l-4.5 29h-23.3v69.8A100 100 0 0 0 200 100" stroke="none"></path>
        </svg>
    </>
}

export const GoogleIconSvg = (prop) => {
    return <>
        <svg style={prop.prop} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className="LgbsSe-Bz112c">
            <g>
                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
            </g>
        </svg>
    </>
}