import { combineReducers } from "redux";
import showLoader from "./app-loader";
import showForgotPasswordModal from "./forgot-password-modal-reducer";
import showLoginModal from "./login-modal-reducer";
import showSignupModal from "./signup-modal-reducer";
import showSaveAndDraft from './SaveAndDraftReducer';
import showSaveAndPost from './SaveAndPostReducer';
import showSaveAndPostLater from './SaveAndPostLaterReducer';
import showDiscardModal from './DiscardModalReducer';
import isAuthenticated from './is-authenticated-reducer';
import loginSignup from "./mobile-varification-reducer";
import confirmProfileModal from './confirm-profile-reducer';
import redirectModal from "./redirect-modal-reducer";
import showProfileImage from './profile-image-reducer';
import showAvatarModal from "./avatar-modal-reducer";
import showPersonalInfoModal from "./profile-info-reducer";
import hideFooter from "./hide-footer-reducer";
import isLoading from "./is-loading-reducer";
import isLoader from "./is-loader-reducer";
import showAddressModal from "./address-modal-reducer";
import deleteImage from "./delete-image-reducer";
import changeImage from "./change-image-reducer";
import changeDocument from "./change-document-reducer";
import showProfileSidebar from "./show-profile-sidebar-reducer";
import showPropertyPanel from "./show-property-panel-reducer";
import showOwnerDetail from "./owner-reducer";
import confirmAndStatus from "./confirm-modal-reducer";
import clientLoader from "./client-loader-reducer";
import favouritePropertyLoader from "./favourite-loader-reducer";
import recentPath from "./recent-path-reducer";
import isPropertyLoading from "./is-property-loding-reducer";
import ownerDetailModal from "./owner-detail-modal-reducer";
import uploadPhotosModal from "./upload-photos-reducer";

const reducers = combineReducers({
  showLoader,
  showForgotPasswordModal,
  showLoginModal,
  showSignupModal,
  showSaveAndDraft,
  showSaveAndPost,
  showSaveAndPostLater,
  showDiscardModal,
  isAuthenticated,
  loginSignup,
  confirmProfileModal,
  redirectModal,
  showProfileImage,
  showAvatarModal,
  showPersonalInfoModal,
  showAddressModal,
  hideFooter,
  isLoading,
  isLoader,
  isPropertyLoading,
  clientLoader,
  deleteImage,
  changeImage,
  changeDocument,
  showProfileSidebar,
  showPropertyPanel,
  showOwnerDetail,
  confirmAndStatus,
  favouritePropertyLoader,
  recentPath,
  ownerDetailModal,
  uploadPhotosModal

});

export default reducers;
