import React from 'react';
import { Col, Container, Image, NavLink, Row } from 'react-bootstrap';
import appTheme from "../../../../../../app-theme.json";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import RouteLinks from "../../../../../utils/route";

const BlogSection = () => {

    const { mainTitle, mainSubTitile, blogs } = appTheme.blogPage.blogSection;

    return (
        <div className="blog_section section_padding">
            <Container>
                <div className="main_title">
                    <h1 className="title">{mainTitle}</h1>
                    <p className="subtitle">{mainSubTitile}</p>
                </div>
                <div className="blogs">
                    <Row>
                        {blogs.map((items) => {
                            return (
                                <Col lg={4} className="mb-4 lg-mb-0" key={items.id}>
                                    <div className="blog_box">
                                        <div className="blog_image">
                                            <Link className="blog_link" to={RouteLinks.BlogDetail}>
                                                <Image src={items.image} />
                                            </Link>
                                        </div>
                                        <div className="blog_contents">
                                            <h4 className="blog_title"><Link className="link_title" to={RouteLinks.BlogDetail}>{items.title}</Link></h4>
                                            <p className="blog_date">{items.date}</p>
                                            <p className="blog_para">{items.paragraph}</p>
                                            <Link className="blog_read_more" to={RouteLinks.BlogDetail}>{items.button}</Link>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
                <ul className="page_pagination">
                    <li className="page_item"><NavLink className="page_link"><FaAngleLeft /></NavLink></li>
                    <li className="page_item"><NavLink className="page_link">01</NavLink></li>
                    <li className="page_item"><NavLink className="page_link">02</NavLink></li>
                    <li className="page_item"><NavLink className="page_link">03</NavLink></li>
                    <li className="page_item"><NavLink className="page_link"><FaAngleRight /></NavLink></li>
                </ul>
            </Container>
        </div>
    )
}

export default BlogSection
