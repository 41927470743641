import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Image, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../../state';
import { Update } from '../../../../services/api/client';
import { toast } from 'react-toastify';
import { toastStyle } from '../../../../utils/http-requests';
import { useFormik } from 'formik';
import { profileInitialValue } from '../../../../helper/profile';
import { profileSchema } from '../../../../helper/validations/profile-schema';
import isTokenExpired from '../../../../utils/jwt-auth';
import appTheme from "../../../../../app-theme.json";

const UpdateInformationModal = (props) => {

    const dispatch = useDispatch();
    const { showPersonalInfoModal, showProfileImage, isLoader, redirectModal } = bindActionCreators(actionCreator, dispatch);
    const showPerofileInfoModal = useSelector((state) => state.showPersonalInfoModal);
    const profileImage = useSelector((state) => state.showProfileImage);
    const showLoader = useSelector((state) => state.isLoader);


    const { clientId, firstName, lastName, email, contacts, profilePicture } = props.clientDetail;
    let contact = [];
    if (contacts) { contacts.forEach(element => { contact.push(element); }); }

    const closeInfromationModal = () => { showPersonalInfoModal(false); }

    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setValues, resetForm } = useFormik({
        initialValues: profileInitialValue,
        validationSchema: profileSchema,
        onSubmit: async (values) => {
            const response = await Update(values, isLoader, isTokenExpired, redirectModal);
            if (response != null) {
                const { isSuccess, successMessage, errorMessages } = response;
                if (isSuccess) { toast.success(successMessage, toastStyle); showProfileImage(!profileImage); }
                else { errorMessages.forEach((err) => { toast.error(err.message, toastStyle) }); }
            }
            closeInfromationModal();
        }
    });

    useEffect(() => {
        setValues({ ...values, clientId, firstName, lastName, email, profilePicture, contacts: [...contact] })
    }, [props]);

    return (
        <Modal show={showPerofileInfoModal} onHide={closeInfromationModal} centered>
            <Modal.Header closeButton>
                <h4 className='title'>{appTheme.profilePage.personalInfo.modalTitle}</h4>
            </Modal.Header>
            <Modal.Body>
                <Form className='fs--1'>
                    <Form.Group className="mb-3" >
                        <Form.Label>{appTheme.profilePage.personalInfo.firstName}</Form.Label>
                        <Form.Control size='sm' type="text" placeholder="Enter First Name" name='firstName' value={values.firstName} onChange={handleChange} onBlur={handleBlur} />
                        {errors.firstName && touched.firstName ? (<p className="form-error">{errors.firstName}</p>) : null}
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>{appTheme.profilePage.personalInfo.lastName}</Form.Label>
                        <Form.Control size='sm' type="text" placeholder="Enter Last Name" name='lastName' value={values.lastName} onChange={handleChange} onBlur={handleBlur} />
                        {errors.lastName && touched.lastName ? (<p className="form-error">{errors.lastName}</p>) : null}
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>{appTheme.profilePage.personalInfo.email}</Form.Label>
                        <div className="position-relative">
                            <Form.Control size='sm' type="email" placeholder="Enter Email" name='email' value={values.email} onChange={handleChange} onBlur={handleBlur} />
                            {errors.email && touched.email ? (<p className="form-error">{errors.email}</p>) : null}
                        </div>
                    </Form.Group>
                    <div className="btn_box">
                        <Button className="cancel_btn" onClick={closeInfromationModal}>Cancel</Button>
                        <Button size='sm' className='submit_btn' onClick={handleSubmit} disabled={showLoader}>
                            {showLoader ? <Spinner animation="border" className='spinner_sm' /> : <>{appTheme.profilePage.personalInfo.submitButton}</>}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default UpdateInformationModal
