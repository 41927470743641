import React from 'react';
import "../../../styles/profile/tenent-list.scss";
import { Button, Image } from 'react-bootstrap';
import appTheme from "../../../../app-theme.json";

const TenentList = ({ item, isActive, setActiveList, setShowDetails }) => {

    const activeHandler = (id) => {
        setActiveList(id)
        setShowDetails(true);
    }


    return (
        <li className={`tenant_detail ${isActive && "active_list"}`} onClick={() => activeHandler(item.id)}>
            <div className="tenant_name_img" >
                <span className="tenant_img">
                    <Image src={item.img} />
                </span>
                <span className="tenant_name_location">
                    <span className="tenant_name">{item.name}</span>
                    <span className="tenant_location">{item.address}</span>
                </span>
            </div>
            <div className="date">{item.date}</div>
            <div><span className={`${item.active ? item.active : "status"}`}>{item.status}</span></div>
            <div className="rent">{item.rent}</div>
            <div><Button className='view_btn'>{appTheme.myTenantPage.button}</Button></div>
        </li>
    )
}

export default TenentList
