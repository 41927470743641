import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreator } from '../../../state';
import { bindActionCreators } from 'redux';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import RouteLinks from '../../../utils/route';
import appTheme from "../../../../app-theme.json";

const RedirectModal = () => {

    const [counter, setCounter] = useState(10);
    const homeNavigate = useNavigate();

    const dispatch = useDispatch();
    const { redirectModal, isAuthenticated } = bindActionCreators(actionCreator, dispatch);
    const showModal = useSelector((state) => state.redirectModal);

    const onModalHide = () => { redirectModal(false); setCounter(10); isAuthenticated(false); homeNavigate(RouteLinks.Home); };

    const loginHandler = () => {
        onModalHide();
        homeNavigate(RouteLinks.Login);
    }

    useEffect(() => {
        if (showModal) {
            const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
            if (counter === 0) loginHandler()
            return () => clearInterval(timer);
        }
    }, [counter, showModal]);

    return (
        <>
            <Modal show={showModal} onHide={onModalHide}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className='fs--1'>
                    <p className='m-0'>{appTheme.redirectModal.title1} <br /> {appTheme.redirectModal.title2} <b>({counter})</b></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='submit_btn' size='sm' onClick={loginHandler}>{appTheme.redirectModal.submitButton}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default RedirectModal;
