import React, { useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import googleApiKey from "../../../google-api-key";
import "../../styles/common/locality-maps.scss";


const PropertyDetailMap = ({ latLng }) => {
    const [libraries] = useState(["places"]);

    const containerStyle = {
        width: '100%',
        height: '400px'
    };
    const center = {
        lat: latLng ? parseFloat(latLng[0]) : "",
        lng: latLng ? parseFloat(latLng[1]) : ""
    }

    const { isLoaded } = useJsApiLoader({ googleMapsApiKey: googleApiKey.key, libraries });

    const [map, setMap] = useState(/** @type google.maps.Map*/(null))

    if (!isLoaded) {
        return;
    }

    return (
        <>
            <div className="map_box">
                <GoogleMap center={center} zoom={15} mapContainerStyle={containerStyle} onLoad={((map) => setMap(map))}>
                    <Marker position={center} />
                </GoogleMap>

            </div>
        </>
    );
};

export default PropertyDetailMap;
