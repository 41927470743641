import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { actionCreator } from '../../../state';
import { GetChat, GetChatUsers, Send, SendMedia } from '../../../services/api/chat';
import AppSetting from '../../../../appConfig.json';
import "../../../styles/profile/chats.scss";
import { SearchUser, UserList, ChatProfile, Conversation, SendMessage } from './components';
import isTokenExpired from '../../../utils/jwt-auth';
import { MarkAllRead } from '../../../services/api/notification';

export const Chats = () => {
    // Get userId from the query string...
    const params = useParams()
    const _userId = params.id;

    const dispatch = useDispatch();
    const { isLoading } = bindActionCreators(actionCreator, dispatch);

    const [userList, setUserList] = useState([]);
    const [chatHistory, setChatHistory] = useState([]);
    const [activeConversationRoomId, setActiveConversationRoomId] = useState(null);
    const [userInformation, setUserInformation] = useState({});
    const [showMessage, setShowMessage] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);

    const _defaultProfilePicture = AppSetting.DEFAULT_PROFILE_PICTURE;
    const _emptyGuid = '00000000-0000-0000-0000-000000000000';

    useEffect(() => { loadUserList(_userId); }, [])

    const loadUserList = async (userId) => {
        const response = await GetChatUsers(userId, isLoading, isTokenExpired);
        if (response != null && response.data.length > 0) {
            let data = response.data;
            const _userIndex = data.findIndex(x => x.isActiveChatUser);
            data[_userIndex].unreadCount = 0;
            setUserList(data);
            setUnReadMessageCount(data);
            if (_userIndex >= 0) { loadChatHistory(data[_userIndex]); }
        }
    }

    const loadChatHistory = async (userInfo) => {
        setActiveConversationRoomId(userInfo.conversationRoomId);
        fetchUserInfo(userInfo);
        setActiveUser(userInfo);
        if (userInfo.conversationRoomId === _emptyGuid) { setChatHistory([]); }
        else {
            const response = await GetChat(userInfo.conversationRoomId, isLoading, isTokenExpired);
            if (response != null && response.data.length > 0) { setChatHistory(response.data); }
        }
    }
    const setActiveUser = (userInfo) => {
        if (userList.length > 0) {
            let _userList = userList;
            const activeUserIndex = _userList.findIndex(x => x.isActiveChatUser);
            const currentUserIndex = _userList.findIndex(x => x.conversationRoomId === userInfo.conversationRoomId);

            _userList[activeUserIndex].isActiveChatUser = false;
            _userList[activeUserIndex].unreadCount = 0;

            _userList[currentUserIndex].isActiveChatUser = true;
            _userList[currentUserIndex].unreadCount = 0;
            setUnReadMessageCount(_userList);
            setUserList(_userList);
        }
    }

    const fetchUserInfo = (userInfo) => { setUserInformation(userInfo); }

    const sendChat = async (payload, isFormData, currentConversationRoomId) => {

        let response = { data: [] }

        if (!isFormData) { response = await Send(payload, isLoading, isTokenExpired); }
        else { response = await SendMedia(payload, isLoading, isTokenExpired); }

        if (currentConversationRoomId === _emptyGuid && (response != null && response.data.length > 0)) {

            let _userList = userList;
            const activeUserIndex = _userList.findIndex(x => x.conversationRoomId === userInformation.conversationRoomId);

            const conversationRoomId = response.data[0].conversationRoomId;
            _userList[activeUserIndex].conversationRoomId = conversationRoomId;

            fetchUserInfo(_userList[activeUserIndex]);
            setUserList(_userList);
            setActiveConversationRoomId(conversationRoomId);
        }
        return response;
    }

    const setUnReadMessageCount = (data) => {
        let _count = 0;

        if (data != null && data.length > 0) {
            for (const item of data) { _count = _count + item.unreadCount; }
        }
        setUnreadCount(_count)
    }

    useEffect(() => { MarkAllRead(isTokenExpired); }, []);

    return <>
        <div className='chat_container'>
            <div className="chat_grid">
                <SearchUser params={{ unreadCount }} />
                <UserList params={{ userList, loadChatHistory, _defaultProfilePicture, setShowMessage }} />
            </div>
            ({(userList != null && userList.length > 0) ?
                <>
                    <div className={`message_content ${showMessage && "show_message"}`}>
                        <ChatProfile params={{ userInformation, _defaultProfilePicture, setShowMessage }} />
                        <div className="message-content">
                            <div className="px-3">
                                <Conversation params={{ chatHistory }} />
                            </div>
                            <SendMessage params={{ activeConversationRoomId, sendChat, setChatHistory, userInformation }} />
                        </div>
                    </div>
                </> : ""
            });
        </div>
    </>
}
