import RouteLinks from "../route";

const listingPanel = (item) => {
    switch (`/${item}`) {
        case RouteLinks.PropertyLocality:
            return true;
        case RouteLinks.PropertyDetail:
            return true;
        case RouteLinks.RentalDetail:
            return true;
        case RouteLinks.Amenities:
            return true;
        case RouteLinks.Gallery:
            return true;
        case RouteLinks.Publish:
            return true;
        default:
            return false;

    }
}

export default listingPanel;