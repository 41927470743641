
// import { PageTitlesData } from "../page-titles-data.js";
import RouteLinks from "../route/index.js";
import apptheme from "../../../app-theme.json";

const { brandName } = apptheme.brand;

export const PageTitle = (path) => {
    let newArr = path.split("/");
    const PageTitlesData = [
        { url: RouteLinks.Home, title: brandName },
        { url: RouteLinks.Login, title: `Account - ${brandName}` },
        { url: RouteLinks.AboutUs, title: `About Us - ${brandName}` },
        { url: RouteLinks.Contact, title: `Contact Us - ${brandName}` },
        { url: RouteLinks.FAQs, title: `Freequently Ask Questions - ${brandName}` },
        { url: RouteLinks.PrivacyPolicy, title: `Privacy Policy - ${brandName}` },
        { url: RouteLinks.TermAndConditions, title: `Terms And Conditions - ${brandName}` },
        { url: RouteLinks.Career, title: `Career - ${brandName}` },
        { url: RouteLinks.CareerDetails, title: `Career Details - ${brandName}` },
        { url: RouteLinks.ReferAndEarn, title: `Refer And Earn - ${brandName}` },
        { url: RouteLinks.Blog, title: `Blogs - ${brandName}` },
        { url: RouteLinks.BlogDetail, title: `Blog Details - ${brandName}` },
        { url: `${RouteLinks.AddProperty}${RouteLinks.PropertyLocality}`, title: `Add Property - ${brandName}` },
        { url: `${RouteLinks.PropertyListing}/${newArr[2]}${RouteLinks.PropertyLocality}`, title: `Edit Property - ${brandName}` },
        { url: RouteLinks.UpdateList, title: `Update List - ${brandName}` },
        { url: RouteLinks.PropertyView, title: `Property View - ${brandName}` },
        { url: `${RouteLinks.PropertyDetail}/${newArr[2]}`, title: `Property Details - ${brandName}` },
        { url: RouteLinks.PropertySearch, title: `Property Search - ${brandName}` },
        { url: RouteLinks.Dashboard, title: `Dashboard - ${brandName}` },
        { url: RouteLinks.Profile, title: `Profile - ${brandName}` },
        { url: RouteLinks.PropertyListing, title: `My Listing - ${brandName}` },
        { url: RouteLinks.Favourite, title: `Favourite - ${brandName}` },
        { url: RouteLinks.DocumentKyc, title: `Document kyc - ${brandName}` },
        { url: RouteLinks.Chats, title: `Chats - ${brandName}` },
        { url: `${RouteLinks.Chats}/${newArr[2]}`, title: `Chats - ${brandName}` },
        { url: RouteLinks.Tenent, title: `My Tenant - ${brandName}` },
        { url: RouteLinks.Tenency, title: `My Tenancy - ${brandName}` },
        { url: RouteLinks.NotFound, title: `NotFound - ${brandName}` },
    ];

    for (var i = 0; i < PageTitlesData.length; i++) {
        if (PageTitlesData[i].url === path) {
            var title = PageTitlesData[i].title;
            document.title = title;
        }
    }
}
