import React, { useState } from 'react';
import { Modal, Button, Form, Image, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../../state';
import { useFormik } from 'formik';
import { toastStyle } from '../../../../utils/http-requests';
import { toast } from 'react-toastify';
import { UpdateAvatar } from '../../../../services/api/client';
import { profilePicsInitialValue } from '../../../../helper/profile/profile-pics';
import { profilePicsSchema } from '../../../../helper/validations/profile-pics-schema';
import isTokenExpired from '../../../../utils/jwt-auth';
import appTheme from "../../../../../app-theme.json";

const UpdateAvatarModal = ({ profilePicture }) => {

    const [postImage, setPostImage] = useState({});

    const dispatch = useDispatch();
    const { showAvatarModal, showProfileImage, isLoader, redirectModal } = bindActionCreators(actionCreator, dispatch);
    const showUpdateAvatarModal = useSelector((state) => state.showAvatarModal);
    const profileImage = useSelector((state) => state.showProfileImage);
    const showLoader = useSelector((state) => state.isLoader);


    const closeAvatarModal = () => { showAvatarModal(false); setPostImage({}); resetForm(); }

    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setValues, resetForm } = useFormik({
        initialValues: profilePicsInitialValue,
        validationSchema: profilePicsSchema,
        onSubmit: async (values) => {
            const payload = { profilePicture: postImage.base64Image }
            const response = await UpdateAvatar(payload, isLoader, isTokenExpired, redirectModal);
            if (response != null) {
                const { data, errorMessages, isSuccess, successMessage } = response;
                if (isSuccess) { toast.success(successMessage, toastStyle); setPostImage({}); showProfileImage(!profileImage); }
                else { errorMessages.forEach((err) => { toast.error(err.message, toastStyle); }); }
            }
            closeAvatarModal();
            resetForm();
        },
    });

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleImage = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertToBase64(file);
        setPostImage({ base64Image: base64 });
        setValues({ profilePicture: base64 });
    }

    return (

        <Modal show={showUpdateAvatarModal} onHide={closeAvatarModal} centered >
            <Modal.Header closeButton>
                <h4 className='title'>{appTheme.profilePage.modalTitle}</h4>
            </Modal.Header>
            <Modal.Body>
                <Form className='fs--1'>
                    <div className="profile_image_box">
                        <div className="image_preview">
                            {
                                postImage.base64Image ? <Image src={postImage.base64Image} /> :
                                    <Image src={`${profilePicture ? profilePicture : appTheme.userDummyImage}`} accept="image/png, image/jpg, image/jpeg" />
                            }
                        </div>
                        {errors.profilePicture && touched.profilePicture ? (<p className="form-error">{errors.profilePicture}</p>) : null}
                    </div>
                    <div className="btn_box">
                        <Form.Group>
                            <Form.Label className='profile_pics'>{appTheme.profilePage.changeButton}<Form.Control size='sm' type='file' accept=".jpeg, .png, .jpg" onChange={handleImage} /></Form.Label>
                        </Form.Group>
                        <Button size='sm' className='submit_btn' variant='primary' onClick={handleSubmit} disabled={showLoader}>
                            {showLoader ? <Spinner animation="border" className='spinner_sm' /> : <>{appTheme.profilePage.submitButton}</>}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>

        </Modal>
    )
}

export default UpdateAvatarModal
