import * as Yup from 'yup';

const errorMessages = {
    propertyAvailabilities: "please select",
    expectedRent: "Please enter expected rent amount",
    monthlyMaintenance: "Please select monthly maintenance",
    availableFrom: "Please select available from",
    availableFrom: "Please select available from",
    preferredTenants: "Please select preferred tenants",
    furnishingType: "Please select furnishing type",
    parkingType: "Please select parking type",
    discription: "please enter detail discription"
}

export const RentalDetailsSchema = Yup.object({
    expectedRent: Yup.string().required(errorMessages.expectedRent),
    monthlyMaintenance: Yup.string().required(errorMessages.monthlyMaintenance),
    availableFrom: Yup.string().required(errorMessages.availableFrom),
    preferredTenants: Yup.string().required(errorMessages.preferredTenants),
    furnishingType: Yup.string().required(errorMessages.furnishingType),
    parkingType: Yup.string().required(errorMessages.parkingType),
    discription: Yup.string().required(errorMessages.discription).min(90, errorMessages.discription)

})