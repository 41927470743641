import React, { useEffect, useState } from 'react';
import { Button, Container, } from 'react-bootstrap';
import PropertyFilter from './components/property-filter';
import PropertyResults from './components/property-results';
import { FaSortAmountDownAlt } from 'react-icons/fa'
import "../../../styles/property-search.scss";
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../state';
import { Navigate, useLocation } from 'react-router-dom';
import { BiSliderAlt } from "react-icons/bi";
import appTheme from "../../../../app-theme.json";
import { GetResidentialRentProperty } from '../../../services/api/properties';
import RouteLinks from '../../../utils/route';
import { GetAllLookups } from "../../../services/api/Lookups";
import { ClientInterestedListing, Remove, Add } from '../../../services/api/client-interested-listing';
import isTokenExpired from '../../../utils/jwt-auth';
import { toast } from 'react-toastify';
import { toastStyle } from '../../../utils/http-requests';
import NotResult from './components/not-result';

const PropertySearch = () => {

    const [showFilter, setShowFilter] = useState(false);
    const [showSort, setShowSort] = useState(false);
    const [listingItems, setListingItems] = useState([]);
    const [showNoResult, setShowNoResult] = useState(false);
    const [favouriteList, setFavouriteList] = useState([]);
    const [favouriteListItems, setFavouriteListItems] = useState([]);
    const [reloadFavorite, setReloadFavorite] = useState(false);
    const [searchFilter, setSearchFilter] = useState("");
    const location = useLocation();

    const dispatch = useDispatch();
    const { hideFooter, isLoading, isLoader, redirectModal } = bindActionCreators(actionCreator, dispatch);

    const getAllLookups = async () => {
        const response = await GetAllLookups();
        if (response != null) { if (response.isSuccess) { localStorage.setItem("lookups", JSON.stringify(response.data)); } }
    }

    const allLookups = JSON.parse(localStorage.getItem("lookups"));
    useEffect(() => {
        if (allLookups === null) { getAllLookups(); }
    }, [allLookups]);

    useEffect(() => {
        hideFooter(true);
        return () => { hideFooter(false); setShowNoResult(false); }
    }, []);

    const hideBackdrop = () => {
        setShowFilter(false);
        setShowSort(false);
    }

    const _ClientInterestedListing = async () => {
        const response = await ClientInterestedListing(isTokenExpired);
        if (response != null) { if (response.isSuccess) { setFavouriteList(response.data); } }
    }

    const _GetResidentialRentProperty = async (payload) => {
        isLoading(true);
        const response = await GetResidentialRentProperty(payload);
        if (response != null) {
            if (response.isSuccess) {
                response.data.length === 0 ? setShowNoResult(true) : setShowNoResult(false);
                setListingItems(response.data.map((item) => { return ({ ...item, isFavourite: false }) }));
                setShowFilter(false);
                isLoading(false);
            }
        } isLoading(false);
    }

    useEffect(() => {
        if (searchFilter) {
            const { citiesTypes, localityAddress, bhkTypes, apartmentTypes, preferredTenants, furnishingTypes, parkingTypes, propertyAges } = searchFilter;
            const payload = {
                cityId: parseInt(citiesTypes),
                latitudeAndLongitude: localityAddress ? [`${localityAddress.latitude},${localityAddress.langitude}`] : [],
                bhkTypeIds: bhkTypes.map((id) => parseInt(id)),
                apartmentTypeIds: apartmentTypes.map((id) => parseInt(id)),
                minPrice: searchFilter.minPrice,
                maxPrice: searchFilter.maxPrice,
                minBuiltUpArea: searchFilter.minBuiltUpArea,
                maxBuiltUpArea: searchFilter.maxBuiltUpArea,
                preferredTenantsIds: preferredTenants.map((id) => parseInt(id)),
                furnishingTypeIds: furnishingTypes.map((id) => parseInt(id)),
                parkingTypeIds: parkingTypes.map((id) => parseInt(id)),
                propertyAgeId: propertyAges.map((id) => parseInt(id)),

            }
            _ClientInterestedListing();
            _GetResidentialRentProperty(payload);
        }

    }, [searchFilter, reloadFavorite]);


    useEffect(() => {
        let propertiesList = [...listingItems];
        propertiesList.forEach((property) => {
            favouriteList.forEach((favourite) => { if (property.propertyListingId === favourite.propertyListingId) { property.isFavourite = true } })
        });
        setFavouriteListItems(propertiesList);

    }, [listingItems, favouriteList]);

    const openHeartHandler = async (payload) => {
        isLoader(true);
        const response = await Add({ propertyListingId: payload }, isTokenExpired, redirectModal);
        if (response != null) {
            if (response.isSuccess) { setReloadFavorite(!reloadFavorite); isLoader(false); toast.success(response.successMessage, toastStyle); }
            else { response.errorMessages.forEach((error) => { isLoader(false); toast.error(error.message, toastStyle); }); }
        } else isLoader(false);
    }
    const fullHeartHandler = async (payload) => {
        isLoader(true);
        const response = await Remove({ propertyListingId: payload }, isTokenExpired, redirectModal);
        if (response != null) {
            if (response.isSuccess) { setReloadFavorite(!reloadFavorite); isLoader(false); toast.success(response.successMessage, toastStyle); }
            else { response.errorMessages.forEach((error) => { isLoader(false); toast.error(error.message, toastStyle); }); }
        } else isLoader(false);
    }

    const sort = showSort ? "custom_sort show_sort" : "custom_sort";

    if (!location.state) {
        return <Navigate to={RouteLinks.Home} />
    }

    return (
        <>
            <div className="property_search">
                <Container fluid>
                    <div className='main_row'>
                        <PropertyFilter showFilter={showFilter} location={location} setSearchFilter={setSearchFilter} />
                        {
                            listingItems.length > 0 &&
                            <PropertyResults
                                location={location}
                                listingItems={favouriteListItems}
                                openHeartHandler={openHeartHandler}
                                fullHeartHandler={fullHeartHandler}
                            />
                        }
                        {showNoResult && <NotResult />}

                    </div>
                    <div className="filter_btn_box">
                        <Button className="filter_btn" onClick={() => setShowFilter(true)}><BiSliderAlt /> Filter</Button>
                        <Button className='sort_btn' onClick={() => setShowSort(true)}><FaSortAmountDownAlt /> Sort</Button>
                    </div>
                </Container>
            </div>
            <div className={sort}>
                {appTheme.propertySearch.sortBy.map((sortItem) => <span key={sortItem.sortId}>{sortItem.sortType}</span>)}
            </div>
            {
                showFilter || showSort ? <div className='back_drop z_index' onClick={hideBackdrop} /> : ""
            }
        </>
    )
}

export default PropertySearch
