import * as Yup from 'yup';

export const addTenantEmailSchema = Yup.object({
    emailId: Yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "please enter valid email").required("please enter email or mobile no."),
    rent: Yup.string().required("please enter rent").min(4, "rent min Rs. 1000 "),
    agreementEndDate: Yup.string().required("please select end date"),
    agreementStartDate: Yup.string().required("please select start date")
});

export const addTenantMobileSchema = Yup.object({
    contactNumber: Yup.string().min(10, "mobile must be 10 charactor long").max(10, "please enter valid mobile no.").required("please enter email or mobile no."),
    rent: Yup.string().min(4, "rent min Rs. 1000 ").required("please enter rent"),
    agreementEndDate: Yup.string().required("please select end date"),
    agreementStartDate: Yup.string().required("please select start date")
});