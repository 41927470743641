import React from 'react';
import "../../../styles/common/notification.scss";
import { Offcanvas, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import RouteLinks from '../../../utils/route';
import moment from 'moment';

const NotificationCanvas = ({ NotificationItems }) => {

    const { showNotification, setShowNotification, notificationList, _MarkAllRead } = NotificationItems;

    const navigate = useNavigate();
    const handleClose = () => { setShowNotification(false); }

    const navigateHandler = (id) => {
        navigate(`${RouteLinks.Chats}/${id}`);
        handleClose();
    }

    return (

        <Offcanvas show={showNotification} onHide={handleClose} placement='end'>
            <Offcanvas.Header closeButton>
                <span className="read_all" onClick={() => _MarkAllRead()}>Mark all as read</span>
            </Offcanvas.Header>
            <Offcanvas.Body className="p-0">
                <div className="notification_box">
                    <div className="notification_body">
                        {notificationList.map((item) => {
                            return (
                                <div className="notification_items" key={item.notificationId} onClick={() => navigateHandler(item.notificationSendFrom)}>
                                    <div className="notification_image">
                                        <Image src={item.displayImage} />
                                    </div>
                                    <div className="notification_content">
                                        <p className="message">{item.message}</p>
                                        <p className="date">{moment(item.createdOn).format('lll')}</p>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default NotificationCanvas
