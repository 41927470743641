
export const propertyDetails = {
    propertyName: "",
    apartmentType: "",
    bhkTypes: "",
    floor: "",
    totalFloor: "",
    propertyAge: "",
    propertyFacing: "",
    area: "",
};
