import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { initialRentalDetails } from "../../helper/tabs/rentetal-details";
import { RentalDetailsSchema } from "../../helper/validations/rental-details-schema";
import { PropertyAvailabilities, MonthlyMaintenance, PreferredTenants, FurnishingTypes, ParkingTypes } from "../../services/api/Lookups";
import { toast } from "react-toastify";
import { toastStyle } from "../../utils/http-requests";
import { RentalDetail } from "../../services/api/residential-rent";
import isTokenExpired from "../../utils/jwt-auth";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreator } from "../../state";
import moment from "moment/moment";
import { useNavigate, useOutletContext } from "react-router-dom";
import appTheme from "../../../app-theme.json";
import RouteLinks from "../../utils/route";

const RentalDetails = () => {

  const [propertyAvailabilities, setgetPropertyAvailabilities] = useState([]);
  const [monthlyMaintenance, setMonthlyMaintenance] = useState([]);
  const [preferredTenants, setPreferredTenants] = useState([]);
  const [furnishingTypes, setFurnishingTypes] = useState([]);
  const [parkingTypes, setParkingTypes] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { redirectModal, isLoader } = bindActionCreators(actionCreator, dispatch);
  const showLoader = useSelector((state) => state.isLoader);

  const [localityData, setLocalityData, propertyListingData] = useOutletContext();

  const { propertyListingId, rentalDetails } = propertyListingData;

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: initialRentalDetails,
    validationSchema: RentalDetailsSchema,
    onSubmit: async (values, action) => {
      if (values) {
        const payload = {
          propertyListingId: propertyListingId,
          propertyAvailableForId: values.propertyAvailabilities,
          expectedRent: parseInt(values.expectedRent),
          expectedDeposit: parseInt(values.expectedDeposit),
          expectedLeaseAmount: values.expectedLeaseAmount,
          isRentNegotiable: values.isRentNegotiable,
          monthlyMaintenanceId: parseInt(values.monthlyMaintenance),
          maintenanceAmount: parseInt(values.maintenanceAmount),
          availableFrom: values.availableFrom,
          preferredTenantsId: parseInt(values.preferredTenants),
          furnishingTypeId: parseInt(values.furnishingType),
          parkingTypeId: parseInt(values.parkingType),
          description: values.discription
        }
        const response = await RentalDetail(payload, isLoader, isTokenExpired, redirectModal);
        if (response != null) {
          const { isSuccess, errorMessages, data, successMessage } = response;
          if (isSuccess) { toast.success(successMessage, toastStyle); navigate(`${RouteLinks.PropertyListing}/${data.propertyListingId}${RouteLinks.Amenities}`); }
          else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle); }); }
        }
      }
    }
  });

  const nextNavigate = () => {
    navigate(`${RouteLinks.PropertyListing}/${propertyListingId}${RouteLinks.Amenities}`);
  }

  const getPropertyAvailabilities = async () => {
    const response = await PropertyAvailabilities();
    if (response != null) {
      const { isSuccess, data, errorMessages } = response;
      if (isSuccess) { setgetPropertyAvailabilities(data); }
      else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle) }) }
    }
  }

  const getMonthlyMaintenance = async () => {
    const response = await MonthlyMaintenance();
    if (response != null) {
      const { isSuccess, data, errorMessages } = response;
      if (isSuccess) { setMonthlyMaintenance(data); }
      else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle) }) }
    }
  }

  const getPreferredTenants = async () => {
    const response = await PreferredTenants();
    if (response != null) {
      const { isSuccess, data, errorMessages } = response;
      if (isSuccess) { setPreferredTenants(data); }
      else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle) }) }
    }
  }
  const getFurnishingTypes = async () => {
    const response = await FurnishingTypes();
    if (response != null) {
      const { isSuccess, data, errorMessages } = response;
      if (isSuccess) { setFurnishingTypes(data); }
      else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle) }) }
    }
  }

  const getParkingTypes = async () => {
    const response = await ParkingTypes();
    if (response != null) {
      const { isSuccess, data, errorMessages } = response;
      if (isSuccess) { setParkingTypes(data); }
      else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle) }) }
    }
  }

  useEffect(() => {
    if (rentalDetails != null) {
      const { propertyAvailableForId, expectedRent, description, expectedDeposit, expectedLeaseAmount, isRentNegotiable, parkingTypeId, monthlyMaintenanceId, maintenanceAmount, availableFrom, preferredTenantsId, furnishingTypeId } = rentalDetails;
      setValues({
        ...values,
        propertyAvailabilities: propertyAvailableForId,
        expectedRent,
        expectedDeposit,
        expectedLeaseAmount,
        isRentNegotiable,
        monthlyMaintenance: monthlyMaintenanceId,
        maintenanceAmount,
        availableFrom: moment.utc(availableFrom).format('YYYY-MM-DD'),
        preferredTenants: preferredTenantsId,
        furnishingType: furnishingTypeId,
        parkingType: parkingTypeId,
        discription: description,
      });
    }

  }, [rentalDetails]);

  useEffect(() => {
    getPropertyAvailabilities();
    getMonthlyMaintenance();
    getPreferredTenants();
    getFurnishingTypes();
    getParkingTypes();
  }, []);

  const firstRadio = parseInt(values.propertyAvailabilities) === 2 ? "" : "first_radio";

  return (
    <>
      <h6 className="tab_title">{appTheme.rentalDetails.title}</h6>
      <div className="tab_content">
        {/* <Row className="mt-4">
          <Col md={5}>
            <Form.Group>
              <Form.Label className="form_label"> Property available for </Form.Label>
              <div className={`property_available ${firstRadio}`}>
                {propertyAvailabilities.map((items) => {
                  return (
                    <label key={items.propertyAvailableForId} className="radio_label">
                      <input type="radio" name="propertyAvailabilities" value={items.propertyAvailableForId} onChange={handleChange} onBlur={handleBlur} />
                      <span className="custom_radio"></span>
                      {items.type}
                    </label>
                  )
                })}
              </div>
            </Form.Group>
          </Col>
        </Row> */}
        <Row className="mt-4">
          <Col md={5}>
            <Form.Group>
              <Form.Label className="form_label">{appTheme.rentalDetails.expectedRent} *</Form.Label>
              <Form.Control size="sm" className="form_input" type="number" placeholder={appTheme.rentalDetails.expectedRent} name="expectedRent" onWheel={(e) => e.target.blur()} value={values.expectedRent} onChange={handleChange} onBlur={handleBlur} />
              {errors.expectedRent && touched.expectedRent ? (<p className="form-error">{errors.expectedRent}</p>) : null}
            </Form.Group>
          </Col>
          <Col md={5} className="mt-4 mt-md-0">
            <Form.Group>
              <Form.Label className="form_label">{appTheme.rentalDetails.expectedDeposit} *</Form.Label>
              <Form.Control size="sm" className="form_input" type="number" placeholder={appTheme.rentalDetails.expectedDeposit} name="expectedDeposit" onWheel={(e) => e.target.blur()} value={values.expectedDeposit} onChange={handleChange} onBlur={handleBlur} />
              {errors.expectedDeposit && touched.expectedDeposit ? (<p className="form-error">{errors.expectedDeposit}</p>) : null}
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={5}>
            <Form.Group>
              <Form.Label className="form_label">{appTheme.rentalDetails.monthlyMaintenance}</Form.Label>
              <Form.Select size="sm" className="form_select" aria-label="Default select example" name="monthlyMaintenance" value={values.monthlyMaintenance} onChange={handleChange} onBlur={handleBlur}>
                <option value="">{appTheme.rentalDetails.monthlyMaintenance}</option>
                {monthlyMaintenance.map((items) => {
                  return (
                    <option key={items.monthlyMaintenanceId} value={items.monthlyMaintenanceId}>{items.type}</option>
                  )
                })}
              </Form.Select>
              {errors.monthlyMaintenance && touched.monthlyMaintenance ? (<p className="form-error">{errors.monthlyMaintenance}</p>) : null}
            </Form.Group>
          </Col>
          {parseInt(values.monthlyMaintenance) === 2 &&
            <Col md={5}>
              <Form.Group>
                <Form.Label className="form_label">{appTheme.rentalDetails.maintenanceAmount}</Form.Label>
                <Form.Control size="sm" className="form_input" type="number" placeholder={appTheme.rentalDetails.maintenanceAmount} name="maintenanceAmount" onWheel={(e) => e.target.blur()} value={values.maintenanceAmount} onChange={handleChange} onBlur={handleBlur} />
              </Form.Group>
            </Col>
          }
        </Row>
        <Row className="mt-4">
          <Col md={5}>
            <Form.Group>
              <Form.Label className="form_label">{appTheme.rentalDetails.availableFrom} *</Form.Label>
              <Form.Control size="sm" className="form_input" type="date" name="availableFrom" value={values.availableFrom} onChange={handleChange} onBlur={handleBlur} />
              {errors.availableFrom && touched.availableFrom ? (<p className="form-error">{errors.availableFrom}</p>) : null}
            </Form.Group>
          </Col>
          <Col md={5} className="mt-4 mt-md-0">
            <Form.Group>
              <Form.Label className="form_label">{appTheme.rentalDetails.preferredTenants} *</Form.Label>
              <Form.Select size="sm" className="form_select" aria-label="Default select example" name="preferredTenants" value={values.preferredTenants} onChange={handleChange} onBlur={handleBlur}>
                <option value="" >{appTheme.rentalDetails.preferredTenants}</option>
                {preferredTenants.map((items) => {
                  return (
                    <option key={items.preferredTenantsId} value={items.preferredTenantsId} >{items.type}</option>
                  )
                })}
              </Form.Select>
              {errors.preferredTenants && touched.preferredTenants ? (<p className="form-error">{errors.preferredTenants}</p>) : null}
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={5}>
            <Form.Group>
              <Form.Label className="form_label">{appTheme.rentalDetails.furnishing} *</Form.Label>
              <Form.Select size="sm" className="form_select" aria-label="Default select example" name="furnishingType" value={values.furnishingType} onChange={handleChange} onBlur={handleBlur}>
                <option value="">{appTheme.rentalDetails.furnishing}</option>
                {furnishingTypes.map((items) => {
                  return (
                    <option key={items.furnishingTypeId} value={items.furnishingTypeId}>{items.type}</option>
                  )
                })}
              </Form.Select>
              {errors.furnishingType && touched.furnishingType ? (<p className="form-error">{errors.furnishingType}</p>) : null}
            </Form.Group>
          </Col>
          <Col md={5} className="mt-4 mt-md-0">
            <Form.Group>
              <Form.Label className="form_label">{appTheme.rentalDetails.parking} *</Form.Label>
              <Form.Select size="sm" className="form_select" aria-label="Default select example" name="parkingType" value={values.parkingType} onChange={handleChange} onBlur={handleBlur}>
                <option value="" >{appTheme.rentalDetails.parking}</option>
                {parkingTypes.map((items) => {
                  return (
                    <option key={items.parkingTypeId} value={items.parkingTypeId}>{items.type}</option>
                  )
                })}
              </Form.Select>
              {errors.parkingType && touched.parkingType ? (<p className="form-error">{errors.parkingType}</p>) : null}
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={10}>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>{appTheme.rentalDetails.discription}</Form.Label>
              <Form.Control type="text" className="form_input discription_text" size="sm" as="textarea" rows={10} placeholder={appTheme.rentalDetails.discription} name="discription" value={values.discription} onChange={handleChange} onBlur={handleBlur} />
              {errors.discription && touched.discription ? (<p className="form-error">{errors.discription}</p>) : null}
            </Form.Group>
          </Col>
        </Row>
        <div className="next_btn">
          <Button size="sm" className="mt-5 save" onClick={handleSubmit}>
            {showLoader ? <Spinner animation="border" className='spinner_sm' /> : <>{appTheme.rentalDetails.submitButton}</>}
          </Button>
          <Button size="sm" variant="primary" className="ms-auto mt-5 next" onClick={nextNavigate}>{appTheme.rentalDetails.nextButton}</Button>
        </div>
      </div>

    </>
  );
};

export default RentalDetails;
