import axios from 'axios';
import AppSetting from '../../../appConfig.json';
import { errorHandler } from '../validation/error-handler';
import { httpHeadersWithToken } from '../../utils/http-requests';


export const Add = async (payload, isLoader, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoader(true);
        try {
            const url = AppSetting.SERVER_URL + "/Tenants/Add";
            const res = await axios.post(url, payload, httpHeadersWithToken());
            isLoader(false);
            return res.data;
        } catch (error) {
            isLoader(false);
            return errorHandler(error);
        }
    }
}

export const MarkDelete = async (payload, isLoader, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoader(true);
        try {
            const url = AppSetting.SERVER_URL + `/Tenants/MarkDelete?propertyListingId=${payload}`;
            const res = await axios.post(url, httpHeadersWithToken());
            isLoader(false);
            return res.data;
        } catch (error) {
            isLoader(false);
            return errorHandler(error);
        }
    }
}

export const GetCurrentTenant = async (payload, isLoading, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoading(true);
        try {
            const url = AppSetting.SERVER_URL + `/Tenants/GetCurrentTenant?propertyListingId=${payload}`;
            const res = await axios.get(url, httpHeadersWithToken());
            isLoading(false);
            return res.data;
        } catch (error) {
            isLoading(false);
            return errorHandler(error);
        }
    }
}

export const GetTenantHistory = async (payload, isLoading, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoading(true);
        try {
            const url = AppSetting.SERVER_URL + `/Tenants/GetTenantHistory?propertyListingId=${payload}`;
            const res = await axios.get(url, httpHeadersWithToken());
            isLoading(false);
            return res.data;
        } catch (error) {
            isLoading(false);
            return errorHandler(error);
        }
    }
}