import React, { useEffect, useState } from 'react';
import { GetAll, GetPublished, GetDraft, GetInactive, GetById } from '../../../services/api/residential-rent';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../state';
import { GetAllLookups } from "../../../services/api/Lookups";
import { Table, Dropdown } from 'react-bootstrap';
import "../../../styles/property/property-listing.scss";
import ListingItems from './components/listing-items';
import isTokenExpired from '../../../utils/jwt-auth';
import PropertyDetailOffcanvas from './components/property-offcanvas';
import { GetCurrentTenant, GetTenantHistory } from '../../../services/api/tenants';
import CurrentTenant from './components/current-tenant';
import AllTenantHistory from './components/all-tenant-history';
import StatusConfirmation from './components/status-confirmation';
import AddTenant from './components/add-tenant';
import RemoveTenantConfirmation from "./components/remove-tenant-confirmation";
import appTheme from "../../../../app-theme.json";

export const PropertyListing = () => {

    const [listingItems, setListingItems] = useState([]);
    const [filteredProperty, setFilteredProperty] = useState({ filterValue: 1, filterType: "All" });
    const [propertyDetail, setPropertyDetail] = useState([]);
    const [showPropertyDetail, setShowPropertyDetail] = useState(false);
    const [status, setStatus] = useState({ activeStatus: "", propertyListingId: "" });
    const [addTenant, setAddTenant] = useState({ showAddTenant: false, listingItemId: "" });
    const [removeTenant, setRemoveTenant] = useState({ showRemoveTenant: false, listingItemId: "" });
    const [currentTenantInfo, setCurrentTenantInfo] = useState({ showCurrentTenant: false, currentTenantDetail: "" });
    const [allTenantHistory, setAllTenantHistory] = useState({ showAllTenant: false, allTenantDetail: [] });

    const dispatch = useDispatch();
    const { isLoading, redirectModal, confirmAndStatus } = bindActionCreators(actionCreator, dispatch);
    const propertyReload = useSelector((state) => state.confirmAndStatus)


    const getAllLookups = async () => {
        const response = await GetAllLookups();
        if (response != null) { if (response.isSuccess) { localStorage.setItem("lookups", JSON.stringify(response.data)); } }
    }

    const allLookups = JSON.parse(localStorage.getItem("lookups"));
    useEffect(() => {
        if (allLookups === null) { getAllLookups(); }
    }, [allLookups]);

    const _GetById = async (payload) => {
        const response = await GetById(payload, isLoading);
        if (response != null) {
            if (response.isSuccess) { setPropertyDetail(response.data); setShowPropertyDetail(true); }
        }
    }

    const _GetAll = async () => {
        const response = await GetAll(isLoading, isTokenExpired, redirectModal);
        if (response != null) {
            if (response.isSuccess) {
                setListingItems(response.data); confirmAndStatus(false);
                setFilteredProperty({ ...filteredProperty, filterValue: "", });
            }
        }
    }

    const _GetPublished = async () => {
        const response = await GetPublished(isLoading, isTokenExpired, redirectModal);
        if (response != null) {
            if (response.isSuccess) { setListingItems(response.data); confirmAndStatus(false); }
        }
    }

    const _GetDraft = async () => {
        const response = await GetDraft(isLoading, isTokenExpired, redirectModal);
        if (response != null) {
            if (response.isSuccess) { setListingItems(response.data); confirmAndStatus(false); }
        }
    }

    const _GetInactive = async () => {
        const response = await GetInactive(isLoading, isTokenExpired, redirectModal);
        if (response != null) {
            if (response.isSuccess) { setListingItems(response.data); confirmAndStatus(false); }
        }
    }

    const _GetCurrentTenant = async (payload) => {
        const response = await GetCurrentTenant(payload, isLoading, isTokenExpired, redirectModal);
        if (response != null) {
            if (response.isSuccess) {
                setCurrentTenantInfo({
                    ...currentTenantInfo,
                    currentTenantDetail: response.data,
                    showCurrentTenant: true
                });
            }
        }
    }

    const _GetTenantHistory = async (payload) => {
        const response = await GetTenantHistory(payload, isLoading, isTokenExpired, redirectModal);
        if (response != null) {
            if (response.isSuccess) {
                setAllTenantHistory({
                    ...allTenantHistory,
                    allTenantDetail: response.data,
                    showAllTenant: true
                });
            }
        }
    }

    useEffect(() => {
        if (propertyReload) { _GetAll(); }
    }, [propertyReload]);

    useEffect(() => {
        if (parseInt(filteredProperty.filterValue) === 1) { _GetAll(); }
        else if (parseInt(filteredProperty.filterValue) === 2) { _GetPublished(); }
        else if (parseInt(filteredProperty.filterValue) === 3) { _GetInactive(); }
        else if (parseInt(filteredProperty.filterValue) === 4) { _GetDraft(); }
    }, [filteredProperty]);

    const filterHandler = (filterItem) => {
        setFilteredProperty({
            filterValue: filterItem.propertyFilterValue, filterType: filterItem.propertyFilterType
        });
    }

    const viewPropertyHandler = (id) => {
        _GetById(id);
    }
    const currentTenantHandler = (id) => {
        _GetCurrentTenant(id);
    }
    const allTenantHandler = (id) => {
        _GetTenantHistory(id);
    }

    const hideDetailsHandler = () => {
        setShowPropertyDetail(false);
    }

    const hideCurrentTenantHandler = () => {
        setCurrentTenantInfo({
            ...currentTenantInfo,
            showCurrentTenant: false
        });
    }

    const hideAllTenantHandler = () => {
        setAllTenantHistory({
            ...allTenantHistory,
            showAllTenant: false
        });
    }

    const { propertyFilter } = appTheme.myListingPage;
    const { propertyImage, propertyName, propertyRent, propertyBuiltArea, propertyBHKType, propertyStatus, propertyTenant, propertyEdit, propertyView } = appTheme.myListingPage.property;

    return (
        <>
            <div className="table_box">
                <div className="filter_listing">
                    <div className="sort_dropdown">
                        <Dropdown>
                            <Dropdown.Toggle>
                                <span className="select_text">{filteredProperty.filterType}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {propertyFilter.map((filterItem) => {
                                    return (
                                        <Dropdown.Item key={filterItem.propertyFilterId} onClick={() => filterHandler(filterItem)}>{filterItem.propertyFilterType}</Dropdown.Item>
                                    )
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <Table bordered responsive>
                    <thead className="table_head">
                        <tr>
                            <th className="image_th">{propertyImage.title}</th>
                            <th className="name_th">{propertyName.title}</th>
                            <th className="rent_th">{propertyRent.title}</th>
                            <th className="area_th">{propertyBuiltArea.title}</th>
                            <th className="bhk_th">{propertyBHKType.title}</th>
                            <th className="status_th">{propertyStatus.title}</th>
                            <th className="tenant_th">{propertyTenant.title}</th>
                            <th className="edit_th">{propertyEdit.title}</th>
                            <th className="view_th">{propertyView.title}</th>
                        </tr>
                    </thead>
                    <tbody className="table_body">
                        {listingItems.map((listingItem) => {
                            return (
                                <ListingItems
                                    listingItem={listingItem}
                                    key={listingItem.propertyListingId}
                                    currentTenantInfo={currentTenantInfo}
                                    allTenantHistory={allTenantHistory}
                                    allStatus={{ status, setStatus }}
                                    tenant={{ addTenant, setAddTenant }}
                                    removeTenantStatus={{ removeTenant, setRemoveTenant }}
                                    viewPropertyHandler={viewPropertyHandler}
                                    currentTenantHandler={currentTenantHandler}
                                    allTenantHandler={allTenantHandler}
                                />
                            )
                        })}
                    </tbody>
                </Table>
            </div>

            <PropertyDetailOffcanvas propertyDetail={propertyDetail} show={showPropertyDetail} handleClose={hideDetailsHandler} />
            <AllTenantHistory allTenantHistory={allTenantHistory} handleClose={hideAllTenantHandler} />
            <CurrentTenant currentTenantInfo={currentTenantInfo} handleClose={hideCurrentTenantHandler} />
            <RemoveTenantConfirmation tenant={{ removeTenant, setRemoveTenant }} />
            <StatusConfirmation allStatus={{ status, setStatus }} />
            <AddTenant tenant={{ addTenant, setAddTenant }} />

        </>

    )
}

