import React, { useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import googleApiKey from "../../../google-api-key";
import "../../styles/common/locality-maps.scss";
import appTheme from "../../../app-theme.json";

const LocalityMaps = (props) => {
  const { latLangHandler } = props;
  const [libraries] = useState(["places"]);


  const containerStyle = {
    width: '100%',
    height: '300px'
  };

  const { latitude, langitude, name } = props.values.localityAddress;
  const center = {
    lat: latitude ? parseFloat(latitude) : 28.627562,
    lng: langitude ? parseFloat(langitude) : 77.278404
  };

  const { isLoaded } = useJsApiLoader({ googleMapsApiKey: googleApiKey.key, libraries });

  const [map, setMap] = useState(/** @type google.maps.Map*/(null))
  if (!isLoaded) {
    return;
  }

  return (
    <>
      <div className="maps_label">
        <h6 className="map_title">{appTheme.localityDetails.mapTitle}</h6>
        <p className="map_para">{appTheme.localityDetails.mapParagraph}</p>
      </div>
      <div className="map_box">
        <GoogleMap
          center={center}
          zoom={15}
          mapContainerStyle={containerStyle}
          onLoad={((map) => setMap(map))}
          onClick={latLang => latLangHandler(latLang)}
        >
          <Marker position={center} />
        </GoogleMap>
      </div>
    </>
  );
};

export default LocalityMaps;
