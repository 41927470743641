import * as Yup from 'yup';

const errorMessage = {
    city: "Please select city",
    locality: "Please enter locality",
    propertyType: "Please select property  type",
    bhkType: "Please select BHK type",
}

export const searchPropertySchema = Yup.object({
    city: Yup.string().required(errorMessage.city),
    localityAddress: Yup.object().required(errorMessage.locality),
    // propertyType: Yup.string().required(errorMessage.propertyType),
    // bhkType: Yup.string().required(errorMessage.bhkType),
});