import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FaArrowsAlt, FaTimes } from "react-icons/fa";
import { Slider } from '@mui/material';
import appTheme from "../../../../../app-theme.json";
import { searchFilter } from '../../../../helper/search-filter';
import { useFormik } from 'formik';
import { useJsApiLoader, StandaloneSearchBox } from '@react-google-maps/api';
import googleApiKey from "../../../../../google-api-key.json";
import { toast } from 'react-toastify';
import { searchErrroMessage, toastStyle } from '../../../../utils/http-requests';
import { searchCity } from '../../../../utils/google-maps';

let findCity = "";

const PropertyFilter = ({ showFilter, setSearchFilter, location }) => {

    const [priceRange, setPriceRange] = useState([appTheme.filter.priceRange.minPrice, appTheme.filter.priceRange.maxPrice]);
    const [size, setSize] = useState([appTheme.filter.sizeRange.minPrice, appTheme.filter.sizeRange.maxPrice]);
    const [localityValue, setLocalityValue] = useState("");
    const [searchLocation, setSearchLocation] = useState();
    const [newCities, setNewCities] = useState([]);
    const [newBhkTypes, setNewBhkTypes] = useState([]);
    const [newApartmentTypes, setNewApartmentTypes] = useState([]);
    const [newPreferredTenants, setNewPreferredTenants] = useState([]);
    const [newFurnishingType, setNewFurnishingType] = useState([]);
    const [newParkingTypes, setNewParkingTypes] = useState([]);
    const [newPropertyAges, setNewPropertyAges] = useState([]);
    const [libraries] = useState(["places"]);

    const { isLoaded } = useJsApiLoader({ googleMapsApiKey: googleApiKey.key, libraries });

    const { values, handleChange, handleBlur, errors, touched, handleSubmit, setValues } = useFormik({
        initialValues: searchFilter,
        onSubmit: (values) => {
            setSearchFilter(values);
        },
    });

    const allLookups = JSON.parse(localStorage.getItem("lookups"));
    useEffect(() => {
        if (allLookups) {
            setLocalityValue(location.state.localityAddress ? location.state.localityAddress.name : "");
            setNewCities(allLookups.cities.map((items) => { return ({ ...items, isChecked: false }) }));
            setNewBhkTypes(allLookups.bhkTypes.map((items) => { return ({ ...items, isChecked: false }) }));
            setNewApartmentTypes(allLookups.apartmentTypes.map((items) => { return ({ ...items, isChecked: false }) }));
            setNewPreferredTenants(allLookups.preferredTenants.map((items) => { return ({ ...items, isChecked: false }) }));
            setNewFurnishingType(allLookups.furnishingTypes.map((items) => { return ({ ...items, isChecked: false }) }));
            setNewParkingTypes(allLookups.parkingTypes.map((items) => { return ({ ...items, isChecked: false }) }));
            setNewPropertyAges(allLookups.propertyAges.map((items) => { return ({ ...items, isChecked: false }) }));
        }
        setTimeout(() => {
            handleSubmit();
        }, 100);
    }, []);

    useEffect(() => {
        setValues({
            ...values,
            minPrice: priceRange[0],
            maxPrice: priceRange[1],
            minBuiltUpArea: size[0],
            maxBuiltUpArea: size[1]
        });
    }, [size, priceRange,]);

    useEffect(() => {
        const { localityAddress, city, bhkType, propertyType } = location.state;
        setValues({
            ...values, localityAddress,
            citiesTypes: city,
            bhkTypes: bhkType ? [bhkType] : [],
            apartmentTypes: propertyType ? [propertyType] : []
        });
    }, [location.state]);


    useEffect(() => {
        if (allLookups) {

            let _bhkTypes = [...allLookups.bhkTypes];
            _bhkTypes.forEach((items) => {
                values.bhkTypes.forEach((id) => { if (items.bhkTypeId === parseInt(id)) { items.isChecked = true } })
            });

            let _apartmentTypes = [...allLookups.apartmentTypes];
            _apartmentTypes.forEach((items) => {
                values.apartmentTypes.forEach((id) => { if (items.apartmentTypeId === parseInt(id)) { items.isChecked = true } })
            });

            let _preferredTenants = [...allLookups.preferredTenants];
            _preferredTenants.forEach((items) => {
                values.preferredTenants.forEach((id) => { if (items.preferredTenantsId === parseInt(id)) { items.isChecked = true } })
            });

            let _furnishingTypes = [...allLookups.furnishingTypes];
            _furnishingTypes.forEach((items) => {
                values.furnishingTypes.forEach((id) => { if (items.furnishingTypeId === parseInt(id)) { items.isChecked = true } })
            });

            let _parkingTypes = [...allLookups.parkingTypes];
            _parkingTypes.forEach((items) => {
                values.parkingTypes.forEach((id) => { if (items.parkingTypeId === parseInt(id)) { items.isChecked = true } })
            });

            let _propertyAges = [...allLookups.propertyAges];
            _propertyAges.forEach((items) => {
                values.propertyAges.forEach((id) => { if (items.propertyAgeId === parseInt(id)) { items.isChecked = true } })
            });

            setNewBhkTypes(_bhkTypes);
            setNewApartmentTypes(_apartmentTypes);
            setNewPreferredTenants(_preferredTenants);
            setNewFurnishingType(_furnishingTypes);
            setNewParkingTypes(_parkingTypes);
            setNewPropertyAges(_propertyAges);
        }
    }, [values.citiesTypes, values.bhkTypes, values.apartmentTypes, values.preferredTenants, values.furnishingTypes, values.parkingTypes, values.propertyAges]);

    useEffect(() => { findCity = searchCity(values.citiesTypes); }, [values.citiesTypes]);

    const onLoadHandler = (ref) => {
        setSearchLocation(ref);
    }

    const handlePlaceChanged = () => {
        const [place] = searchLocation.getPlaces();
        if (place) {
            const filteredCity = place.formatted_address.search(findCity);
            if (filteredCity >= 0) {
                if (!findCity) { setLocalityValue(""); toast.warning(searchErrroMessage.cityText, toastStyle); }
                else {
                    setValues({
                        ...values,
                        localityAddress: {
                            name: place.formatted_address,
                            latitude: place.geometry.location.lat(),
                            langitude: place.geometry.location.lng()
                        }
                    });
                    setLocalityValue(place.formatted_address);
                }
            }
            else { setLocalityValue(""); toast.warning(`${searchErrroMessage.localityText} ${findCity}`, toastStyle); };

        }
    }

    const handleBhkTypes = (e) => {
        const { value, checked } = e.target;
        const { bhkTypes } = values;
        if (checked) { setValues({ ...values, bhkTypes: [...bhkTypes, value] }); }
        else { setValues({ ...values, bhkTypes: bhkTypes.filter((e) => e !== value) }); }
    }

    const handleApartment = (e) => {
        const { value, checked } = e.target;
        const { apartmentTypes } = values;
        if (checked) { setValues({ ...values, apartmentTypes: [...apartmentTypes, value] }); }
        else { setValues({ ...values, apartmentTypes: apartmentTypes.filter((e) => e !== value), }); }
    }

    const handlePreferredTenant = (e) => {
        const { value, checked } = e.target;
        const { preferredTenants } = values;
        if (checked) { setValues({ ...values, preferredTenants: [...preferredTenants, value] }); }
        else { setValues({ ...values, preferredTenants: preferredTenants.filter((e) => e !== value), }); }
    }

    const handleFurnishingTypes = (e) => {
        const { value, checked } = e.target;
        const { furnishingTypes } = values;
        if (checked) { setValues({ ...values, furnishingTypes: [...furnishingTypes, value] }); }
        else { setValues({ ...values, furnishingTypes: furnishingTypes.filter((e) => e !== value), }); }
    }

    const handleParkingTypes = (e) => {
        const { value, checked } = e.target;
        const { parkingTypes } = values;
        if (checked) { setValues({ ...values, parkingTypes: [...parkingTypes, value] }); }
        else { setValues({ ...values, parkingTypes: parkingTypes.filter((e) => e !== value), }); }
    }

    const handlePropertyAges = (e) => {
        const { value, checked } = e.target;
        const { propertyAges } = values;
        if (checked) { setValues({ ...values, propertyAges: [...propertyAges, value] }); }
        else { setValues({ ...values, propertyAges: propertyAges.filter((e) => e !== value), }); }
    }

    const cityChangeHandler = (e) => {
        setValues({
            ...values,
            citiesTypes: e.target.value,
            localityAddress: "",
        });
        setLocalityValue("");
    }

    const clearHanlder = () => {
        setValues({
            ...values,
            minPrice: 0,
            maxPrice: 15000,
            minBuiltUpArea: 0,
            maxBuiltUpArea: 2000,
            localityAddress: "",
            citiesTypes: 1,
            bhkTypes: [],
            apartmentTypes: [],
            preferredTenants: [],
            furnishingTypes: [],
            parkingTypes: [],
            propertyAges: [],
        });
        setPriceRange([0, 15000]);
        setSize([0, 2000]);
        setLocalityValue("");
    }

    const clearLocallity = () => {
        setValues({
            ...values,
            localityAddress: "",
        });
        setLocalityValue("");
    }

    return (
        <>
            <div className={`${showFilter ? "property_filter " : "property_filter show_filter"}`}>
                <div className="filter_box">
                    <div className="title_box">
                        <h6>{appTheme.filter.title}</h6>
                        <p onClick={clearHanlder}>{appTheme.filter.clear} <FaTimes className="close_icon" /></p>
                    </div>
                    <div className="filter_item_box">
                        <h6 className="filter_title">{appTheme.filter.citiyTitle}</h6>
                        <div className="city_box">
                            <Form.Select size="sm" value={values.citiesTypes} onChange={cityChangeHandler}>
                                {newCities.map((items) => {
                                    return (
                                        <option key={items.citiesLookupId} value={items.citiesLookupId}>{items.name}</option>
                                    )
                                })}
                            </Form.Select>
                        </div>
                    </div>
                    <div className="filter_item_box">
                        <h6 className="filter_title">{appTheme.filter.location.title}</h6>
                        {
                            isLoaded &&
                            <div className="search_box">
                                <StandaloneSearchBox className="input" onLoad={onLoadHandler} onPlacesChanged={handlePlaceChanged}>
                                    <Form.Control type="text" size="sm" placeholder="Search" name="localityAddress" value={`${localityValue.slice(0, 20)}${localityValue.length > 20 ? "..." : ""}`} onChange={(e) => setLocalityValue(e.target.value)} />
                                </StandaloneSearchBox>
                                {values.localityAddress || localityValue ? <FaTimes className="fa_times" onClick={clearLocallity} /> : null}
                            </div>
                        }
                    </div>
                    <div className="filter_item_box">
                        <h6 className="filter_title">{appTheme.filter.bhkTypeTitle}</h6>
                        <Row className="checkbox_row">
                            {
                                newBhkTypes.map((items) => {
                                    return (
                                        <Col xs={6} className='mb-3' key={items.bhkTypeId}>
                                            <Form.Check id={items.type} label={items.type} value={items.bhkTypeId} checked={items.isChecked} onChange={handleBhkTypes} />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                    <div className="filter_item_box">
                        <h6 className="filter_title">{appTheme.filter.apartmentTypesTitle}</h6>
                        <Row className="checkbox_row">
                            {
                                newApartmentTypes.map((items) => {
                                    return (
                                        <Col xs={6} className='mb-3' key={items.apartmentTypeId}>
                                            <Form.Check id={items.type} label={items.type} value={items.apartmentTypeId} checked={items.isChecked} onChange={handleApartment} />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                    <div className="filter_item_box">
                        <h6 className="filter_title">{appTheme.filter.priceRange.title}</h6>
                        <Slider
                            value={priceRange}
                            onChange={(e) => setPriceRange(e.target.value)}
                            valueLabelDisplay="auto"
                            max={appTheme.filter.priceRange.maxLimit}
                        />
                        <div className="price_range">
                            <p className='min_price'>Min <span>&#8377; {priceRange[0]}</span></p>
                            <p className='max_price'>Max <span>&#8377; {priceRange[1]}</span></p>
                        </div>
                    </div>
                    <div className="filter_item_box">
                        <h6 className="filter_title">{appTheme.filter.sizeRange.title}</h6>
                        <Slider
                            value={size}
                            onChange={(e) => setSize(e.target.value)}
                            valueLabelDisplay="auto"
                            max={appTheme.filter.sizeRange.maxLimit}
                        />
                        <div className="price_range">
                            <p className='min_price'>Min <span><FaArrowsAlt /> {size[0]}</span></p>
                            <p className='max_price'>Max <span><FaArrowsAlt /> {size[1]}</span></p>
                        </div>
                    </div>
                    <div className="filter_item_box">
                        <h6 className="filter_title">{appTheme.filter.preferredTenantsTitle}</h6>
                        <Row className="checkbox_row">
                            {
                                newPreferredTenants.map((items) => {
                                    return (
                                        <Col xs={6} className='mb-3 check_box' key={items.preferredTenantsId}>
                                            <Form.Check id={items.type} label={items.type} value={items.preferredTenantsId} checked={items.isChecked} onChange={handlePreferredTenant} />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                    <div className="filter_item_box">
                        <h6 className="filter_title">{appTheme.filter.furnishingTypeTitle}</h6>
                        <Row className="checkbox_row">
                            {
                                newFurnishingType.map((items) => {
                                    return (
                                        <Col xs={6} className='mb-3' key={items.furnishingTypeId}>
                                            <Form.Check label={items.type} id={items.type} value={items.furnishingTypeId} checked={items.isChecked} onChange={handleFurnishingTypes} />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                    <div className="filter_item_box">
                        <h6 className="filter_title">{appTheme.filter.parkingTypesTitle}</h6>
                        <Row className="checkbox_row">
                            {
                                newParkingTypes.map((items) => {
                                    return (
                                        <Col xs={6} className='mb-3' key={items.parkingTypeId}>
                                            <Form.Check label={items.type} id={items.type} value={items.parkingTypeId} checked={items.isChecked} onChange={handleParkingTypes} />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                    <div className="filter_item_box">
                        <h6 className="filter_title">{appTheme.filter.propertyAgesTitle}</h6>
                        <Row className="checkbox_row">
                            {
                                newPropertyAges.map((items) => {
                                    return (
                                        <Col xs={6} className='mb-3' key={items.propertyAgeId}>
                                            <Form.Check label={items.ageRange} id={items.ageRange} value={items.propertyAgeId} checked={items.isChecked} onChange={handlePropertyAges} />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                </div>
                <div className="apply_filter">
                    <Button onClick={handleSubmit}>{appTheme.filter.button}</Button>
                </div>
            </div>

        </>
    )
}

export default PropertyFilter
