import React from 'react';
import { Container, Row, Col, Image, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import RouteLinks from '../../utils/route';
import appTheme from "../../../app-theme.json";

const PropertyTypes = ({ listingItems }) => {

    const searchPageNavigate = useNavigate();

    const appartment = listingItems.length > 0 ? listingItems.filter((properties) => properties.apartmentTypeId === 1) : "0";
    const independentHouse = listingItems.length > 0 ? listingItems.filter((properties) => properties.apartmentTypeId === 2) : "0";
    const gatedCoummunity = listingItems.length > 0 ? listingItems.filter((properties) => properties.apartmentTypeId === 3) : "0";

    const addpropertySectorValue = (sectorItem) => {

        switch (sectorItem.propertySectorId) {
            case 1:
                return { ...sectorItem, value: appartment.length, searchValue: { city: 7, propertyType: "1" } }
            case 2:
                return { ...sectorItem, value: independentHouse.length, searchValue: { city: 7, propertyType: "2" } }
            case 3:
                return { ...sectorItem, value: gatedCoummunity.length, searchValue: { city: 7, propertyType: "3" } }
            case 4:
                return { ...sectorItem, value: independentHouse.length + gatedCoummunity.length, searchValue: { city: 7, propertyType: "2" } }
            default:
                break;
        }
    }

    let propertySector = appTheme.propertyTypes.propertySector.map((sector) => addpropertySectorValue(sector));
    propertySector.sort((a, b) => a.position - b.position);

    return (
        <div className="property_types section_padding">
            <Container>
                <div className="main_title">
                    <h1 className="title">{appTheme.propertyTypes.mainTitle}</h1>
                    <p className="subtitle">{appTheme.propertyTypes.mainSubTitile}</p>
                </div>
                {

                    <div className="property_sector">
                        <Row className="property_row">
                            {propertySector.map((propertyItem) => {
                                if (propertyItem.isPublished)
                                    return (
                                        <Col lg={3} sm={6} data-aos="zoom-in" className="property_col mb-4 mb-lg-0" key={propertyItem.propertySectorId} onClick={() => searchPageNavigate(RouteLinks.PropertySearch, { state: propertyItem.searchValue })}>
                                            <div className="property_link" >
                                                <div className="property_box">
                                                    <div className="property_img">
                                                        <Image className="img-fluid" src={propertyItem.img} />
                                                    </div>
                                                    <h6 className="property_title">{propertyItem.title}</h6>
                                                    <span className="property_value">{appartment == 0 ? 0 : propertyItem.value} {appTheme.propertyTypes.propertiesValue}</span>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                            })}
                        </Row>
                    </div>
                }

            </Container>
        </div>
    )
}

export default PropertyTypes;
