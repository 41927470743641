import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import appTheme from "../../../../app-theme.json";

const AboutSection = () => {

    return (
        <div className='about_sec section_padding'>
            <Container>
                <div className="main_title">
                    <h1 className="title">{appTheme.aboutSection.mainTitle}</h1>
                    <p className="subtitle">{appTheme.aboutSection.mainSubTitile}</p>
                </div>
                <div className="about">
                    <Row>
                        <Col md={6} data-aos="fade-up" className='about_content mt-4 mt-md-0 order-2 order-md-1'>
                            <h2 className='about_title'>{appTheme.aboutSection.title}</h2>
                            <p className='about_para'>{appTheme.aboutSection.paragraph}</p>
                            <Button className='about_btn'>{appTheme.aboutSection.button}</Button>
                        </Col>
                        <Col md={6} data-aos="fade-down" className="order-1 order-md-2">
                            <Image src={appTheme.aboutSection.image} rounded className='img-fluid' />
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default AboutSection
