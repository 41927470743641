import React from 'react';
import CountUp from 'react-countup';
import "../../styles/common/property-clients.scss";
import { Col, Container, Row } from 'react-bootstrap';
import appTheme from "../../../app-theme.json";


const PropertyClients = () => {

    const counterOptions = {
        duration: appTheme.propertyCounter.counterOptions.duration,
        enableScrollSpy: appTheme.propertyCounter.counterOptions.enableScrollSpy,
    }


    return (
        <div className='clients_sec section_padding'>
            <Container>
                <div className="main_title">
                    <h1 className="title">{appTheme.propertyCounter.mainTitle}</h1>
                    <p className="subtitle">{appTheme.propertyCounter.mainSubTitile}</p>
                </div>
                <Row>
                    {
                        appTheme.propertyCounter.counterData.map((item) => {
                            return (
                                <Col md={3} className='mb-4 mb-md-0' key={item.id} data-aos="zoom-in">
                                    <div className="client_box">
                                        <CountUp end={item.end} {...counterOptions} className='client_value' /> <span className='client_m'>+</span>
                                        <p className='client_lebel'>{item.label}</p>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}

export default PropertyClients
