import React from 'react';
import "../../../../styles/property/property-offcanvas.scss";
import { Offcanvas } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import appTheme from "../../../../../app-theme.json";
import moment from 'moment/moment';



const CurrentTenant = ({ currentTenantInfo, handleClose }) => {

    const { showCurrentTenant, currentTenantDetail } = currentTenantInfo;

    if (!currentTenantDetail) {
        return
    }

    const { currentTenant } = appTheme.myListingPage.property.propertyTenant;

    return (
        <div className="property_offcanvas">
            <Offcanvas show={showCurrentTenant} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{currentTenant.title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="current_tenant_info">
                        <div className="tenant_profile">
                            <div className="current_tenant_image">
                                <Image src={`${currentTenantDetail.profilePicture ? currentTenantDetail.profilePicture : appTheme.userDummyImage}`} />
                            </div>
                        </div>
                        <div className="current_tenant">
                            <div className="current_tenant_detail">
                                <span className="tenant_label">{currentTenant.firstName}</span>
                                <span className="tenant_value">{currentTenantDetail.firstName}</span>
                            </div>
                            <div className="current_tenant_detail">
                                <span className="tenant_label">{currentTenant.lastName}</span>
                                <span className="tenant_value">{currentTenantDetail.lastName}</span>
                            </div>
                            <div className="current_tenant_detail">
                                <span className="tenant_label">{currentTenant.contactNumber}</span>
                                <span className="tenant_value">{currentTenantDetail.tenantContact[0].contactNumber}</span>
                            </div>
                            <div className="current_tenant_detail">
                                <span className="tenant_label">{currentTenant.email}</span>
                                <span className="tenant_value">{currentTenantDetail.emailId}</span>
                            </div>
                            <div className="current_tenant_detail">
                                <span className="tenant_label">{currentTenant.startDate}</span>
                                <span className="tenant_value">{moment(currentTenantDetail.agreementStartDate).format("DD MMM YYYY")}</span>
                            </div>
                            <div className="current_tenant_detail">
                                <span className="tenant_label">{currentTenant.endDate}</span>
                                <span className="tenant_value">{moment(currentTenantDetail.agreementEndDate).format("DD MMM YYYY")}</span>
                            </div>
                            <div className="current_tenant_detail">
                                <span className="tenant_label">{currentTenant.rent}</span>
                                <span className="tenant_value">{currentTenantDetail.rent}</span>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default CurrentTenant;
