import React, { useEffect, useState } from 'react';
import "../../../styles/profile/dashboard.scss";
import { Row, Col } from "react-bootstrap";
import { FaList, FaTrashAlt, FaChartLine, FaInbox, FaHeart } from "react-icons/fa";
import { MdOutlinePublishedWithChanges } from "react-icons/md";
import appTheme from "../../../../app-theme.json";
import { GetAll, GetDraft, GetInactive, GetPublished } from '../../../services/api/residential-rent';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../state';
import isTokenExpired from '../../../utils/jwt-auth';
import { ClientInterestedListing } from '../../../services/api/client-interested-listing';

export const Dashboard = () => {

    const [listingItems, setListingItems] = useState([]);
    const [isRentedOut, setIsRentedOut] = useState([]);
    const [forRent, setForRent] = useState([]);
    const [inactiveItems, setInactiveItems] = useState([]);
    const [draftItems, setDraftItemsItems] = useState([]);
    const [favouriteItems, setfavouriteItems] = useState([]);

    const dispatch = useDispatch();
    const { isLoading, redirectModal } = bindActionCreators(actionCreator, dispatch);

    useEffect(() => {
        _GetAll();
        _GetPublished();
        _GetInactive();
        _GetDraft();
        _ClientInterestedListing();
    }, []);


    const _GetAll = async () => {
        const response = await GetAll(isLoading, isTokenExpired, redirectModal);
        if (response != null) {
            if (response.isSuccess) { setListingItems(response.data); }
        }
    }

    const _GetPublished = async () => {
        const response = await GetPublished(isLoading, isTokenExpired, redirectModal);
        if (response != null) {
            if (response.isSuccess) {
                setIsRentedOut(response.data.filter((rentOutFilter) => rentOutFilter.isRentedOut));
                setForRent(response.data.filter((forRentFilter) => !forRentFilter.isRentedOut));
            }
        }
    }

    const _GetInactive = async () => {
        const response = await GetInactive(isLoading, isTokenExpired, redirectModal);
        if (response != null) {
            if (response.isSuccess) { setInactiveItems(response.data); }
        }
    }

    const _GetDraft = async () => {
        const response = await GetDraft(isLoading, isTokenExpired, redirectModal);
        if (response != null) {
            if (response.isSuccess) { setDraftItemsItems(response.data); }
        }
    }

    const _ClientInterestedListing = async () => {
        const response = await ClientInterestedListing(isTokenExpired, redirectModal);
        if (response != null) {
            if (response.isSuccess) { setfavouriteItems(response.data); }
        }
    }


    const addPropertyIcons = (itemId) => {
        switch (itemId.id) {
            case 1:
                return { ...itemId, icon: <FaList />, propertyValue: listingItems.length, dbClass: "db_listed" }
            case 2:
                return { ...itemId, icon: <MdOutlinePublishedWithChanges />, propertyValue: forRent.length, dbClass: "" }
            case 3:
                return { ...itemId, icon: <FaChartLine />, propertyValue: isRentedOut.length, dbClass: "db_rentout" }
            case 4:
                return { ...itemId, icon: <FaTrashAlt />, propertyValue: inactiveItems.length, dbClass: "db_inactive" }
            case 5:
                return { ...itemId, icon: <FaInbox />, propertyValue: draftItems.length, dbClass: "db_listed" }
            case 6:
                return { ...itemId, icon: <FaHeart />, propertyValue: favouriteItems.length, dbClass: "db_rentout" }
            default:
                break;
        }
    }

    const { title, property } = appTheme.dashboardPage;
    const _property = property.map((propItem) => addPropertyIcons(propItem));

    return (
        <div className="dashboard">
            <h4 className='title'>{title}</h4>

            <div className="db_box">
                <Row>
                    {
                        _property.map((items) => {
                            return (
                                <Col lg={3} md={4} xs={6} className="mb-4" key={items.id}>
                                    <div className={`db_card ${items.dbClass}`}>
                                        <div className="db_icon ">{items.icon}</div>
                                        <h6 className="db_title">{items.propertyStatus}</h6>
                                        <p className="db_value">{items.propertyValue <= 9 ? `0${items.propertyValue}` : items.propertyValue} {items.propertyText}</p>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>

            <div className="db_box">
                <Row>
                    <Col lg={6}>
                        <div className="card tenant_card">
                            <div className="tenant_header">
                                <h6 className="tenant_title">Active Tenants</h6>
                            </div>
                            <div className="tenant_content">
                                <div className="tenant_list">
                                    <img src="/assets/img/users/profile-user.png" alt="" />
                                    <p className="name">Hukum singh</p>
                                    <p className="price">&#8377; 15000</p>
                                </div>
                                <div className="tenant_list">
                                    <img src="/assets/img/users/profile-user.png" alt="" />
                                    <p className="name">Hukum singh</p>
                                    <p className="price">&#8377; 15000</p>
                                </div>
                                <div className="tenant_list">
                                    <img src="/assets/img/users/profile-user.png" alt="" />
                                    <p className="name">Hukum singh</p>
                                    <p className="price">&#8377; 15000</p>
                                </div>
                                <div className="tenant_list">
                                    <img src="/assets/img/users/profile-user.png" alt="" />
                                    <p className="name">Hukum singh</p>
                                    <p className="price">&#8377; 15000</p>
                                </div>
                                <div className="tenant_list">
                                    <img src="/assets/img/users/profile-user.png" alt="" />
                                    <p className="name">Hukum singh</p>
                                    <p className="price">&#8377; 15000</p>
                                </div>

                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="card tenant_card">
                            <div className="tenant_header">
                                <h6 className="tenant_title">Tenants History</h6>
                            </div>
                            <div className="tenant_content">
                                <div className="tenant_list">
                                    <img src="/assets/img/users/profile-user.png" alt="" />
                                    <p className="name">Hukum singh</p>
                                    <p className="price">&#8377; 15000</p>
                                </div>
                                <div className="tenant_list">
                                    <img src="/assets/img/users/profile-user.png" alt="" />
                                    <p className="name">Hukum singh</p>
                                    <p className="price">&#8377; 15000</p>
                                </div>
                                <div className="tenant_list">
                                    <img src="/assets/img/users/profile-user.png" alt="" />
                                    <p className="name">Hukum singh</p>
                                    <p className="price">&#8377; 15000</p>
                                </div>
                                <div className="tenant_list">
                                    <img src="/assets/img/users/profile-user.png" alt="" />
                                    <p className="name">Hukum singh</p>
                                    <p className="price">&#8377; 15000</p>
                                </div>
                                <div className="tenant_list">
                                    <img src="/assets/img/users/profile-user.png" alt="" />
                                    <p className="name">Hukum singh</p>
                                    <p className="price">&#8377; 15000</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

        </div>

    )
}
