import React from "react";
import Banner from "../../../components/common/banner";
import appTheme from "../../../../app-theme.json";
import ReferAndEarnSection from "./components/refer-and-earn-section";
import BottomTopButton from "../../../components/common/bottom-top-button";


export const ReferAndEarn = () => {

    const addRandEComponent = (rAndEComponentItem) => {
        switch (rAndEComponentItem.componentName) {
            case "Banner":
                return { ...rAndEComponentItem, component: <Banner bannerContent={appTheme.referAndEarnPage.referAndEarnBannerContents} /> }
            case "ReferAndEarnSection":
                return { ...rAndEComponentItem, component: <ReferAndEarnSection /> }
            case "BottomTopButton":
                return { ...rAndEComponentItem, component: <BottomTopButton /> }
            default:
                break;
        }
    };

    let referAndEarnComponents = appTheme.referAndEarnPage.referAndEarnComponents.map((rAndEComponentItem) => addRandEComponent(rAndEComponentItem));
    referAndEarnComponents.sort((a, b) => a.position - b.position);


    return (
        <>
            {referAndEarnComponents.map((items) => {
                if (items && items.isPublished)
                    return (
                        <section key={items.position}>{items.component}</section>
                    )
            })}
        </>
    )
};
