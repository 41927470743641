import React from 'react';
import { Image } from 'react-bootstrap';
import appTheme from "../../../../../app-theme.json";

const NotResult = () => {

    const { notResult } = appTheme.propertySearch;

    return (

        <div className="property_results">
            <div className="not_result">
                <div className="not_img">
                    <Image className="img-fluid" src={notResult.image} />
                </div>
                <div className="not_content">
                    <h1 className="not_title">{notResult.title}</h1>
                    <p className="not_para">{notResult.paragraph}</p>
                </div>
            </div>
        </div>
    )
}

export default NotResult
