import ReducerActions from "../../utils/reducer-actions";

const reducer = (state = "/", action) => {
    if (action.type === ReducerActions.recentPath) {
        return action.payload;
    }
    return state;
};

export default reducer;
