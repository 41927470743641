
export const LocalityDetails = {
    city: "",
    street: "",
    propertySector: "",
    propertyType: "",
    localityAddress: "",
};

export const addLocalityDetails = {
    city: "",
    street: "",
    propertySector: "",
    propertyType: "",
    localityAddress: "",
};

