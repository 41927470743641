import React, { useState } from 'react';
import "../../styles/profile/my-tenent.scss";
import TenentList from '../../components/profile/my-tenent/tenent-list';
import TenentDetails from '../../components/profile/my-tenent/tenent-details';
import appTheme from "../../../app-theme.json";

const dummyData = [
    {
        id: 1,
        img: "/assets/img/users/profile-user.png",
        name: "Hukam S.",
        address: "Laxmi Nagar",
        date: "01/08/23",
        status: "Active",
        rent: "11,000.00",
        active: "status_active"
    },
    {
        id: 2,
        img: "/assets/img/users/akshay-kumar.png",
        name: "Akshay K.",
        address: "Punjabi Bagh",
        date: "25/03/22",
        status: "31/12/23",
        rent: "13,000.00",
        active: ""
    },
    {
        id: 3,
        img: "/assets/img/users/pran.png",
        name: "Pran S.",
        address: "Nangloi",
        date: "01/01/23",
        status: "31/09/23",
        rent: "8,500.00",
        active: ""
    },
    {
        id: 4,
        img: "/assets/img/users/hritik-roshan.png",
        name: "Hritik R.",
        address: "Shahadra",
        date: "19/04/23",
        status: "Active",
        rent: "5,500.00",
        active: "status_active"
    },
    {
        id: 5,
        img: "/assets/img/users/sanjay-dutt.png",
        name: "Sanjay D.",
        address: "Mayur Vihar",
        date: "23/11/21",
        status: "Active",
        rent: "17,500.00",
        active: "status_active"
    },
    {
        id: 6,
        img: "/assets/img/users/profile-user.png",
        name: "Hukam S.",
        address: "Laxmi Nagar",
        date: "01/08/23",
        status: "Active",
        rent: "11,000.00",
        active: "status_active"
    },
    {
        id: 7,
        img: "/assets/img/users/akshay-kumar.png",
        name: "Akshay K.",
        address: "Punjabi Bagh",
        date: "25/03/22",
        status: "31/12/23",
        rent: "13,000.00",
        active: ""
    },
    {
        id: 8,
        img: "/assets/img/users/pran.png",
        name: "Pran S.",
        address: "Nangloi",
        date: "01/01/23",
        status: "31/09/23",
        rent: "8,500.00",
        active: ""
    },
    {
        id: 9,
        img: "/assets/img/users/hritik-roshan.png",
        name: "Hritik R.",
        address: "Shahadra",
        date: "19/04/23",
        status: "Active",
        rent: "5,500.00",
        active: "status_active"
    },
    {
        id: 10,
        img: "/assets/img/users/sanjay-dutt.png",
        name: "Sanjay D.",
        address: "Mayur Vihar",
        date: "23/11/21",
        status: "Active",
        rent: "17,500.00",
        active: "status_active"
    },
    {
        id: 11,
        img: "/assets/img/users/profile-user.png",
        name: "Hukam S.",
        address: "Laxmi Nagar",
        date: "01/08/23",
        status: "Active",
        rent: "11,000.00",
        active: "status_active"
    },
    {
        id: 12,
        img: "/assets/img/users/akshay-kumar.png",
        name: "Akshay K.",
        address: "Punjabi Bagh",
        date: "25/03/22",
        status: "31/12/23",
        rent: "13,000.00",
        active: ""
    },
    {
        id: 13,
        img: "/assets/img/users/pran.png",
        name: "Pran S.",
        address: "Nangloi",
        date: "01/01/23",
        status: "31/09/23",
        rent: "8,500.00",
        active: ""
    },
    {
        id: 14,
        img: "/assets/img/users/hritik-roshan.png",
        name: "Hritik R.",
        address: "Shahadra",
        date: "19/04/23",
        status: "Active",
        rent: "5,500.00",
        active: "status_active"
    },
    {
        id: 15,
        img: "/assets/img/users/sanjay-dutt.png",
        name: "Sanjay D.",
        address: "Mayur Vihar",
        date: "23/11/21",
        status: "Active",
        rent: "17,500.00",
        active: "status_active"
    },

]

export const MyTenent = () => {

    const [showDetails, setShowDetails] = useState(false);
    const [activeList, setActiveList] = useState(null);



    return (
        <>
            <div className="tanent_container">
                <div className="message_list_container">
                    <div className="message_title">
                        <div className="messages">
                            <h4 className='title'>{appTheme.myTenantPage.title}</h4>
                        </div>
                    </div>
                    <div className="message_list">
                        <div className="tenant_content font_size">
                            <ul className='tennat_box'>
                                <li className='tenant_list_title'>
                                    <div className='list_text'>{appTheme.myTenantPage.tenant}</div>
                                    <div className='list_text'>{appTheme.myTenantPage.startDate}</div>
                                    <div className='list_text'>{appTheme.myTenantPage.endDate}</div>
                                    <div className='list_text'>{appTheme.myTenantPage.rentPrice}</div>
                                    <div className='list_text'></div>
                                </li>
                                {
                                    dummyData.map((item) => <TenentList item={item} key={item.id} isActive={activeList === item.id} setActiveList={setActiveList} setShowDetails={setShowDetails} />)
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <TenentDetails showDetails={showDetails} setShowDetails={setShowDetails} />
            </div>
        </>
    )
}
