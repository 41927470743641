import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../state';
import { Add, ClientInterestedListing, Remove } from '../../../services/api/client-interested-listing';
import { toast } from 'react-toastify';
import { toastStyle } from '../../../utils/http-requests';
import isTokenExpired from '../../../utils/jwt-auth';
import PropertyCardItem from './property-card-item';
import { useNavigate } from 'react-router-dom';
import RouteLinks from '../../../utils/route';
import appTheme from "../../../../app-theme.json";

const PropertyItems = ({ listingItems, setFavLoadProperty }) => {

    const [favouriteListItems, setFavouriteListItems] = useState([]);
    const [favouriteList, setFavouriteList] = useState([]);
    const searchNavigate = useNavigate();

    const dispatch = useDispatch();
    const { isLoader, redirectModal, favouritePropertyLoader } = bindActionCreators(actionCreator, dispatch);

    const authentication = useSelector((state) => state.isAuthenticated);
    const favouritLoader = useSelector((state) => state.favouritePropertyLoader);

    const _ClientInterestedListing = async () => {
        const response = await ClientInterestedListing(isTokenExpired);
        if (response != null) { if (response.isSuccess) { setFavouriteList(response.data); } }
    }

    useEffect(() => {
        _ClientInterestedListing();
    }, [authentication, favouritLoader]);

    useEffect(() => {
        let propertiesList = [...listingItems];
        propertiesList.forEach((property) => {
            favouriteList.forEach((favourite) => { if (property.propertyListingId === favourite.propertyListingId) { property.isFavourite = true } })
        });
        setFavouriteListItems(propertiesList);
    }, [listingItems, favouriteList]);

    const openHeartHandler = async (payload) => {
        isLoader(true);
        const response = await Add({ propertyListingId: payload }, isTokenExpired, redirectModal);
        if (response != null) {
            if (response.isSuccess) { favouritePropertyLoader(!favouritLoader); isLoader(false); setFavLoadProperty(true); toast.success(response.successMessage, toastStyle); }
            else { response.errorMessages.forEach((error) => { isLoader(false); toast.error(error.message, toastStyle); }); }
        } else isLoader(false);
    }
    const fullHeartHandler = async (payload) => {
        isLoader(true);
        const response = await Remove({ propertyListingId: payload }, isTokenExpired, redirectModal);
        if (response != null) {
            if (response.isSuccess) { favouritePropertyLoader(!favouritLoader); isLoader(false); setFavLoadProperty(true); toast.success(response.successMessage, toastStyle); }
            else { response.errorMessages.forEach((error) => { isLoader(false); toast.error(error.message, toastStyle); }); }
        } else isLoader(false);
    }

    const morePropertyHandler = () => {
        searchNavigate(RouteLinks.PropertySearch, { state: { city: 7 } });
    }


    if (favouriteListItems.length === 0) {
        return;
    }

    return (
        <div className="property_main">
            <Row>
                {
                    listingItems.map((items, index) => {
                        if (index <= 5)
                            return (
                                <PropertyCardItem
                                    propertyCardItems={items}
                                    openHeartHandler={openHeartHandler}
                                    fullHeartHandler={fullHeartHandler}
                                    key={items.propertyListingId}
                                />
                            )
                    })
                }
                <Col sm={12} className="more_btn_col">
                    <Button className="more_btn" onClick={morePropertyHandler}>{appTheme.regionalPropertyHomepage.button}</Button>
                </Col>
            </Row>
        </div>
    )
}

export default PropertyItems
