import React, { useState, useEffect } from 'react';
import "../../../../styles/property/property-offcanvas.scss";
import { Offcanvas } from 'react-bootstrap';
import { FaMapMarkerAlt } from "react-icons/fa";
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PropertyGallery from './property-gallery';
import appTheme from "../../../../../app-theme.json";
import moment from 'moment/moment';
import configData from "../../../../../appConfig.json";


let sliderImg = [];

const PropertyDetailOffcanvas = ({ propertyDetail, show, handleClose }) => {

    const editNavigate = useNavigate();
    const [bhkType, setBhkType] = useState("");
    const [facingType, setFacingType] = useState("");
    const [parking, setParking] = useState("");
    const [preferredTenant, setPreferredTenant] = useState("");
    const [propertyAge, setPropertyAge] = useState("");
    const [apartmentType, setApartmentType] = useState("");
    const [showListing, setShowListing] = useState(false);

    const { locality, apartmentName, propertyFacingId, bhkTypeId, apartmentTypeId, floorId, builtUpArea, propertyAgeId, amenities, rentalDetails, galleries, totalFloorId } = propertyDetail;
    const newRentalDetails = rentalDetails ? rentalDetails : "";
    const newAmenities = amenities ? amenities : "";
    const { expectedDeposit, expectedRent, parkingTypeId, availableFrom, preferredTenantsId } = newRentalDetails;
    const posted = moment.utc(availableFrom).format("MMM Do YYYY");

    const allLookups = JSON.parse(localStorage.getItem("lookups"));
    useEffect(() => {
        if (allLookups) {
            allLookups.bhkTypes.forEach((item) => {
                if (item.bhkTypeId === bhkTypeId)
                    setBhkType(item.type);
            });
            allLookups.apartmentTypes.forEach((item) => {
                if (item.apartmentTypeId === apartmentTypeId)
                    setApartmentType(item.type);
            });

            allLookups.preferredTenants.forEach((item) => {
                if (item.preferredTenantsId === preferredTenantsId)
                    setPreferredTenant(item.type);
            });

            allLookups.propertyFacing.forEach((item) => {
                if (item.propertyFacingId === propertyFacingId)
                    setFacingType(item.facing);

            });

            allLookups.parkingTypes.forEach((item) => {
                if (item.parkingTypeId === parkingTypeId)
                    setParking(item.type);
            });

            allLookups.propertyAges.forEach((item) => {
                if (item.propertyAgeId === propertyAgeId)
                    setPropertyAge(item.ageRange);
            });
        }
    }, [propertyDetail, show]);

    useEffect(() => {
        sliderImg = [];
        if (galleries) {
            galleries.forEach((item, index) => {
                sliderImg.push({
                    original: `${configData.GALLERY_URL}${item.name}`,
                    thumbnail: `${configData.GALLERY_URL}${item.name}`,
                    originalClass: "original_image",
                })
            });
        }
    }, [propertyDetail, galleries]);

    return (
        <div className="property_offcanvas">
            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="property_view">
                        <div className="property_contnet">
                            <PropertyGallery sliderImg={sliderImg} />
                            <div className="overview_details">
                                <h5 className='title'>{apartmentName}</h5>
                                <p className="location"><FaMapMarkerAlt />{locality}</p>
                                <div className="rent_posted">
                                    <p className="expected_rent"><span>&#8377;</span>{expectedRent}</p>
                                    <p className="posted_date"> Posted on {posted}</p>
                                </div>
                                <hr className='mb-2 mt-0' />
                                <div className="properties_details">
                                    <Row className='property_ul'>
                                        <Col xs={6} className='label'>{appTheme.propertyDetails.appartmentType}</Col>
                                        <Col xs={6} className='value'>{apartmentName}</Col>
                                        <Col xs={6} className='label'>{appTheme.propertyDetails.appartmentType}</Col>
                                        <Col xs={6} className='value'>{apartmentType}</Col>
                                        <Col xs={6} className='label'>{appTheme.rentalDetails.preferredTenants}</Col>
                                        <Col xs={6} className='value'>{preferredTenant}</Col>
                                        <Col xs={6} className='label'>{appTheme.propertySearchDetails.propertyDetails.bathroom}</Col>
                                        <Col xs={6} className='value'>{newAmenities.numberOfBathrooms} Bathroom</Col>
                                        <Col xs={6} className='label'>{appTheme.propertySearchDetails.propertyDetails.balcony}</Col>
                                        <Col xs={6} className='value'>{newAmenities.numberOfBalcony} Balcony</Col>
                                        <Col xs={6} className='label'>{appTheme.propertyDetails.propertyAge}</Col>
                                        <Col xs={6} className='value'>{propertyAge}</Col>
                                        <Col xs={6} className='label'>{appTheme.propertyDetails.builtUpArea}</Col>
                                        <Col xs={6} className='value'>{builtUpArea}</Col>
                                        <Col xs={6} className='label'>{appTheme.propertyDetails.facing}</Col>
                                        <Col xs={6} className='value'>{facingType}</Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default PropertyDetailOffcanvas;
