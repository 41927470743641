import { Image } from 'react-bootstrap';
import { FaArrowLeft } from "react-icons/fa";


export const ChatProfile = (inputs) => {

    return (
        <>
            <div className="message_title">
                <span className="hide_chat_btn" onClick={() => inputs.params.setShowMessage(false)}> <FaArrowLeft /></span>
                <div className="user_active">
                    <Image src={inputs.params.userInformation.profilePicture ? inputs.params.userInformation.profilePicture : inputs.params._defaultProfilePicture} />
                </div>
                <div className="user_name_status">
                    <span className="name">{inputs.params.userInformation.firstName} {inputs.params.userInformation.lastName}</span>
                </div>
            </div>
        </>
    )
}