import React from 'react';
import "../../../styles/profile/tenent-details.scss";
import { Image, Accordion, Button } from "react-bootstrap";
import { FaArrowLeft, FaFilePdf, FaTrashAlt, FaCheck, FaTimes, FaCaretDown } from "react-icons/fa";
import appTheme from "../../../../app-theme.json";



const TenentDetails = ({ showDetails, setShowDetails }) => {

    const { title, removeButton, userDetails, personalDetails, agreement, documentKyc, tenantHistory } = appTheme.myTenantPage.tenantDetails;

    return (
        <div className={`message_content_container ${showDetails && "show_details"}`}>
            <div className="message_title">
                <FaArrowLeft onClick={() => setShowDetails(false)} />
                <div className="tenant_view">{title}</div>
            </div>
            <div className="message_content">
                <div className="tenant_card_detail">
                    <div className="tenant_name_img">
                        <span className="tenant_img">
                            <Image src="/assets/img/users/profile-user.png" />
                        </span>
                        <span className="tenant_name_location">
                            <span className="tenant_name">Hukam S.</span>
                            <span className="tenant_location">Laxmi Nagar</span>
                        </span>
                    </div>
                    <div className="rent_detail">
                        <span className='rent_label'>{userDetails.startDate}</span>
                        <span className='rent_value'>1 Aug 2013</span>
                    </div>
                    <div className="rent_detail">
                        <span className='rent_label'>{userDetails.member}</span>
                        <span className='rent_value'>4</span>
                    </div>
                    <div className="rent_detail">
                        <span className='rent_label'>{userDetails.rentPrice}</span>
                        <span className='rent_value'>11,000.00</span>
                    </div>
                    <div className="rent_detail">
                        <span className='rent_label'>{userDetails.depositePrice}</span>
                        <span className='rent_value'>11,000.00</span>
                    </div>
                </div>
                <div className="tenant_card_detail">
                    <div className="tenant_name_img">
                        <span className="tenant_name_location">
                            <span className="tenant_name">{personalDetails.title}</span>
                        </span>
                    </div>

                    <div className="rent_detail">
                        <span className='rent_label'>{personalDetails.mobileNo}</span>
                        <span className='rent_value'>9717309081</span>
                    </div>

                    <div className="rent_detail">
                        <span className='rent_label'>{personalDetails.houseNo}</span>
                        <span className='rent_value'>H. No. 157 B</span>
                    </div>

                    <div className="rent_detail">
                        <span className='rent_label'>{personalDetails.area}</span>
                        <span className='rent_value'>Pratap Vihar, Part - 2</span>
                    </div>

                    <div className="rent_detail">
                        <span className='rent_label'>{personalDetails.locality}</span>
                        <span className='rent_value'>Sultan Puri</span>
                    </div>

                    <div className="rent_detail">
                        <span className='rent_label'>{personalDetails.state}</span>
                        <span className='rent_value'>Delhi</span>
                    </div>

                    <div className="rent_detail">
                        <span className='rent_label'>{personalDetails.pinCode}</span>
                        <span className='rent_value'>110086</span>
                    </div>
                </div>
                <div className="tenant_card_detail">
                    <div className="tenant_name_img">
                        <span className="tenant_name_location">
                            <span className="tenant_name">{agreement.title}</span>
                        </span>
                    </div>

                    <div className="rent_detail">
                        <span className='rent_label'>{agreement.rentAgreement}</span>
                        <span className='rent_value'><FaFilePdf className='pdf' /></span>
                    </div>
                </div>
                <div className="tenant_card_detail">
                    <div className="tenant_name_img">
                        <span className="tenant_name_location">
                            <span className="tenant_name">{documentKyc.title}</span>
                        </span>
                    </div>

                    <div className="rent_detail">
                        <span className='rent_label'>{documentKyc.adharCard}</span>
                        <span className='rent_value'><FaCheck className='check_icon' /></span>
                    </div>

                    <div className="rent_detail">
                        <span className='rent_label'>{documentKyc.panCard}</span>
                        <span className='rent_value'><FaCheck className='check_icon' /></span>
                    </div>

                    <div className="rent_detail">
                        <span className='rent_label'>{documentKyc.voterId}</span>
                        <span className='rent_value'><FaTimes className='close_icon' /></span>
                    </div>
                </div>
                <div className="tenant_card_detail">
                    <div className="tenant_name_img">
                        <span className="tenant_name_location">
                            <span className="tenant_name">{tenantHistory.title}</span>
                        </span>
                    </div>
                    <Accordion className='rent_accordion' defaultActiveKey="0">
                        {tenantHistory.tenantsLocation.map((locationItem) => {
                            return (
                                <Accordion.Item eventKey={locationItem.tenantsEventKey} key={locationItem.tenantsLocationId}>
                                    <Accordion.Header>{locationItem.tenantsLocationTitle}<FaCaretDown className='angle_icon' /></Accordion.Header>
                                    <Accordion.Body>
                                        {locationItem.tenantDetails.map((tenantDetailsItem) => {
                                            return (
                                                <div className="rent_detail" key={tenantDetailsItem.tenantDetailsId}>
                                                    <span className='rent_label'>{tenantDetailsItem.tenantDetailsLabel}</span>
                                                    <span className='rent_value'>{tenantDetailsItem.tenantDetailsType}</span>
                                                </div>
                                            )
                                        })}
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })}
                    </Accordion>
                </div>
                <div className="message_input small_device">
                    <Button className="remove_tenant"><FaTrashAlt />{removeButton}</Button>
                </div>
            </div>
            <div className="message_input large_device">
                <Button className="remove_tenant"><FaTrashAlt />{removeButton}</Button>
            </div>
        </div>
    )
}

export default TenentDetails
