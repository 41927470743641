import React from 'react';
import "../../../../../styles/others/blog-detail.scss";
import { Col, Container, Form, Image, Row } from 'react-bootstrap';
import appTheme from "../../../../../../app-theme.json";
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagramSquare } from "react-icons/fa";

const BlogDetailSection = () => {

    const { mainTitle, mainSubTitile, detailContents } = appTheme.blogDetailPage.blogDetailSection;

    return (
        <div className="blog_detail section_padding">
            <Container>
                <div className="main_title">
                    <h1 className="title">{mainTitle}</h1>
                    <p className="subtitle">{mainSubTitile}</p>
                </div>
                <div className="blog_detail">
                    <Row>
                        <Col lg={8}>
                            <div className="blog_details_warpper">
                                <div className="details_image">
                                    <Image className="img-fluid" src={detailContents.image} />
                                </div>
                                <div className="details_content">
                                    <h3 className="details_title">{detailContents.title}</h3>
                                    <p className="details_date">{detailContents.date}</p>
                                    {detailContents.detailsPara.map((detailContentsItem) => {
                                        return (<p className="details_para" key={detailContentsItem.detailsParaId}>{detailContentsItem.detailsParaType}</p>)
                                    })}
                                    <blockquote className="blockquote">{detailContents.blockquote}</blockquote>
                                    {detailContents.detailsParagraph.map((detailContentsItem) => {
                                        return (<p className="details_para" key={detailContentsItem.detailsParaId}>{detailContentsItem.detailsParaType}</p>)
                                    })}
                                    <div className="blog_marketing_wrap">
                                        <div className="tags">
                                            <strong>{detailContents.tags.title}:</strong>
                                            {detailContents.tags.tag.map((tagItem) => {
                                                return (
                                                    <Link className="tag_link" key={tagItem.tagId}>{tagItem.tagType}</Link>
                                                )
                                            })}
                                        </div>
                                        <div className="share_social">
                                            <strong>{detailContents.socialMedia.title}: </strong>
                                            <ul className="share_social_ul">
                                                <li className="social_li"><Link className="social_a"><FaFacebookF /></Link></li>
                                                <li className="social_li"><Link className="social_a"><FaTwitter /></Link></li>
                                                <li className="social_li"><Link className="social_a"><FaLinkedinIn /></Link></li>
                                                <li className="social_li"><Link className="social_a"><FaInstagramSquare /></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="widget">
                                <div className="single_widget widget_search">
                                    <h4 className="widget_title">{detailContents.search.title}</h4>
                                    <Form className="search_form">
                                        <Form.Group className="mb-3">
                                            <Form.Control type="text" placeholder="search" />
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div className="single_widget widget_categories">
                                    <h4 className="widget_title">{detailContents.category.title}</h4>
                                    <ul className="categories_ul">
                                        <li className="categories_li">
                                            {detailContents.category.categories.map((categorieItem) => {
                                                return (
                                                    <Link className="categories_name" key={categorieItem.categoriesId}>{categorieItem.categoriesType}<span>{categorieItem.categoriesValue}</span></Link>
                                                )
                                            })}
                                        </li>
                                    </ul>
                                </div>
                                <div className="single_widget widget_recent">
                                    <h4 className="widget_title">{detailContents.recentPost.title}</h4>
                                    <ul className="related_post">
                                        {detailContents.recentPost.posts.map((postsItem) => {
                                            return (
                                                <li className="single_related" key={postsItem.postId}>
                                                    <div className="single_related_image">
                                                        <Link className="link_img">
                                                            <Image src={postsItem.postImage} alt="" />
                                                        </Link>
                                                    </div>
                                                    <div className="single_related_contents">
                                                        <Link className="single_title">{postsItem.postTitle}</Link>
                                                        <p className="single_date">{postsItem.postDate}</p>
                                                        <p className="single_para">{postsItem.postPara}</p>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default BlogDetailSection;
