import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreator } from "../../state/index";
import { authInitialValue } from "../../helper/models/authentication"
import { IsExistingNumberSchema, RegisterSchema, ValidateOtpAndLoginSchema, ValidatePasswordAndLoginSchema } from '../../helper/validations/authentication-schema';
import { useFormik } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "../../styles/authentication/login.scss";
import { CredentialLogin, IsExistingNumber, Register, SentOtp, ValidateOtpAndLogin } from '../../services/api/authentication';
import { toast } from 'react-toastify';
import { toastStyle } from '../../utils/http-requests';
import { successMessages } from '../../helper/api-message/api-success';
import appTheme from "../../../app-theme.json";
import { Link, useNavigate, Navigate } from 'react-router-dom';
import RouteLinks from "../../utils/route";
import { ServerConfig } from '../../services/api/server-config';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

let chekAuth = true;
const Authentication = () => {

    const defaultAuthState = { existNumber: true, registerUser: false, validateOtp: false, resendOtp: false }
    const [auth, setAuth] = useState(defaultAuthState);
    const [showBottomLink, setShowBottomLink] = useState(true);
    const [isExistNumberError, setIsExistNumberError] = useState(false);
    const [loginOTPMinutes, setLoginOTPMinutes] = useState(0);
    const [loginOTPSeconds, setLoginOTPSeconds] = useState(0);
    const [otpPasswordTabKey, setOtpPasswordTabKey] = useState(1);
    const [isEye, setIsEye] = useState(true);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { isAuthenticated, isLoader, hideFooter } = bindActionCreators(actionCreator, dispatch);
    const showLoader = useSelector((state) => state.isLoader);
    const authentication = useSelector((state) => state.isAuthenticated);
    let recentPathNavigate = useSelector((state) => state.recentPath);

    let validationSchema = IsExistingNumberSchema;
    if (auth.existNumber) { validationSchema = IsExistingNumberSchema; }
    if (auth.registerUser) { validationSchema = RegisterSchema; }
    if (auth.resendOtp) { validationSchema = IsExistingNumberSchema; }
    if (auth.validateOtp) {
        if (parseInt(otpPasswordTabKey) === 1) { validationSchema = ValidateOtpAndLoginSchema; }
        else if (parseInt(otpPasswordTabKey) === 2) { validationSchema = ValidatePasswordAndLoginSchema; }
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm, setValues } = useFormik({
        initialValues: authInitialValue,
        validationSchema: validationSchema,
        onSubmit: async (values, action) => {
            if (values) {
                const countryCode = values.country.dialCode;
                const mobile = values.mobile.slice(countryCode.length);
                let payload = {
                    contactNumber: mobile, countryCode: `+${countryCode}`, requiredWhatsAppNotification: false
                };
                const getServerConfig = async () => {
                    const responseServerConfig = await ServerConfig();
                    if (responseServerConfig != null) {
                        if (responseServerConfig.data) { setLoginOTPMinutes(responseServerConfig.data[0].value); }
                    }
                }

                const sentOtp = async () => {
                    const responseOtp = await SentOtp(payload, isLoader);
                    if (responseOtp != null) {
                        const { data, errorMessages } = responseOtp;
                        toast.success(successMessages.otpSent, toastStyle);
                        if (data) {
                            getServerConfig();
                            setAuth({ ...auth, validateOtp: true, existNumber: false, resendOtp: false });
                            setValues({ ...values, otp: "", password: "" });
                        }
                        else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle) }); }
                    }
                }

                if (auth.resendOtp) { sentOtp(); }

                if (auth.existNumber) {
                    const responseExistNumber = await IsExistingNumber(payload, isLoader);
                    if (responseExistNumber != null) {
                        if (responseExistNumber.data) {
                            sentOtp();
                            setAuth({ ...auth, existNumber: false, });
                            setIsExistNumberError(false);
                        }
                        else { setIsExistNumberError(true); }
                    }
                }

                if (auth.registerUser) {
                    const payload = {
                        contactNumber: mobile,
                        countryCode: `+${countryCode}`,
                        email: values.email,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        password: values.password,
                        requiredWhatsAppNotification: false
                    };
                    const response = await Register(payload, isLoader);
                    if (response != null) {
                        const { errorMessages, isSuccess, data, successMessage } = response;
                        if (!isSuccess) { errorMessages.forEach(error => { toast.error(error.message, toastStyle); }); }
                        else if (data.token) {
                            isAuthenticated(true);
                            navigate(recentPathNavigate);
                            chekAuth = false;
                            toast.success(successMessage, toastStyle);
                            localStorage.setItem("token", data.token);
                        }
                    }
                }

                if (auth.validateOtp) {
                    if (parseInt(otpPasswordTabKey) === 1) {
                        payload = {
                            contactNumber: mobile,
                            countryCode: `+${countryCode}`,
                            otp: values.otp,
                            requiredWhatsAppNotification: false
                        }
                        const validateOtpResponse = await ValidateOtpAndLogin(payload, isLoader);
                        if (validateOtpResponse != null) {
                            const { successMessage, isSuccess, errorMessages, data } = validateOtpResponse;
                            if (!isSuccess) { errorMessages.forEach((error) => { toast.error(error.message, toastStyle) }); }
                            else {
                                isAuthenticated(true);
                                localStorage.setItem("token", data.token);
                                navigate(recentPathNavigate);
                                chekAuth = false;

                            }
                        }
                    }
                    if (parseInt(otpPasswordTabKey) === 2) {
                        payload = { contactNumber: mobile, countryCode: `+${countryCode}`, password: values.password }
                        const credentialResponse = await CredentialLogin(payload, isLoader);
                        if (credentialResponse != null) {
                            const { isSuccess, successMessage, data, errorMessages } = credentialResponse;
                            if (!isSuccess) { errorMessages.forEach((error) => { toast.error(error.message, toastStyle) }); isLoader(false); }
                            else {
                                isAuthenticated(true);
                                localStorage.setItem("token", data.token);
                                navigate(recentPathNavigate);
                                chekAuth = false;

                            }
                        }
                    }
                }

            }
        }
    });

    useEffect(() => {
        hideFooter(true);
        return () => { hideFooter(false); }
    }, []);

    useEffect(() => {
        if (auth.resendOtp) { handleSubmit(); }
    }, [auth.resendOtp]);

    const handleChangeNumber = () => {
        setAuth({ ...auth, validateOtp: false, existNumber: true });
        setLoginOTPMinutes(0);
        setLoginOTPSeconds(0);
        setOtpPasswordTabKey(1);
    }

    const handleResendOtp = () => {
        setAuth({ ...auth, validateOtp: false, resendOtp: true });
    }

    const handleRegister = () => {
        setAuth({ ...auth, existNumber: false, validateOtp: false, registerUser: true, });
        setShowBottomLink(false);
        resetForm();
    }

    const handleLogin = () => {
        setAuth({ ...auth, existNumber: true, registerUser: false, validateOtp: false, });
        setShowBottomLink(true);
        resetForm();
    }

    const handleEye = () => {
        setIsEye(!isEye);
    }

    const handleOtpPasswordTab = (e) => {
        setOtpPasswordTabKey(e.target.value);
    }

    const handlePhoneNumber = (mobile, country) => {
        setValues({ ...values, mobile, country });
        setIsExistNumberError(false);
    }

    useEffect(() => {
        let OTPCounter = setInterval(() => {
            if (loginOTPSeconds > 0) { setLoginOTPSeconds(loginOTPSeconds - 1); }
            if (loginOTPSeconds === 0) {
                if (loginOTPMinutes === 0) { clearInterval(OTPCounter) }
                else { setLoginOTPMinutes(loginOTPMinutes - 1); setLoginOTPSeconds(59); }
            }
        }, 1000)
        return () => {
            clearInterval(OTPCounter);
        };
    }, [loginOTPMinutes, loginOTPSeconds]);


    const changeNumberInput =
        <>
            <h4 className="card_title">{appTheme.authentication.login.title}</h4>
            <Form.Group className="form_group">
                <PhoneInput
                    placeholder={'Enter Mobile Number'}
                    inputProps={{ name: 'mobile' }}
                    value={values.mobile}
                    country={'in'}
                    countryCodeEditable={false}
                    onChange={(mobile, country) => handlePhoneNumber(mobile, country)}
                />
                {errors.mobile && touched.mobile ? (<p className="form_error">{errors.mobile}</p>) : null}
                {isExistNumberError && <p className="form_error">{appTheme.authentication.login.notRegitr}</p>}
            </Form.Group>
        </>

    let otpPasswordContent = null;
    if (parseInt(otpPasswordTabKey) === 1) {
        otpPasswordContent =
            <Form.Group className="form_group">
                <Form.Label>
                    {`+${values.mobile}`}
                    (<span className="change_mobile" onClick={handleChangeNumber}>{appTheme.authentication.login.changeNumber}</span>)
                    {loginOTPMinutes === 0 && loginOTPSeconds === 0 ?
                        <>(<span className="resend_otp" onClick={handleResendOtp}>{appTheme.authentication.login.resendOtp}</span>)</>
                        : <>(<span className="resend_otp otp_counter">{loginOTPMinutes}:{loginOTPSeconds < 10 ? `0${loginOTPSeconds}` : loginOTPSeconds}</span>)</>
                    }
                </Form.Label>
                <Form.Control type="text" maxLength={6} name="otp" value={values.otp} onChange={handleChange} onBlur={handleBlur} />
                {errors.otp && touched.otp ? (<p className="form_error">{errors.otp}</p>) : null}
            </Form.Group>
    }
    else if (parseInt(otpPasswordTabKey) === 2) {
        otpPasswordContent =
            <Form.Group className="form_group">
                <Form.Label>
                    {`+${values.mobile}`}
                    (<span className="change_mobile" onClick={handleChangeNumber}>{appTheme.authentication.login.changeNumber}</span>)
                </Form.Label>
                <Form.Control type={isEye ? "password" : "text"} maxLength={13} name="password" value={values.password} onChange={handleChange} onBlur={handleBlur} />
                <span className='eye_box' onClick={handleEye}>{isEye ? <FaEye /> : <FaEyeSlash />} </span>
                {errors.password && touched.password ? (<p className="form_error">{errors.password}</p>) : null}
            </Form.Group>
    }


    const validateOtpContent =

        <>
            <h4 className="card_title">{parseInt(otpPasswordTabKey) === 1 ? appTheme.authentication.login.otpTitle : appTheme.authentication.login.passwordTitle}</h4>
            <Form.Group className="form_group otp_password_tab">
                <Form.Check inline value="1" checked={parseInt(otpPasswordTabKey) === 1 ? true : false} label="OTP" name="login" type="radio" id="OTP" onChange={handleOtpPasswordTab} />
                <Form.Check inline value="2" label="Password" name="login" type="radio" id="Password" onChange={handleOtpPasswordTab} />
            </Form.Group>

            {otpPasswordContent}

        </>

    const registerContent =
        <>
            <h4 className="card_title">{appTheme.authentication.registerTitle}</h4>
            <Row>
                <Col xs={12} className="mb-1">
                    <Form.Group className="form_group">
                        <PhoneInput
                            placeholder={'Enter Mobile Number'}
                            inputProps={{ name: 'mobile' }}
                            value={values.mobile}
                            country={'in'}
                            countryCodeEditable={false}
                            onChange={(mobile, country) => setValues({ ...values, mobile, country })}
                        />
                        {errors.mobile && touched.mobile ? (<p className="form_error">{errors.mobile}</p>) : null}
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group className="form_group">
                        <Form.Label>{appTheme.authentication.firstName.label}</Form.Label>
                        <Form.Control type="text" name="firstName" value={values.firstName} onChange={handleChange} onBlur={handleBlur} />
                        {errors.firstName && touched.firstName ? (<p className="form_error">{errors.firstName}</p>) : null}
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group className="form_group">
                        <Form.Label>{appTheme.authentication.lastName.label}</Form.Label>
                        <Form.Control type="text" name="lastName" value={values.lastName} onChange={handleChange} onBlur={handleBlur} />
                        {errors.lastName && touched.lastName ? (<p className="form_error">{errors.lastName}</p>) : null}
                    </Form.Group>
                </Col>
                <Col xs={12}>
                    <Form.Group className="form_group">
                        <Form.Label>{appTheme.authentication.email.label}</Form.Label>
                        <Form.Control autoComplete="email" type="email" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} />
                        {errors.email && touched.email ? (<p className="form_error">{errors.email}</p>) : null}
                    </Form.Group>
                </Col>
                <Col xs={12}>
                    <Form.Group className="form_group">
                        <Form.Label>{appTheme.authentication.password.label} <span className='min_char'>(Min 13 characters)</span></Form.Label>
                        <div className="">
                            <Form.Control autoComplete="new-password" type={isEye ? "password" : "text"} maxLength={13} name="password" value={values.password} onChange={handleChange} onBlur={handleBlur} />
                            <span className='eye_box' onClick={handleEye}>{isEye ? <FaEye /> : <FaEyeSlash />} </span>
                        </div>
                        {errors.password && touched.password ? (<p className="form_error">{errors.password}</p>) : null}
                    </Form.Group>
                </Col>
            </Row>
        </>

    if (authentication && chekAuth) { return <Navigate to={RouteLinks.Home} replace />; }

    return (
        <>
            <div className="auth_container">
                <div className="custom_card">
                    <div className="card_body">
                        <Form className='form_box' autoComplete="off">
                            {
                                <div className="">

                                    {auth.existNumber && changeNumberInput}

                                    {auth.validateOtp && validateOtpContent}

                                    {auth.registerUser && registerContent}

                                    <Row className="submit_row">
                                        <Col className="text-center" md="12">
                                            <Button size="sm" variant="primary" className="submit_login" onClick={handleSubmit} disabled={showLoader}>
                                                {showLoader ? <Spinner animation="border" /> : <>{appTheme.authentication.button}</>}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            }

                        </Form>

                        <div className="bottom_box">
                            {showBottomLink ? <>
                                <p className='bottom_text'>{appTheme.authentication.bottomLink.label1}</p>
                                <Link className='bottom_link' onClick={handleRegister}>{appTheme.authentication.bottomLink.link1}</Link>
                            </>

                                : <>
                                    <p className='bottom_text'>{appTheme.authentication.bottomLink.label2}</p>
                                    <Link className='bottom_link' onClick={handleLogin}>{appTheme.authentication.bottomLink.link2}</Link>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Authentication;
