import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../state';
import { Publish, MarkDraft, MarkActive, MarkInactive } from '../../../services/api/residential-rent';
import isTokenExpired from '../../../utils/jwt-auth';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RouteLinks from '../../../utils/route';
import appTheme from "../../../../app-theme.json";


const StatusConfirmation = ({ propertyListingData, buttonValue }) => {

    const [buttonText, setButtonText] = useState("");

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { confirmAndStatus, isLoader } = bindActionCreators(actionCreator, dispatch);

    const showModal = useSelector((state) => state.confirmAndStatus);
    const showLoader = useSelector((state) => state.isLoader);

    const hideModal = () => { confirmAndStatus(false); }

    const { confirmationModel } = appTheme.publishStatusPage;

    useEffect(() => {
        switch (buttonValue) {
            case 1: setButtonText(confirmationModel.publish);
                break;
            case 2: setButtonText(confirmationModel.inactive);
                break;
            case 3: setButtonText(confirmationModel.convertToDraft);
                break;
            case 4: setButtonText(confirmationModel.inactive);
                break;
            case 5: setButtonText(confirmationModel.activeAndPublish);
                break;
            case 6: setButtonText(confirmationModel.activeAndDraft);
                break;
            default:
                setButtonText("");
                break;
        }
    }, [buttonValue]);

    const _Publish = async (propertyId) => {
        const response = await Publish(propertyId, isLoader, isTokenExpired);
        if (response != null) { if (response.isSuccess) { hideModal(); navigate(RouteLinks.PropertyListing); } }
    }

    const _MarkDraft = async (propertyId) => {
        const response = await MarkDraft(propertyId, isLoader, isTokenExpired);
        if (response != null) { if (response.isSuccess) { hideModal(); navigate(RouteLinks.PropertyListing); } }
    }

    const _MarkActive = async (propertyId) => {
        const response = await MarkActive(propertyId, isLoader, isTokenExpired);
        if (response != null) { if (response.isSuccess) { hideModal(); navigate(RouteLinks.PropertyListing); } }
    }

    const _MarkInactive = async (propertyId) => {
        const response = await MarkInactive(propertyId, isLoader, isTokenExpired);
        if (response != null) { if (response.isSuccess) { hideModal(); navigate(RouteLinks.PropertyListing); } }
    }

    const confirmHandler = () => {
        switch (buttonValue) {
            case 1: _Publish(propertyListingData.propertyListingId);
                break;
            case 2: _MarkInactive(propertyListingData.propertyListingId);
                break;
            case 3: _MarkDraft(propertyListingData.propertyListingId);
                break;
            case 4: _MarkInactive(propertyListingData.propertyListingId);
                break;
            case 5: _MarkActive(propertyListingData.propertyListingId); _Publish(propertyListingData.propertyListingId);
                break;
            case 6: _MarkActive(propertyListingData.propertyListingId); _MarkDraft(propertyListingData.propertyListingId);
                break;
            default:
                break;
        }
    }


    return (
        <Modal show={showModal} onHide={hideModal} className='delete_image_modal' centered>
            <Modal.Header closeButton>
                <h4 className="title">{buttonText}</h4>
            </Modal.Header>
            <Modal.Body>{confirmationModel.message} {buttonText}</Modal.Body>
            <Modal.Footer>
                <Button size='sm' variant="danger" onClick={hideModal}>{confirmationModel.cancelButton}</Button>
                <Button size='sm' variant="success" onClick={confirmHandler}>
                    {showLoader ? <Spinner animation="border" className='spinner_sm' /> : <>{confirmationModel.okButton}</>}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default StatusConfirmation;
