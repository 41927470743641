
export const initialAmenities = {
    numberOfBathrooms: null,
    numberOfBalcony: null,
    waterSupplies: null,
    heavingGym: false,
    isNonVegAllowed: false,
    havingGatedSecurity: false,
    propertyShownBy: null,
    secondaryNumber: null,
    directionsTips: null,
    availableAmenities: [],
};
