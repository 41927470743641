import { Button, Col, Form, Row } from "react-bootstrap";
import { FaSistrix } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import RouteLinks from "../../utils/route";
import { useJsApiLoader, StandaloneSearchBox } from '@react-google-maps/api';
import { useRef, useState } from "react";
import googleApiKey from "../../../google-api-key.json";
import { FaCaretDown, FaTimes } from "react-icons/fa";
import { useFormik } from 'formik';
import { searchProperty } from "../../helper/home/search-property";
import { useEffect } from "react";
import { ApartmentTypes, BHKTypes, Cities } from "../../services/api/Lookups";
import { searchPropertySchema } from "../../helper/home/search-property-schema";
import appTheme from "../../../app-theme.json";
import { toast } from "react-toastify";
import { searchErrroMessage, searchLocalityMessage, toastStyle } from "../../utils/http-requests";
import { searchCity } from "../../utils/google-maps";

let findCity = "";
const RentFilter = () => {

    const [libraries] = useState(["places"]);

    const [searchLocation, setSearchLocation] = useState();
    const [localityText, setLocalityText] = useState("");
    const [cityCheck, setCityCheck] = useState(true);
    const [propertyTypeCheck, setPropertyTypeCheck] = useState(true);
    const [bhkCheck, setBhkCheck] = useState(true);
    const [cities, setCities] = useState([]);
    const [bhkType, setBhkType] = useState([]);
    const [propertyType, setPropertyType] = useState([]);

    const searchPageNavigate = useNavigate();

    const { values, handleChange, handleBlur, errors, touched, handleSubmit, setValues } = useFormik({
        initialValues: searchProperty,
        validationSchema: searchPropertySchema,
        onSubmit: (values) => {
            searchPageNavigate(RouteLinks.PropertySearch, { state: values });
        },
    });

    const { isLoaded } = useJsApiLoader({ googleMapsApiKey: googleApiKey.key, libraries });

    const onLoadHandler = (ref) => {
        setSearchLocation(ref);
    }

    const handlePlaceChanged = () => {
        const [place] = searchLocation.getPlaces();
        if (place) {
            const filteredCity = place.formatted_address.search(findCity);
            if (filteredCity >= 0) {
                if (!findCity) { setLocalityText(""); toast.warning(searchErrroMessage.cityText, toastStyle); }
                else {
                    setValues({
                        ...values,
                        localityAddress: {
                            name: place.formatted_address,
                            latitude: place.geometry.location.lat(),
                            langitude: place.geometry.location.lng()
                        }
                    });
                    setLocalityText(place.formatted_address);
                }
            }
            else { setLocalityText(""); toast.warning(`${searchErrroMessage.localityText} ${findCity}`, toastStyle); };

        }
    }

    const cityChangeHandler = (e) => {
        setValues({
            ...values,
            city: e.target.value,
            localityAddress: ""
        });
        setLocalityText("");
    }

    const clearLocalityHandler = () => {
        setValues({
            ...values,
            localityAddress: ""
        });
        setLocalityText("");
    }

    useEffect(() => { findCity = searchCity(values.city); }, [values.city]);

    const getCities = async () => {
        const response = await Cities();
        if (response != null) { if (response.isSuccess) { setCities(response.data); } }
    }

    const getBHKTypes = async () => {
        const response = await BHKTypes();
        if (response != null) { if (response.isSuccess) { setBhkType(response.data); } }
    }

    const getPropertyTypes = async () => {
        const response = await ApartmentTypes();
        if (response != null) { if (response.isSuccess) { setPropertyType(response.data); } }
    }

    useEffect(() => {
        getCities();
        getPropertyTypes();
        getBHKTypes();
    }, []);

    const cityCheckClass = cityCheck ? "check_icon" : "check_icon move_icon";
    const propertyCheckClass = propertyTypeCheck ? "check_icon" : "check_icon move_icon";
    const bhkCheckClass = bhkCheck ? "check_icon" : "check_icon move_icon";

    return <>
        <div className="filter_container">
            <Row className="gx-3 filter_row">
                <Col lg={2} className="mb-3 mb-lg-0 position-relative">
                    <span className={`${cityCheckClass}`}> <FaCaretDown /></span>
                    <Form.Select aria-label="Default select example" className={`form_select ${touched.city && errors.city && "error_input"}`} name="city" onClick={() => setCityCheck(!cityCheck)} onChange={cityChangeHandler} onBlur={handleBlur}>
                        <option className="opt">{appTheme.searchForm.city}</option>
                        {cities.map((item) => { return (<option className="opt" key={item.citiesLookupId} value={item.citiesLookupId}>{item.name}</option>) })}
                    </Form.Select>
                </Col>
                <Col lg={4} className="mb-3 mb-lg-0 standalone_search_col">
                    {
                        isLoaded &&
                        <StandaloneSearchBox className="input" onLoad={onLoadHandler} onPlacesChanged={handlePlaceChanged}>
                            <Form.Control type="text" placeholder="Search localities" className={`search_input ${touched.localityAddress && errors.localityAddress ? "place_holder" : ""}`} name="localityAddress" onBlur={handleBlur} value={localityText} onChange={(e) => setLocalityText(e.target.value)} />
                        </StandaloneSearchBox>
                    }
                    {localityText ? <div className="clear_locality"><FaTimes onClick={clearLocalityHandler} /></div> : null}
                </Col>
                <Col lg={3} className="mb-3 mb-lg-0 position-relative">
                    <Form.Select aria-label="Default select example" className={`form_select ${touched.propertyType && errors.propertyType && "error_input"}`} name="propertyType" onClick={() => setPropertyTypeCheck(!propertyTypeCheck)} onChange={handleChange} onBlur={handleBlur}>
                        <option value="">{appTheme.searchForm.propertyType}</option>
                        {propertyType.map((item) => { return (<option key={item.apartmentTypeId} value={item.apartmentTypeId}>{item.type}</option>) })}
                    </Form.Select>
                    <span className={`${propertyCheckClass}`}> <FaCaretDown /></span>
                </Col>
                <Col lg={2} className="mb-3 mb-lg-0 position-relative">
                    <Form.Select aria-label="Default select example" className={`form_select ${touched.bhkType && errors.bhkType && "error_input"}`} name="bhkType" onClick={() => setBhkCheck(!bhkCheck)} onChange={handleChange} onBlur={handleBlur}>
                        <option value="">{appTheme.searchForm.bhkType}</option>
                        {bhkType.map((item) => { return (<option key={item.bhkTypeId} value={item.bhkTypeId}>{item.type}</option>) })}
                    </Form.Select>
                    <span className={`${bhkCheckClass}`}> <FaCaretDown /></span>
                </Col>
                <Col lg={1} className=" h-100 position-relative">
                    <Button variant="primary" className="search_btn" onClick={handleSubmit}><FaSistrix className="search_icon" /></Button>
                </Col>
            </Row>
        </div>
    </>
};
export default RentFilter;