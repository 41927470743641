import axios from 'axios';
import AppSetting from '../../../appConfig.json';
import { errorHandler } from '../validation/error-handler';
import { httpHeaders } from '../../utils/http-requests';

export const GetResidentialRentProperty = async (payload) => {
    try {
        const url = AppSetting.SERVER_URL + "/Properties/GetResidentialRentProperty";
        const res = await axios.post(url, payload, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}