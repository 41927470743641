import jwtDecode from 'jwt-decode';

const isTokenExpired = () => {
  if (localStorage.getItem('token')) {
    const decodeToken = jwtDecode(localStorage.getItem('token'));
    const currentDate = new Date();
    if (decodeToken.exp * 1000 < currentDate.getTime()) {
      localStorage.removeItem('token');
      return true;
    }
    return false;
  }
  return true;
}

export default isTokenExpired;