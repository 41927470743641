
export const searchCity = (key) => {
    switch (parseInt(key)) {
        case 1:
            return "Bengaluru";
        case 2:
            return "Pune";
        case 3:
            return "Gurugram";
        case 4:
            return "Mumbai";
        case 5:
            return "Chennai";
        case 6:
            return "Hyderabad";
        case 7:
            return "Delhi";
        case 8:
            return "Ghaziabad";
        case 9:
            return "Greater Noida";
        case 10:
            return "Faridabad";
        case 11:
            return "Noida";
        default:
            return "";
    }
}

