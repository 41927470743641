import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaBed, FaBuilding, FaParking, FaHouseUser, FaUserAlt, FaWater, FaBath, FaStore, FaClock, FaExpandArrowsAlt, FaCrosshairs } from "react-icons/fa";
import amenitiesLoockups from '../../../../utils/loockups/amenities-loockups';
import PropertyDetailMap from '../../../../components/common/property-details-map';
import appTheme from "../../../../../app-theme.json";

let myAmenities = [];

export const Scrollpy = (props) => {

    const [furnishingType, setFurnishingType] = useState("");
    const [parkingType, setParkingType] = useState("");
    const [waterSupply, setWaterSupply] = useState("");
    const [propertyShownBy, setPropertyShownBy] = useState("");
    const [latLng, setLatLng] = useState(null);

    const { bhkType, apartmentType, preferredTenant, propertyAge, propertyFacing } = props.items;
    const { builtUpArea, totalFloorId, floorId, latitudeAndLongitude } = props.items.propertyDetails;
    const { description, furnishingTypeId, parkingTypeId } = props.items.newRentaldetail;
    const { numberOfBalcony, numberOfBathrooms, waterSupplyId, propertyShownById, amenities } = props.items.newAmenities;

    const allLookups = JSON.parse(localStorage.getItem("lookups"));

    let bothWaterSupplies = "Corporation and Bore Well";
    let bothParking = "Bike and Car";


    useEffect(() => {
        if (allLookups) {
            allLookups.furnishingTypes.forEach((item) => {
                if (item.furnishingTypeId === furnishingTypeId)
                    setFurnishingType(item.type);
            });
            allLookups.parkingTypes.forEach((item) => {
                if (item.parkingTypeId === parkingTypeId)
                    setParkingType(item.type);
            });
            allLookups.waterSupplies.forEach((item) => {
                if (item.waterSupplyId === waterSupplyId)
                    setWaterSupply(item.type);
            });
            allLookups.propertyShownBy.forEach((item) => {
                if (item.propertyShownById === propertyShownById)
                    setPropertyShownBy(item.person);
            });
            myAmenities = [];
            if (amenities) {
                allLookups.amenities.forEach((item) => {
                    amenities.forEach((amenitie) => {
                        if (item.amenitiesId === amenitie.amenitiesId)
                            amenitiesLoockups(amenitie.amenitiesId, item, myAmenities);
                    })
                });
            }
        }
    }, [props.items.newRentaldetail]);

    useEffect(() => {
        if (latitudeAndLongitude) { setLatLng(latitudeAndLongitude.split(",")); }
    }, [latitudeAndLongitude]);

    const { propertySearchDetails, propertyDetails, rentalDetails, amenitiesDetails } = appTheme;


    return (
        <Container fluid className='scroll_content'>
            <Row className='pt-4'>
                <Col>
                    <div id="description">
                        <h2 className="content_heading" >{propertySearchDetails.propertyDetails.discription}</h2>
                        <div className="content_container">
                            <p className='content_para'>{description}</p>
                        </div>
                    </div>
                    <div id="propertyoverview">
                        <h2 className="content_heading">{propertySearchDetails.propertyDetails.overview}</h2>
                        <div className="content_container">
                            <Row className="property_description">
                                <Col xs={6} md={4} className="overview_col">
                                    <div className="overview_icon"><FaBed /></div>
                                    <div className="overview_value">
                                        <p className='label'>{propertySearchDetails.propertyDetails.bedroom}</p>
                                        <p className='value'>{bhkType}</p>
                                    </div>
                                </Col>
                                <Col xs={6} md={4} className="overview_col">
                                    <div className="overview_icon"><FaBuilding /></div>
                                    <div className="overview_value">
                                        <p className='label'>{propertyDetails.appartmentType}</p>
                                        <p className='value'>{apartmentType}</p>
                                    </div>
                                </Col>
                                <Col xs={6} md={4} className="overview_col">
                                    <div className="overview_icon"><FaHouseUser /></div>
                                    <div className="overview_value">
                                        <p className='label'>{rentalDetails.preferredTenants}</p>
                                        <p className='value'>{preferredTenant}</p>
                                    </div>
                                </Col>
                                <Col xs={6} md={4} className="overview_col">
                                    <div className="overview_icon"><FaBath /></div>
                                    <div className="overview_value">
                                        <p className='label'>{propertySearchDetails.propertyDetails.bathroom}</p>
                                        <p className='value'>{numberOfBathrooms} Bathroom</p>
                                    </div>
                                </Col>
                                <Col xs={6} md={4} className="overview_col">
                                    <div className="overview_icon"><FaStore /></div>
                                    <div className="overview_value">
                                        <p className='label'>{propertySearchDetails.propertyDetails.balcony}</p>
                                        <p className='value'>{numberOfBalcony} Balcony</p></div>
                                </Col>
                                <Col xs={6} md={4} className="overview_col">
                                    <div className="overview_icon"><FaClock /></div>
                                    <div className="overview_value">
                                        <p className='label'>{propertyDetails.propertyAge}</p>
                                        <p className='value'>{propertyAge}</p>
                                    </div>
                                </Col>
                                <Col xs={6} md={4} className="overview_col">
                                    <div className="overview_icon"><FaExpandArrowsAlt /></div>
                                    <div className="overview_value">
                                        <p className='label'>{propertyDetails.builtUpArea}</p>
                                        <p className='value'>{builtUpArea} sq ft</p>
                                    </div>
                                </Col>
                                <Col xs={6} md={4} className="overview_col">
                                    <div className="overview_icon"><FaCrosshairs /></div>
                                    <div className="overview_value">
                                        <p className='label'>{propertyDetails.facing}</p>
                                        <p className='value'>{propertyFacing}</p>
                                    </div>
                                </Col>
                                <Col xs={6} md={4} className="overview_col">
                                    <div className="overview_icon"><FaBuilding /></div>
                                    <div className="overview_value">
                                        <p className='label'>{propertyDetails.floor}</p>
                                        <p className='value'>{floorId} Floor</p>
                                    </div>
                                </Col>
                                <Col xs={6} md={4} className="overview_col">
                                    <div className="overview_icon"><FaBuilding /></div>
                                    <div className="overview_value">
                                        <p className='label'>{propertyDetails.totalFloor}</p>
                                        <p className='value'>{totalFloorId} Floor</p>
                                    </div>
                                </Col>
                                <Col xs={6} md={4} className="overview_col">
                                    <div className="overview_icon"><FaBed /></div>
                                    <div className="overview_value">
                                        <p className='label'>{rentalDetails.furnishing}</p>
                                        <p className='value'>{furnishingType}</p>
                                    </div>
                                </Col>
                                <Col xs={6} md={4} className="overview_col">
                                    <div className="overview_icon"><FaWater /></div>
                                    <div className="overview_value">
                                        <p className='label'>{amenitiesDetails.waterSpply}</p>
                                        <p className='value'>{`${waterSupply === "Both" ? bothWaterSupplies : waterSupply}`}</p>
                                    </div>
                                </Col>
                                <Col xs={6} md={4} className="overview_col">
                                    <div className="overview_icon"><FaUserAlt /></div>
                                    <div className="overview_value">
                                        <p className='label'>{amenitiesDetails.propertyShown}</p>
                                        <p className='value'>{propertyShownBy}</p>
                                    </div>
                                </Col>
                                <Col xs={6} md={4} className="overview_col">
                                    <div className="overview_icon"><FaParking /></div>
                                    <div className="overview_value">
                                        <p className='label'>{rentalDetails.parking}</p>
                                        <p className='value'>{`${parkingType === "Both" ? bothParking : parkingType}`}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div id="amenities">
                        <h2 className="content_heading">{propertySearchDetails.propertyDetails.amenities}</h2>
                        <div className="content_container">
                            <Row className="property_description">
                                {myAmenities.map((item, index) => {
                                    return (
                                        <Col xs={6} md={4} className="overview_col" key={index}>
                                            <div className="overview_amenites">
                                                <p className='amenites_icon'>{item.icons}</p>
                                                <p className='amenites_value'>{item.type}</p>
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </div>
                    </div>
                    <div id="map">
                        <h2 className="content_heading">{propertySearchDetails.propertyDetails.map}</h2>
                        <div className="content_container p-0">
                            {latLng && <PropertyDetailMap latLng={latLng} />}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
