import { httpHeadersWithToken } from '../../utils/http-requests';
import AppSetting from '../../../appConfig.json';
import axios from 'axios';
import { errorHandler } from '../validation/error-handler';

export const GetChat = async (payload, loadingState, isTokenExpired) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { return; }
    else {
        loadingState(true);
        try {
            const url = AppSetting.SERVER_URL + `/Chat?id=${payload}`;
            const res = await axios.get(url, httpHeadersWithToken());
            loadingState(false);
            return res.data;
        } catch (error) {
            loadingState(false);
            return errorHandler(error);
        }
    }
}

export const GetChatUsers = async (id, loadingState, isTokenExpired) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { return; }
    else {
        loadingState(true);
        try {
            id = id || null;
            const url = id == null ? AppSetting.SERVER_URL + "/Chat/GetChatUsers" : AppSetting.SERVER_URL + "/Chat/GetChatUsers?participantId=" + id;
            const res = await axios.get(url, httpHeadersWithToken());
            loadingState(false);
            return res.data;
        } catch (error) {
            loadingState(false);
            return errorHandler(error);
        }
    }
}

export const Send = async (payload, loadingState, isTokenExpired) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { return; }
    else {
        loadingState(true);
        try {
            const url = AppSetting.SERVER_URL + "/Chat/Send";
            const res = await axios.post(url, payload, httpHeadersWithToken());
            loadingState(false);
            return res.data;
        } catch (error) {
            loadingState(false);
            return errorHandler(error);
        }
    }
}

export const SendMedia = async (payload, loadingState, isTokenExpired) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { return; }
    else {
        loadingState(true);
        try {
            const url = AppSetting.SERVER_URL + "/Chat/SendMedia";
            const res = await axios.post(url, payload, httpHeadersWithToken());
            loadingState(false);
            return res.data;
        } catch (error) {
            loadingState(false);
            return errorHandler(error);
        }
    }
}
