import React, { useEffect, useState } from 'react';
import "../../../styles/property-details.scss";
import ScrollspyNav from "react-scrollspy-nav";
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../state';
import { GetById, GetNearProperties } from '../../../services/api/residential-rent';
import { GetAllLookups } from "../../../services/api/Lookups";
import { OwnerProfile, Scrollpy, PropertyOverview } from "./components";
import appTheme from "../../../../app-theme.json";

const PropertyDetails = () => {

    const propertyId = useParams();
    const [propertyDetails, setPropertyDetails] = useState([]);
    const [nearProperty, setNearProperty] = useState("");
    const [bhkType, setBhkType] = useState("");
    const [apartmentType, setApartmentType] = useState("");
    const [preferredTenant, setPreferredTenantId] = useState("");
    const [propertyAge, setPropertyAge] = useState("");
    const [propertyFacing, setPropertyFacing] = useState("");

    const { bhkTypeId, apartmentTypeId, propertyAgeId, propertyFacingId, rentalDetails, amenities, messageId } = propertyDetails;
    const newRentaldetail = rentalDetails ? rentalDetails : "";
    const newAmenities = amenities ? amenities : "";
    const { preferredTenantsId } = newRentaldetail;

    const dispatch = useDispatch();
    const { isLoading, hideFooter } = bindActionCreators(actionCreator, dispatch);

    const _GetById = async (payload) => {
        const response = await GetById(payload, isLoading);
        if (response != null) {
            if (response.isSuccess) { setPropertyDetails(response.data); }
        }
    }

    const _GetNearProperties = async (payload) => {
        const response = await GetNearProperties(payload);
        if (response != null) {
            if (response.isSuccess) { setNearProperty(response.data); }
        }
    }

    const allLookups = JSON.parse(localStorage.getItem("lookups"));
    const getAllLookups = async () => {
        const response = await GetAllLookups();
        if (response != null) { if (response.isSuccess) { localStorage.setItem("lookups", JSON.stringify(response.data)); } }
    }

    useEffect(() => {
        if (allLookups === null) { getAllLookups(); }
    }, [allLookups]);

    useEffect(() => {
        hideFooter(true);
        return () => { hideFooter(false); }
    }, []);

    useEffect(() => {
        _GetById(propertyId.id);
        _GetNearProperties(propertyId.id);
    }, [propertyId.id]);

    useEffect(() => {
        if (propertyDetails && allLookups) {
            allLookups.bhkTypes.forEach((item) => {
                if (item.bhkTypeId === bhkTypeId)
                    setBhkType(item.type);
            });
            allLookups.apartmentTypes.forEach((item) => {
                if (item.apartmentTypeId === apartmentTypeId)
                    setApartmentType(item.type);
            });
            allLookups.preferredTenants.forEach((item) => {
                if (item.preferredTenantsId === preferredTenantsId)
                    setPreferredTenantId(item.type);
            });
            allLookups.propertyAges.forEach((item) => {
                if (item.propertyAgeId === propertyAgeId)
                    setPropertyAge(item.ageRange);
            });
            allLookups.propertyFacing.forEach((item) => {
                if (item.propertyFacingId === propertyFacingId)
                    setPropertyFacing(item.facing);
            });
        }

    }, [propertyDetails, newRentaldetail, newAmenities]);

    if (!propertyDetails) { return }

    const { propertySearchDetails } = appTheme;

    return (
        <>
            <div className="property_detail_content">
                <div className="scroll_details">
                    <PropertyOverview items={{ propertyDetails, bhkType, apartmentType, propertyAge, propertyFacing }} />
                    <section className='scrollpy_section'>
                        {/* <div className='scrollpy_nav' id='scrollContent'>
                            <ScrollspyNav scrollTargetIds={["description", "propertyoverview", "amenities", "map"]}
                                offset={-80} activeNavClass="scroll_active" scrollDuration="500">
                                <div className='scroll_link_box'>
                                    <a className="scroll_link ms-0" href="#description" >{propertySearchDetails.propertyDetails.discription}</a>
                                    <a className="scroll_link" href="#propertyoverview">{propertySearchDetails.propertyDetails.overview}</a>
                                    <a className="scroll_link" href="#amenities">{propertySearchDetails.propertyDetails.amenities}</a>
                                    <a className="scroll_link" href="#map">{propertySearchDetails.propertyDetails.map}</a>
                                </div>
                            </ScrollspyNav> 
                        </div> */}

                        <Scrollpy items={{ propertyDetails, newRentaldetail, newAmenities, bhkType, apartmentType, preferredTenant, propertyAge, propertyFacing }} />

                    </section>

                </div>
                <OwnerProfile nearProperty={nearProperty} propertyId={propertyId} messageId={messageId} />
            </div>
        </>
    )
}

export default PropertyDetails
