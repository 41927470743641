import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { actionCreator } from "../../../state/index";
import Nav from 'react-bootstrap/Nav';
import RouteLinks from "../../../utils/route";
import { Link, useNavigate } from "react-router-dom";
import appTheme from "../../../../app-theme.json";

const AppNavBeforLogin = ({ pathName }) => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { recentPath } = bindActionCreators(actionCreator, dispatch);

  const navigateHandler = () => {
    recentPath(`${RouteLinks.AddProperty}${RouteLinks.PropertyLocality}`);
    navigate(RouteLinks.Login);
  }

  const loginHandler = () => {
    recentPath(RouteLinks.Home);
    navigate(RouteLinks.Login);
  }

  return (
    <>
      {
        pathName === "/account" ? "" : <Nav.Link className="login_btn" onClick={loginHandler}> {appTheme.login.title}</Nav.Link>
      }

      <Nav.Link className="post_btn" onClick={navigateHandler}>{appTheme.postProperty.title}</Nav.Link>
    </>
  );
};

export default AppNavBeforLogin;
