import { Col } from 'react-bootstrap';
import { Oval } from 'react-loader-spinner';
import { useSelector } from "react-redux";

const AppLoader = () => {

    const show = useSelector((state) => state.isLoading);

    return <>
        {show ?
            <Col className='app-loader'>
                <Oval
                    height={35}
                    width={35}
                    visible={true}
                    ariaLabel='oval-loading'
                    strokeWidth={4}
                />
            </Col> : null
        }
    </>
}

export default AppLoader;