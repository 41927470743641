
import { toast } from "react-toastify";
import { toastStyle } from "../../utils/http-requests";
import { errorMessages } from "../../helper/api-message/api-error";

export const errorHandler = (err) => {

    if (err.response) {
        if (err.response.status === 401 || err.response.status === 403)
            // toast.error(errorMessages.TokenError, toastStyle);
            return;

        else if (err.response.status === 404) toast.error(errorMessages.NotFound, toastStyle);

        else toast.error(errorMessages.ServerError, toastStyle);
    }

    else if (err.request) {
        // toast.error(errorMessages.Failed, toastStyle); 
        return;
    }

    else { toast.error(err.message, toastStyle); }
}