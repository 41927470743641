
export const initialRentalDetails = {
    propertyAvailabilities: 1,
    expectedRent: "",
    expectedDeposit: "",
    expectedLeaseAmount: 1000,
    isRentNegotiable: true,
    monthlyMaintenance: "",
    maintenanceAmount: "",
    availableFrom: "",
    preferredTenants: "",
    furnishingType: "",
    parkingType: "",
    discription: ""
};
