import React from 'react';
import AmenitiesComp from '../../components/property-listing/Amenities';

export const AmenitiesTab = () => {
    return (
        <>
            <AmenitiesComp />
        </>
    )
}

