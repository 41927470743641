import axios from 'axios';
import AppSetting from '../../../appConfig.json';
import { errorHandler } from '../validation/error-handler';
import { httpHeaders } from '../../utils/http-requests';

export const ServerConfig = async () => {
    try {
        const url = AppSetting.SERVER_URL + "/ServerConfig";
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}