import React from 'react';
import "../../../../styles/property/property-offcanvas.scss";
import { Offcanvas } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import appTheme from "../../../../../app-theme.json";
import moment from 'moment/moment';



const AllTenantHistory = ({ allTenantHistory, handleClose }) => {

    const { showAllTenant, allTenantDetail } = allTenantHistory;

    if (!allTenantDetail) {
        return
    }

    const { allTenant } = appTheme.myListingPage.property.propertyTenant;

    return (
        <div className="property_offcanvas">
            <Offcanvas show={showAllTenant} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{allTenant.title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {
                        allTenantDetail.map((allItem) => {
                            return (
                                <div className="current_tenant_info mb-3" key={allItem.propertyListingId}>
                                    <div className="tenant_profile">
                                        <div className="current_tenant_image">
                                            <Image src={`${allItem.profilePicture ? allItem.profilePicture : appTheme.userDummyImage}`} />
                                        </div>
                                    </div>
                                    <div className="current_tenant">
                                        <div className="current_tenant_detail">
                                            <span className="tenant_label">{allTenant.firstName}</span>
                                            <span className="tenant_value">{allItem.firstName}</span>
                                        </div>
                                        <div className="current_tenant_detail">
                                            <span className="tenant_label">{allTenant.lastName}</span>
                                            <span className="tenant_value">{allItem.lastName}</span>
                                        </div>
                                        <div className="current_tenant_detail">
                                            <span className="tenant_label">{allTenant.contactNumber}</span>
                                            <span className="tenant_value">{allItem.tenantContact[0].contactNumber}</span>
                                        </div>
                                        <div className="current_tenant_detail">
                                            <span className="tenant_label">{allTenant.email}</span>
                                            <span className="tenant_value">{allItem.emailId}</span>
                                        </div>
                                        <div className="current_tenant_detail">
                                            <span className="tenant_label">{allTenant.startDate}</span>
                                            <span className="tenant_value">{moment(allItem.agreementStartDate).format("DD MMM YYYY")}</span>
                                        </div>
                                        <div className="current_tenant_detail">
                                            <span className="tenant_label">{allTenant.endDate}</span>
                                            <span className="tenant_value">{moment(allItem.agreementEndDate).format("DD MMM YYYY")}</span>
                                        </div>
                                        <div className="current_tenant_detail">
                                            <span className="tenant_label">{allTenant.rent}</span>
                                            <span className="tenant_value">{allItem.rent}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }


                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default AllTenantHistory;
