import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import '../../styles/nav.scss';
import RouteLinks from '../../utils/route';
import AppNavBeforLogin from './before-login';
import AppNavAfterLogin from './after-login';
import { useSelector } from 'react-redux';
import NotificationCanvas from './components/notifaction-canvas';
import { useEffect, useState } from 'react';
import isTokenExpired from '../../utils/jwt-auth';
import { MarkAllRead, Notification } from '../../services/api/notification';
import { Image } from 'react-bootstrap';
import appTheme from "../../../app-theme.json"

const AppNav = ({ pathName }) => {

    const [showNotification, setShowNotification] = useState(false);
    const [reloadNotification, setReloadNotification] = useState(false);
    const [notificationList, setNotificationList] = useState([]);

    const authentication = useSelector((state) => state.isAuthenticated);

    const showNotifiationHandler = () => {
        setShowNotification(true);
    }

    const _Notification = async () => {
        const response = await Notification(isTokenExpired);
        if (response != null) {
            if (response.isSuccess) { setNotificationList(response.data); }
        }
    }
    const _MarkAllRead = async () => {
        const response = await MarkAllRead(isTokenExpired);
        if (response) {
            setShowNotification(false); setReloadNotification(!reloadNotification);
        }
    }

    const _MarkAllReads = async () => {
        const response = await MarkAllRead(isTokenExpired);
        if (response) { _Notification(); }
    }

    useEffect(() => {
        _Notification();
        if (pathName) {
            let _pathName = pathName.split("/");
            _pathName.forEach(pathItems => {
                if (pathItems == "chats") { _MarkAllReads() }
            });
        }
    }, [pathName, reloadNotification]);

    const beforeLogin = authentication ? "align-items-lg-center" : "align-items-lg-center before_login";

    return (
        <>
            <Navbar expand="lg" className='site-nav'>
                <Container fluid>
                    <Navbar.Brand as={Link} to={RouteLinks.Home}>
                        <Image src={appTheme.brand.brandLogo} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="site-nav-items" />
                    <Navbar.Collapse className="justify-content-end" id="site-nav-items">
                        <Nav className={`${beforeLogin}`}>
                            {authentication ?
                                <AppNavAfterLogin
                                    showNotifiationHandler={showNotifiationHandler}
                                    notificationList={notificationList} />
                                : <AppNavBeforLogin pathName={pathName} />
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <NotificationCanvas
                NotificationItems={{ _MarkAllRead, notificationList, showNotification, setShowNotification }}
            />
        </>
    );
};

export default AppNav;
