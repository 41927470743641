import React, { useEffect, useState } from 'react';
import { Image, Form } from 'react-bootstrap';
import GALLERY from "../../../../../appConfig.json";
import { FaEdit, FaEye } from "react-icons/fa";
import RouteLinks from "../../../../utils/route";
import { useNavigate } from 'react-router-dom';
import appTheme from "../../../../../app-theme.json";

const ListingItems = ({ listingItem, viewPropertyHandler, currentTenantHandler, allTenantHandler, currentTenantInfo, allTenantHistory, allStatus, tenant, removeTenantStatus }) => {

    const [bhkType, setBhkType] = useState("");
    const [activeStatus, setActiveStatus] = useState("");
    const [currentTenantValue, setCurrentTenantValue] = useState(1);
    const { propertyListingId, apartmentName, builtUpArea, bhkTypeId, rentalDetails, isDraft, isActive, isPublished, galleries, tenantDetails, isRentedOut } = listingItem;
    const newRentalDetails = rentalDetails ? rentalDetails : "";
    const navigate = useNavigate();

    const { status, setStatus } = allStatus;
    const { propertyImage, propertyRent, propertyStatus, propertyTenant } = appTheme.myListingPage.property;


    const allLookups = JSON.parse(localStorage.getItem("lookups"));
    useEffect(() => {
        if (allLookups) {
            allLookups.bhkTypes.forEach((items) => {
                if (items.bhkTypeId === bhkTypeId) {
                    setBhkType(items.type);
                }
            });
        }
        if (isDraft && isActive) { setActiveStatus("Draft"); }
        else if (isPublished && isActive) { setActiveStatus("Published"); }
        else if (!isActive) { setActiveStatus("Inactive"); }
    }, [listingItem]);


    const handleStatus = (e) => {
        setActiveStatus(e.target.value);
        setStatus({ activeStatus: e.target.value, propertyListingId: propertyListingId });
    }

    const changeContent = (status) => {
        switch (parseInt(status)) {
            case 1:
                return "Draft";
            case 2:
                return "Draft";
            case 3:
                return "Published";
            case 4:
                return "Published";
            case 5:
                return "Inactive";
            case 6:
                return "Inactive";
            default:
                break;
        }
    }

    useEffect(() => {
        if (!currentTenantInfo.showCurrentTenant) { setCurrentTenantValue(1); }
        if (!allTenantHistory.showAllTenant) { setCurrentTenantValue(1); }
    }, [currentTenantInfo.showCurrentTenant, allTenantHistory.showAllTenant]);

    useEffect(() => {
        if (!removeTenantStatus.removeTenant.showRemoveTenant) { setCurrentTenantValue(1); }
    }, [removeTenantStatus.removeTenant.showRemoveTenant]);


    const tenantHistoryHandle = (e) => {
        switch (parseInt(e.target.value)) {
            case 1:
                setCurrentTenantValue(1);
                break;
            case 2:
                removeTenantStatus.setRemoveTenant({ showRemoveTenant: true, listingItemId: propertyListingId });
                setCurrentTenantValue(2);
                break;
            case 3:
                currentTenantHandler(propertyListingId);
                setCurrentTenantValue(3);
                break;
            case 4:
                allTenantHandler(propertyListingId);
                setCurrentTenantValue(4);
                break;
            default:
                break;
        }

    }

    const editHandler = (id) => {
        navigate(`${RouteLinks.PropertyListing}/${id}${RouteLinks.PropertyLocality}`);
    }

    return (
        <>
            <tr>
                <td className="image_td"><Image src={`${galleries.length > 0 ? `${GALLERY.GALLERY_URL}/${galleries[0].name}` : propertyImage.noImage}`} /> </td>
                <td className="name_td">{apartmentName}</td>
                <td className="rent_td">{newRentalDetails.expectedRent ? newRentalDetails.expectedRent : propertyRent.noRent}</td>
                <td className="area_td">{builtUpArea}</td>
                <td className="bhk_td">{bhkType}</td>
                <td className="status_td">
                    {isRentedOut ? <p>{propertyStatus.published.isRentout}</p> :
                        <Form.Select aria-label="Default select example" onChange={handleStatus} value={activeStatus}>
                            <option>{parseInt(activeStatus) >= 1 ? changeContent(activeStatus) : activeStatus}</option>
                            {
                                activeStatus === "Draft" && <>
                                    <option value="1">{propertyStatus.draft.inactive}</option>
                                    <option value="2">{propertyStatus.draft.publish}</option>
                                </>
                            }
                            {
                                activeStatus === "Published" && <>
                                    <option value="3">{propertyStatus.published.inactive}</option>
                                    <option value="4">{propertyStatus.published.draft}</option>
                                </>
                            }
                            {
                                activeStatus === "Inactive" && <>
                                    <option value="5">{propertyStatus.inactive.activeAndPublish}</option>
                                    <option value="6">{propertyStatus.inactive.activeAndDraft}</option>
                                </>
                            }

                        </Form.Select>
                    }
                </td>
                <td className="tenant_td">
                    {isRentedOut ?
                        <Form.Select aria-label="Default select example" value={currentTenantValue} onChange={tenantHistoryHandle}>
                            {propertyTenant.isRentout.map((tenantItem) => {
                                return (
                                    <option key={tenantItem.tenantStatusId} value={tenantItem.tenantStatusValue}>{tenantItem.tenantStatusType}</option>
                                )
                            })}
                        </Form.Select>
                        :
                        isPublished ? <p className="add_tenant" onClick={() => tenant.setAddTenant({ showAddTenant: true, listingItemId: propertyListingId })}>{propertyTenant.addTenant.title}</p>
                            : null
                    }
                </td>
                <td className="edit_td">
                    {isRentedOut ? null : <FaEdit onClick={() => editHandler(propertyListingId)} />}
                </td>
                <td className="view_td"><FaEye onClick={() => viewPropertyHandler(propertyListingId)} /></td>
            </tr>
        </>

    )
}

export default ListingItems
