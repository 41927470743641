import * as Yup from 'yup';

const errorMessage = {
    city: "Please select city",
    propertySector: "Please select property sector",
    propertyType: "Please select property  type",
    locality: "Please enter locality",
    street: "Please enter street",
}

export const LocalityDetailsSchema = Yup.object({
    city: Yup.string().required(errorMessage.city),
    propertySector: Yup.string().required(errorMessage.propertySector),
    propertyType: Yup.string().required(errorMessage.propertyType),
    street: Yup.string().required(errorMessage.street),
    localityAddress: Yup.object().required(errorMessage.locality),
});