import React from 'react';
import { Accordion, Container, Row, Col, Form, Button } from 'react-bootstrap';
import appTheme from "../../../../../app-theme.json";
import { FaLongArrowAltRight } from "react-icons/fa";

const FAQsSection = () => {

    const { mainTitle, mainSubTitile, FAQsAccordionData, FAQsForms } = appTheme.FAQsPage.FAQsSection;

    return (

        <div className="faqs_section section_padding">
            <Container>
                <div className="main_title">
                    <h1 className="title">{mainTitle}</h1>
                    <p className="subtitle">{mainSubTitile}</p>
                </div>

                <div className="faqs_box">
                    <Row>
                        <Col lg={8} className="faqs_left_col">
                            <Accordion defaultActiveKey="0">
                                {
                                    FAQsAccordionData.map((items) => {
                                        return (
                                            <Accordion.Item eventKey={items.eventKey} key={items.id}>
                                                <Accordion.Header>{items.header}</Accordion.Header>
                                                <Accordion.Body>
                                                    <p className="paragraph">{items.paragraph}</p>
                                                    <div className="important_box">
                                                        {items.importantPoints.map((item) => {
                                                            return (
                                                                <div className="important_item" key={item.pointId}>
                                                                    <FaLongArrowAltRight />
                                                                    <p className="para">{item.point}</p>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    })
                                }
                            </Accordion>
                        </Col>
                        <Col lg={4} className="faqs_right_col mt-3 mt-lg-0">
                            <Form className="form_box">
                                <h3 className="title">{FAQsForms.title}</h3>
                                <Form.Group className="mb-3">
                                    <Form.Label>{FAQsForms.name.label}</Form.Label>
                                    <Form.Control type="text" placeholder={FAQsForms.name.placeholder} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{FAQsForms.email.label}</Form.Label>
                                    <Form.Control type="email" placeholder={FAQsForms.email.placeholder} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{FAQsForms.message.label}</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder={FAQsForms.message.placeholder} />
                                </Form.Group>
                                <Button className="submit_btn">{FAQsForms.button}</Button>
                            </Form>
                        </Col>
                    </Row>

                </div>
            </Container>
        </div>
    )
}

export default FAQsSection
