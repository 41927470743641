import ReducerActions from "../../utils/reducer-actions";

const reducer = (state = false, action) => {
    if (action.type === ReducerActions.redirectModal) {
        return action.payload;
    }

    return state;
};

export default reducer;
