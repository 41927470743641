import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaRegThumbsUp, FaUserTie, FaHandHoldingHeart, FaHome } from "react-icons/fa";
import appTheme from "../../../../../app-theme.json";

const ChooseUs = () => {

    const addfeaturesIcon = (featuresItem) => {

        switch (featuresItem.featuresGridDataId) {
            case 1:
                return { ...featuresItem, icon: <FaRegThumbsUp /> }
            case 2:
                return { ...featuresItem, icon: <FaUserTie /> }
            case 3:
                return { ...featuresItem, icon: <FaHandHoldingHeart /> }
            case 4:
                return { ...featuresItem, icon: <FaHome /> }
            default:
                break;
        }
    }

    let featuresGridData = appTheme.aboutPage.ChooseUsSection.featuresGridData.map((featuresItem) => addfeaturesIcon(featuresItem));
    featuresGridData.sort((a, b) => a.position - b.position);

    return (
        <div className="choose_us section_padding">
            <Container>
                <div className="main_title">
                    <h1 className="title">{appTheme.aboutPage.ChooseUsSection.mainTitle}</h1>
                    <p className="subtitle">{appTheme.aboutPage.ChooseUsSection.mainSubTitile}</p>
                </div>
                <div className="choose_box">
                    <Row className="choose_row">
                        {
                            featuresGridData.map((items) => {
                                if (items && items.isPublished)
                                    return (
                                        <Col lg={3} sm={6} className="choose_col p-0" key={items.featuresGridDataId}>
                                            <div className="feature">
                                                <div className="feature_icon">
                                                    {items.icon}
                                                </div>
                                                <div className="feature_content">
                                                    <h6 className="feature_title">{items.title}</h6>
                                                    <p className="feature_para">{items.paragraph}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                            })
                        }
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default ChooseUs
