import React, { useState } from 'react';
import "../../styles/profile/document-kyc.scss";
import { Col, Row, Image, Button } from 'react-bootstrap';
import { FaClock, FaFileAlt } from "react-icons/fa";
import ChangeDocument from '../../components/document-kyc/modal';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../state';
import appTheme from "../../../app-theme.json";

const dummyData = [
    {
        img: "/assets/img/documents/sample_aadhar_card.jpg",
    },
    {
        img: "/assets/img/documents/sample_pan_card.jpg",
    },
    {
        img: "/assets/img/documents/document_thumbnail.png",
    },
    {
        img: "/assets/img/documents/document_thumbnail.png",
    },
    {
        img: "/assets/img/documents/document_thumbnail.png",
    }
]

export const DocumentKyc = () => {
    const [document, setDocument] = useState("");
    const dispatch = useDispatch();
    const { changeDocument } = bindActionCreators(actionCreator, dispatch);

    const handleUpload = (img) => {
        setDocument(img);
        changeDocument(true)
    }

    return (
        <>
            <div className="document_kyc">
                <h4 className='title'>{appTheme.documentKycPage.title}</h4>
                <p className='para'>{appTheme.documentKycPage.paragraph}</p>
                <Row className='document_row'>
                    {
                        dummyData.map((item, index) => {
                            return (
                                <Col md={4} xl={3} className='mb-4' key={index}>
                                    <div className="document_contain">
                                        <div className="pending_contain">
                                            <FaClock />
                                            <span className="pending">Pending</span>
                                        </div>
                                        <span className="document_thumbnail">
                                            <Image className="w-100" src={item.img} />
                                        </span>
                                        <Button className="document_upload" onClick={() => handleUpload(item.img)}><FaFileAlt /> {appTheme.documentKycPage.button}</Button>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>

            <ChangeDocument document={document} />

        </>
    )
}

