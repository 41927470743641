import { Route, Routes } from 'react-router-dom';
import Index from './app/pages';
import RouteLinks from './app/utils/route';
import Authentication from './app/pages/authentication';
import PropertySearch from './app/pages/search-property/property-search';
import PropertyDetails from './app/pages/search-property/property-details';
import { RootLayout, ProfileLayout, AddPropertyLayout, EditPropertyLayout } from "./app/layout";
import { Dashboard, Chats, Profile, DocumentKyc, MyTenent, Tenancy, Favourite, PropertyListing } from "./app/pages/profile";
import { AboutUs, FAQs, NotFound, PrivacyPolicy, Blog, BlogDetail, TermAndConditionPage, Contact, Career, CareerDetails, ReferAndEarn } from "./app/pages/others";
import { AmenitiesTab, PropertyLocalityTab, PublishTab, RentalDetailTab, PropertyDetailTab, GalleryTab, EditPropertyLocality, EditPropertyDetail } from "./app/pages/add-edit-property";


const AppRoutes = () => {

  return (
    <Routes>
      <Route path={RouteLinks.Login} element={<Authentication />} />
      <Route path={RouteLinks.Home} element={<Index />} />
      <Route path={RouteLinks.PropertySearch} element={<PropertySearch />} />
      <Route path={`${RouteLinks.PropertyDetail}${RouteLinks.PropertyDetailId}`} element={<PropertyDetails />} />
      <Route path={RouteLinks.AboutUs} element={<AboutUs />} />
      <Route path={RouteLinks.Contact} element={<Contact />} />
      <Route path={RouteLinks.TermAndConditions} element={<TermAndConditionPage />} />
      <Route path={RouteLinks.FAQs} element={<FAQs />} />
      <Route path={RouteLinks.PrivacyPolicy} element={<PrivacyPolicy />} />
      <Route path={RouteLinks.Blog} element={<Blog />} />
      <Route path={RouteLinks.BlogDetail} element={<BlogDetail />} />
      <Route path={RouteLinks.Career} element={<Career />} />
      <Route path={RouteLinks.CareerDetails} element={<CareerDetails />} />
      <Route path={RouteLinks.ReferAndEarn} element={<ReferAndEarn />} />
      <Route element={<RootLayout />}>
        <Route element={<AddPropertyLayout />} >
          <Route path={`${RouteLinks.AddProperty}${RouteLinks.PropertyLocality}`} element={<PropertyLocalityTab />} />
          <Route path={`${RouteLinks.AddProperty}${RouteLinks.PropertyDetail}`} element={<PropertyDetailTab />} />
        </Route>
        <Route element={<EditPropertyLayout />} >
          <Route path={`${RouteLinks.PropertyListing}${RouteLinks.EditProperty}${RouteLinks.PropertyLocality}`} element={<EditPropertyLocality />} />
          <Route path={`${RouteLinks.PropertyListing}${RouteLinks.EditProperty}${RouteLinks.PropertyDetail}`} element={<EditPropertyDetail />} />
          <Route path={`${RouteLinks.PropertyListing}${RouteLinks.EditProperty}${RouteLinks.RentalDetail}`} element={<RentalDetailTab />} />
          <Route path={`${RouteLinks.PropertyListing}${RouteLinks.EditProperty}${RouteLinks.Amenities}`} element={<AmenitiesTab />} />
          <Route path={`${RouteLinks.PropertyListing}${RouteLinks.EditProperty}${RouteLinks.Gallery}`} element={<GalleryTab />} />
          <Route path={`${RouteLinks.PropertyListing}${RouteLinks.EditProperty}${RouteLinks.Publish}`} element={<PublishTab />} />
        </Route>
        <Route element={<ProfileLayout />}>
          <Route path={RouteLinks.Dashboard} element={<Dashboard />} />
          <Route path={RouteLinks.Profile} element={<Profile />} />
          <Route path={RouteLinks.PropertyListing} element={<PropertyListing />} />
          <Route path={RouteLinks.Favourite} element={<Favourite />} />
          <Route path={RouteLinks.Chats} element={<Chats />} />
          <Route path={`${RouteLinks.Chats}/:id`} element={<Chats />} />
          <Route path={RouteLinks.DocumentKyc} element={<DocumentKyc />} />
          <Route path={RouteLinks.Tenent} element={<MyTenent />} />
          <Route path={RouteLinks.Tenency} element={<Tenancy />} />
        </Route>
      </Route>
      <Route path={RouteLinks.NotFound} element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
