import React from 'react';
import { Image } from 'react-bootstrap';

const Banner = ({ bannerContent }) => {

    if (!bannerContent) {
        return
    }

    return (

        <div className="banners">
            <div className="banner_image">
                <Image src={bannerContent.image} />
            </div>
            <div className="banner_overlay">
                <div className="banner_content">
                    <h1 className="title">{bannerContent.title}</h1>
                    <p className="subtitle">{bannerContent.subTitle}</p>
                </div>
            </div>
        </div>
    )
}

export default Banner;
