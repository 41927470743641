import { FaDoorClosed, FaWifi, FaGasPump, FaHouseUser, FaSwimmingPool, FaFireExtinguisher, FaParking, FaShoppingCart, FaBatteryFull, FaBiohazard } from "react-icons/fa";
import { TbAirConditioning, TbHome2 } from "react-icons/tb";
import { GiRotaryPhone, GiWaterTank } from "react-icons/gi";
import { MdSportsCricket, MdPark, MdCleanHands } from "react-icons/md";


const amenitiesLoockups = (id, item, myAmenities) => {

    switch (id) {
        case 1:
            myAmenities.push({ type: item.type, icons: <FaDoorClosed /> });
            break;
        case 2:
            myAmenities.push({ type: item.type, icons: <FaWifi /> });
            break;
        case 3:
            myAmenities.push({ type: item.type, icons: <TbAirConditioning /> });
            break;
        case 4:
            myAmenities.push({ type: item.type, icons: <FaHouseUser /> });
            break;
        case 5:
            myAmenities.push({ type: item.type, icons: <GiRotaryPhone /> });
            break;

        case 6:
            myAmenities.push({ type: item.type, icons: <FaSwimmingPool /> });
            break;
        case 7:
            myAmenities.push({ type: item.type, icons: <MdSportsCricket /> });
            break;
        case 8:
            myAmenities.push({ type: item.type, icons: <FaFireExtinguisher /> });
            break;
        case 9:
            myAmenities.push({ type: item.type, icons: <TbHome2 /> });
            break;
        case 10:
            myAmenities.push({ type: item.type, icons: <FaShoppingCart /> });
            break;
        case 11:
            myAmenities.push({ type: item.type, icons: <FaGasPump /> });
            break;
        case 12:
            myAmenities.push({ type: item.type, icons: <FaShoppingCart /> });
            break;
        case 13:
            myAmenities.push({ type: item.type, icons: <MdPark /> });
            break;
        case 14:
            myAmenities.push({ type: item.type, icons: <GiWaterTank /> });
            break;

        case 15:
            myAmenities.push({ type: item.type, icons: <FaBiohazard /> });
            break;
        case 16:
            myAmenities.push({ type: item.type, icons: <MdCleanHands /> });
            break;
        case 17:
            myAmenities.push({ type: item.type, icons: <FaBatteryFull /> });
            break;
        case 18:
            myAmenities.push({ type: item.type, icons: <FaParking /> });
            break;
        default:
            break;
    }
}

export default amenitiesLoockups;