import * as Yup from 'yup';

const errorMessage = {
    firstName: { required: "First Name is required", valid: "" },
    lastName: { required: "Last Name is required", valid: "" },
    email: { required: "email is required", valid: "please enter valid email" },
}

export const profileSchema = Yup.object({
    firstName: Yup.string().required(errorMessage.firstName.required),
    lastName: Yup.string().required(errorMessage.lastName.required),
    email: Yup.string().email(errorMessage.email.valid).required(errorMessage.email.required),
})