import * as Yup from 'yup';

const errorMessages = {
    numberOfBathrooms: "please enter bathrooms number",
    numberOfBalcony: "please enter balcony number",
    waterSupplies: "Please select water supplies",
    propertyShownBy: "Please select property Shown By",

}

export const AmenitiesSchema = Yup.object({
    numberOfBathrooms: Yup.number().positive(errorMessages.numberOfBathrooms),
    numberOfBalcony: Yup.number().positive(errorMessages.numberOfBalcony),
    waterSupplies: Yup.number().positive(errorMessages.waterSupplies),
    propertyShownBy: Yup.number().positive(errorMessages.propertyShownBy),
});