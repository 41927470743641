import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import appTheme from "../../../../../app-theme.json";
import { MdLocationOn } from "react-icons/md";
import { FaEnvelopeOpen, FaPhoneAlt } from "react-icons/fa";


const ContactInfo = () => {

    const addInformationGridIcon = (informationGridItem) => {

        switch (informationGridItem.contactInfoId) {
            case 1:
                return { ...informationGridItem, icon: <MdLocationOn /> }
            case 2:
                return { ...informationGridItem, icon: <FaEnvelopeOpen /> }
            case 3:
                return { ...informationGridItem, icon: <FaPhoneAlt /> }
            default:
                break;
        }
    }

    let informationGridData = appTheme.contactPage.contactInformation.informationGridData.map((informationGridItem) => addInformationGridIcon(informationGridItem));
    informationGridData.sort((a, b) => a.position - b.position);

    return (

        <div className="contact_info_section section_padding">
            <Container>
                <div className="main_title">
                    <h1 className="title">{appTheme.contactPage.contactInformation.mainTitle}</h1>
                    <p className="subtitle">{appTheme.contactPage.contactInformation.mainSubTitile}</p>
                </div>
                <div className="contact_info">
                    <Row>
                        {informationGridData.map((items) => {
                            return (
                                <Col lg={4} className="mb-3" data-aos="zoom-in" key={items.contactInfoId}>
                                    <div className="info_box_bg">
                                        <div className="info_box">
                                            <div className="icon_box">{items.icon}</div>
                                            <span className="icon_label">{items.infoLabel}</span>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default ContactInfo
