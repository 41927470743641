import React, { useEffect } from 'react';
import "../../../styles/others/contact.scss";
import { MdLocationOn } from "react-icons/md";
import { FaEnvelopeOpen, FaPhoneAlt } from "react-icons/fa";
import ContactInfo from './components/contact-info';
import Aos from 'aos';
import appTheme from "../../../../app-theme.json";
import Banner from '../../../components/common/banner';
import ContactUs from './components/contact-us';
import BottomTopButton from '../../../components/common/bottom-top-button';

export const Contact = () => {

    const addContactComponents = (contactPageItem) => {

        switch (contactPageItem.componentName) {
            case "ContactInfo":
                return { ...contactPageItem, component: <ContactInfo /> }
            case "ContactUs":
                return { ...contactPageItem, component: <ContactUs /> }
            case "ContactBanner":
                return { ...contactPageItem, component: <Banner bannerContent={appTheme.contactPage.contactBannerContents} /> }
            case "BottomTopButton":
                return { ...contactPageItem, component: <BottomTopButton /> }
            default:
                break;
        }
    }

    let contactPageComponents = appTheme.contactPage.contactPageComponents.map((contactPageItem) => addContactComponents(contactPageItem));
    contactPageComponents.sort((a, b) => a.position - b.position);


    useEffect(() => { Aos.init(); }, []);

    return (
        <>
            {
                contactPageComponents.map((items) => {
                    if (items && items.isPublished)
                        return (
                            <section key={items.position}>{items.component}</section>
                        )
                })
            }
        </>
    )
}

