import axios from 'axios';
import AppSetting from '../../../appConfig.json';
import { errorHandler } from '../validation/error-handler';
import { httpHeadersWithToken } from '../../utils/http-requests';

export const Detail = async (isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { return; }
    else {
        try {
            const url = AppSetting.SERVER_URL + "/Client/Detail";
            const res = await axios.get(url, httpHeadersWithToken());
            return res.data;
        } catch (error) {
            return errorHandler(error);
        }
    }
}

export const Update = async (payload, isLoader, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoader(true);
        try {
            const url = AppSetting.SERVER_URL + "/Client/Update";
            const res = await axios.post(url, payload, httpHeadersWithToken());
            isLoader(false);
            return res.data;
        } catch (error) {
            isLoader(false);
            return errorHandler(error);
        }
    }
}

export const UpdateAvatar = async (payload, isLoader, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoader(true);
        try {
            const url = AppSetting.SERVER_URL + "/Client/UpdateAvatar";
            const res = await axios.post(url, payload, httpHeadersWithToken());
            isLoader(false);
            return res.data;
        } catch (error) {
            isLoader(false);
            return errorHandler(error);
        }
    }
}

export const UpdateAddress = async (payload, isLoader, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoader(true);
        try {
            const url = AppSetting.SERVER_URL + "/Client/UpdateAddress";
            const res = await axios.post(url, payload, httpHeadersWithToken());
            isLoader(false);
            return res.data;
        } catch (error) {
            isLoader(false);
            return errorHandler(error);
        }
    }
}