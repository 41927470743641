import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Col, Image, Row } from 'react-bootstrap';
import { FaBed, FaBuilding, FaHome, FaExpandArrowsAlt, FaStore, FaClock, FaCrosshairs, } from "react-icons/fa";
import configData from "../../../../../appConfig.json";
import { propertyDetail } from "../../../../utils/owl-carousel/options";
import moment from 'moment/moment';
import appTheme from "../../../../../app-theme.json";

export const PropertyOverview = ({ items }) => {

    const { locality, apartmentName, floorId, totalFloorId, builtUpArea, rentalDetails, amenities, galleries } = items.propertyDetails;
    const newRentaldetail = rentalDetails ? rentalDetails : "";
    const newAmenities = amenities ? amenities : "";
    const { expectedRent, availableFrom } = newRentaldetail;
    const { numberOfBalcony } = newAmenities;
    const posted = moment.utc(availableFrom).format("MMM Do YYYY");

    const { propertyDetails, propertySearchDetails } = appTheme;

    if (!galleries) { return }

    return (
        <section className="property_details">
            <div className='details_content'>
                <h1 className='property_title'>{items.bhkType} {items.apartmentType} in {locality}</h1>
                <p className='property_location'>{apartmentName}, near {locality}</p>
                <div className="price_details">
                    <p className='price'>&#8377;{expectedRent}</p>
                    <p className='posted_date'>Posted on {posted}</p>
                </div>
                <hr />
            </div>

            <Row className='pt-3'>
                <Col md={6} className="image_slider">
                    <OwlCarousel className='owl-theme' {...propertyDetail}>
                        {galleries.length > 0 ?
                            galleries && galleries.map((item, index) => {
                                return (
                                    <Image src={`${configData.GALLERY_URL}${item.name}`} key={index} />
                                )
                            }) : <Image src={appTheme.propertySearch.noImage} />
                        }

                    </OwlCarousel>
                </Col>
                <Col md={6} className='details_content'>
                    <Row className='property_description'>
                        <Col xs={6} className="property_label">
                            <FaHome />
                            <p>{propertySearchDetails.propertyDetails.apartmentName}</p>
                        </Col>
                        <Col xs={6} className="property_value">
                            <p>{apartmentName}</p>
                        </Col>
                    </Row>
                    <Row className='property_description'>
                        <Col xs={6} className="property_label">
                            <FaBed />
                            <p>{propertySearchDetails.propertyDetails.bedroom}</p>
                        </Col>
                        <Col xs={6} className="property_value">
                            <p>{items.bhkType}</p>
                        </Col>
                    </Row>
                    <Row className='property_description'>
                        <Col xs={6} className="property_label">
                            <FaBuilding />
                            <p>{propertyDetails.appartmentType}</p>
                        </Col>
                        <Col xs={6} className="property_value">
                            <p>{items.apartmentType}</p>
                        </Col>
                    </Row>
                    <Row className='property_description'>
                        <Col xs={6} className="property_label">
                            <FaBuilding />
                            <p>{propertyDetails.floor}</p>
                        </Col>
                        <Col xs={6} className="property_value">
                            <p>{floorId} {propertyDetails.floor}</p>
                        </Col>
                    </Row>
                    <Row className='property_description'>
                        <Col xs={6} className="property_label">
                            <FaBuilding />
                            <p>{propertyDetails.totalFloor}</p>
                        </Col>
                        <Col xs={6} className="property_value">
                            <p>{totalFloorId} {propertyDetails.floor}</p>
                        </Col>
                    </Row>
                    <Row className='property_description'>
                        <Col xs={6} className="property_label">
                            <FaStore />
                            <p>{propertySearchDetails.propertyDetails.balcony}</p>
                        </Col>
                        <Col xs={6} className="property_value">
                            <p>{numberOfBalcony} Balcony</p>
                        </Col>
                    </Row>
                    <Row className='property_description'>
                        <Col xs={6} className="property_label">
                            <FaClock />
                            <p>{propertyDetails.propertyAge}</p>
                        </Col>
                        <Col xs={6} className="property_value">
                            <p>{items.propertyAge}</p>
                        </Col>
                    </Row>
                    <Row className='property_description'>
                        <Col xs={6} className="property_label">
                            <FaExpandArrowsAlt />
                            <p>{propertyDetails.builtUpArea}</p>
                        </Col>
                        <Col xs={6} className="property_value">
                            <p>{builtUpArea} sq ft</p>
                        </Col>
                    </Row>
                    <Row className='property_description'>
                        <Col xs={6} className="property_label">
                            <FaCrosshairs />
                            <p>{propertyDetails.facing}</p>
                        </Col>
                        <Col xs={6} className="property_value">
                            <p>{items.propertyFacing}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    )
}
