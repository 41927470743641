import React, { useEffect, useState } from 'react';
import "../../../styles/mobile/profile-side-nav.scss";
import UserNameAndImage from '../../common/user-image';
import RouteLinks from '../../../utils/route';
import { FaUserAlt, FaListUl, FaHeart, FaUsers, FaFileAlt, FaComment, FaHome } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../state';
import appTheme from "../../../../app-theme.json";


const ProfileSideNav = () => {

    const [pathName, setPathName] = useState();

    const dispatch = useDispatch();
    const { showProfileSidebar } = bindActionCreators(actionCreator, dispatch);
    const showSidebar = useSelector((state) => state.showProfileSidebar);

    useEffect(() => {

        const interval = setInterval(() => {
            setPathName(window.location.pathname);
        }, 100);
        showProfileSidebar(false);
        return () => clearInterval(interval);
    }, [pathName]);

    const toggleSidebar = showSidebar ? "profile_sidebar show_sidbar" : "profile_sidebar ";

    return (
        <>

            <div className={toggleSidebar}>
                <UserNameAndImage />
                <div className="nav_links">
                    <NavLink className='tab_link' to={appTheme.dashboard.path}><FaHome />{appTheme.dashboard.title}</NavLink>
                    <NavLink className='tab_link' to={appTheme.myProfile.path}><FaUserAlt />{appTheme.myProfile.title}</NavLink>
                    <NavLink className='tab_link' to={appTheme.myListing.path}><FaListUl />{appTheme.myListing.title}</NavLink>
                    <NavLink className='tab_link' to={appTheme.myFavourite.path}><FaHeart />{appTheme.myFavourite.title}</NavLink>
                    <NavLink className='tab_link' to={appTheme.chats.path}><FaComment />{appTheme.chats.title}</NavLink>
                    <NavLink className='tab_link' to={appTheme.documentKyc.path}><FaFileAlt />{appTheme.documentKyc.title}</NavLink>
                    <NavLink className='tab_link' to={appTheme.myTenents.path}><FaUsers />{appTheme.myTenents.title}</NavLink>
                    <NavLink className='tab_link' to={appTheme.myTenenty.path}><FaUsers />{appTheme.myTenenty.title}</NavLink>
                    <NavLink className='tab_link' to={`${RouteLinks.AddProperty}${RouteLinks.PropertyLocality}`}><FaListUl />{appTheme.postProperty.title}</NavLink>
                </div>
            </div>

            {showSidebar && <div className="back_drop z_index" onClick={() => showProfileSidebar(false)} />}

        </>
    )
}

export default ProfileSideNav
