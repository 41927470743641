import React from 'react';
import { Button, Modal, Image, Spinner, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../state';
import { useFormik } from 'formik';
import isTokenExpired from '../../../utils/jwt-auth';
import configData from "../../../../appConfig.json"
import convertToBase64 from "../../../utils/convert-image/base-64";
import { changeImageSchema } from '../../../helper/validations/gallery-schema';
import { changeImageInitial } from '../../../helper/tabs/gallery';
import appTheme from "../../../../app-theme.json";
import { DeleteGallery, UploadGallery } from '../../../services/api/residential-rent';
import { useState } from 'react';

const ChangeImage = ({ changeImageDetail, refreshGallery, setRefreshGallery }) => {
    const dispatch = useDispatch();
    const { changeImage, isLoader, redirectModal } = bindActionCreators(actionCreator, dispatch);
    const showModal = useSelector((state) => state.changeImage);
    const showLoader = useSelector((state) => state.isLoader);
    const handleClose = () => { changeImage(false); resetForm(); }

    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setValues, resetForm } = useFormik({
        initialValues: changeImageInitial,
        validationSchema: changeImageSchema,
        onSubmit: async (values) => {
            if (values) {
                const payload = [{
                    propertyListingGalleryId: changeImageDetail.propertyListingGalleryId,
                    propertyListingId: changeImageDetail.propertyListingId,
                    name: changeImageDetail.name
                }]
                const deleteResponse = await DeleteGallery(payload, isLoader, isTokenExpired, redirectModal);
                if (deleteResponse != null) {
                    if (deleteResponse.isSuccess) {
                        let formData = new FormData();
                        formData.append('Files', values.changeFileImg);
                        formData.append('PropertyListingId', changeImageDetail.propertyListingId);
                        const response = await UploadGallery(formData, isLoader, isTokenExpired, redirectModal);
                        if (response != null) {
                            if (response.isSuccess) { setRefreshGallery(!refreshGallery); handleClose(); }
                            else { handleClose(); }
                        }
                    }
                }
            }
        },
    });

    const handleImage = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertToBase64(file);
        setValues({ changeImage: base64, changeFileImg: file });
    }

    return (
        <Modal show={showModal} onHide={handleClose} className='change_image_modal'>
            <Modal.Header closeButton>
                <Modal.Title>{appTheme.galleryDetails.changeModalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="change_image">
                    {
                        values.changeImage ? <Image src={values.changeImage} />
                            : <Image src={`${configData.GALLERY_URL}/${changeImageDetail.name}`} />
                    }
                    {errors.changeImage && touched.changeImage ? (<p className="form-error mt-2">{errors.changeImage}</p>) : null}
                </div>
                <div className="btn_box">
                    <Form.Group>
                        <Form.Label className='profile_pics'>{appTheme.galleryDetails.changeModalButton}<Form.Control size='sm' type='file' accept=".jpeg, .png, .jpg" onChange={handleImage} /></Form.Label>
                    </Form.Group>
                    <Button size='sm' className='submit_btn' variant='primary' onClick={handleSubmit} disabled={showLoader}>
                        {showLoader ? <Spinner animation="border" className='spinner_sm' /> : <>{appTheme.galleryDetails.submitModalButton}</>}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ChangeImage
