import axios from 'axios';
import AppSetting from '../../../appConfig.json';
import { errorHandler } from '../validation/error-handler';
import { httpHeadersWithToken } from '../../utils/http-requests';


export const ClientInterestedListing = async (isTokenExpired) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { return; }
    else {
        try {
            const url = AppSetting.SERVER_URL + "/ClientInterestedListing";
            const res = await axios.get(url, httpHeadersWithToken());
            return res.data;
        } catch (error) {
            return errorHandler(error);
        }
    }
}

export const Add = async (payload, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        try {
            const url = AppSetting.SERVER_URL + "/ClientInterestedListing/Add";
            const res = await axios.post(url, payload, httpHeadersWithToken());
            return res.data;
        } catch (error) {
            return errorHandler(error);
        }
    }
}

export const Remove = async (payload, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        try {
            const url = AppSetting.SERVER_URL + "/ClientInterestedListing/Remove";
            const res = await axios.post(url, payload, httpHeadersWithToken());
            return res.data;
        } catch (error) {
            return errorHandler(error);
        }
    }
}
