
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { initialAmenities } from "../../helper/tabs/amenities";
import { AmenitiesSchema } from "../../helper/validations/amenities-schema";
import { WaterSupplies, PropertyShownBy, Amenities } from "../../services/api/Lookups";
import { toast } from "react-toastify";
import { toastStyle } from "../../utils/http-requests";
import { AmenitiesDetail } from "../../services/api/residential-rent";
import isTokenExpired from "../../utils/jwt-auth";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreator } from "../../state";
import { useNavigate, useOutletContext } from "react-router-dom";
import appTheme from "../../../app-theme.json";
import RouteLinks from "../../utils/route";

const AmenitiesComp = () => {

  const [waterSupplies, setWaterSupplies] = useState([]);
  const [propertyShownBy, setPropertyShownBy] = useState([]);
  const [amenitiesLookup, setAmenitiesLookup] = useState([]);
  const [newAmenities, setNewAmenities] = useState([]);

  const dispatch = useDispatch();
  const { redirectModal, isLoader } = bindActionCreators(actionCreator, dispatch);
  const showLoader = useSelector((state) => state.isLoader);

  const navigate = useNavigate();
  const [localityData, setLocalityData, propertyListingData] = useOutletContext();
  const { propertyListingId, amenities } = propertyListingData;
  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: initialAmenities,
    validationSchema: AmenitiesSchema,
    onSubmit: async (values, action) => {
      if (values) {
        const payload = {
          propertyListingId: propertyListingId,
          numberOfBathrooms: parseInt(values.numberOfBathrooms),
          numberOfBalcony: parseInt(values.numberOfBalcony),
          waterSupplyId: parseInt(values.waterSupplies),
          heavingGym: values.heavingGym,
          isNonVegAllowed: values.isNonVegAllowed,
          havingGatedSecurity: values.havingGatedSecurity,
          directionsTipForTenants: values.directionsTips,
          propertyShownById: parseInt(values.propertyShownBy),
          amenities: values.availableAmenities.map((id) => { return { amenitiesId: parseInt(id) } })
        }
        const response = await AmenitiesDetail(payload, isLoader, isTokenExpired, redirectModal);
        if (response != null) {
          const { isSuccess, errorMessages, data, successMessage } = response;
          if (isSuccess) { toast.success(successMessage, toastStyle); navigate(`${RouteLinks.PropertyListing}/${data.propertyListingId}${RouteLinks.Gallery}`); }
          else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle); }); }
        }
      }
    }
  });

  const nextNavigate = () => {
    navigate(`${RouteLinks.PropertyListing}/${propertyListingId}${RouteLinks.Gallery}`);
  }

  const getWaterSupplies = async () => {
    const response = await WaterSupplies();
    if (response != null) {
      const { isSuccess, data, errorMessages } = response;
      if (isSuccess) { setWaterSupplies(data); }
      else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle) }) }
    }
  }

  const getPropertyShownBy = async () => {
    const response = await PropertyShownBy();
    if (response != null) {
      const { isSuccess, data, errorMessages } = response;
      if (isSuccess) { setPropertyShownBy(data); }
      else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle) }) }
    }
  }

  const getAmenities = async () => {
    const response = await Amenities();
    if (response != null) {
      const { isSuccess, data, errorMessages } = response;
      if (isSuccess) { setAmenitiesLookup(data.map((item) => { return ({ ...item, isChecked: false }) })); }
      else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle) }) }
    }
  }

  useEffect(() => {
    getWaterSupplies();
    getPropertyShownBy();
    getAmenities();
  }, []);

  useEffect(() => {
    if (amenities) {
      const { numberOfBathrooms, numberOfBalcony, waterSupplyId, directionsTipForTenants, propertyShownById } = amenities;
      setValues({
        ...values,
        numberOfBathrooms: numberOfBathrooms,
        numberOfBalcony: numberOfBalcony,
        waterSupplies: waterSupplyId,
        propertyShownBy: propertyShownById,
        directionsTips: directionsTipForTenants,
        availableAmenities: amenities.amenities ? amenities.amenities.map((id) => id.amenitiesId.toString()) : []
      });
    }

  }, [amenities, setValues]);

  const bathroomDecrementHandle = () => {
    if (values.numberOfBathrooms === 0) { setValues({ ...values, numberOfBathrooms: 0 }); }
    else { setValues({ ...values, numberOfBathrooms: values.numberOfBathrooms - 1 }); }
  }

  const bathroomIncrementHandle = () => {
    setValues({ ...values, numberOfBathrooms: values.numberOfBathrooms + 1 });
  }

  const balconyDecrementHandle = () => {
    if (values.numberOfBalcony === 0) { setValues({ ...values, numberOfBalcony: 0 }); }
    else { setValues({ ...values, numberOfBalcony: values.numberOfBalcony - 1 }); }
  }

  const balconyIncrementHandle = () => {
    setValues({ ...values, numberOfBalcony: values.numberOfBalcony + 1 });

  }

  useEffect(() => {

    let amenities = [...amenitiesLookup];
    amenities.forEach((item) => {
      values.availableAmenities.forEach((id) => { if (item.amenitiesId === parseInt(id)) { item.isChecked = true } })
    });
    setNewAmenities(amenities);

  }, [amenitiesLookup, values.availableAmenities]);

  const handleCheckbox = (e) => {
    const { value, checked } = e.target;
    const { availableAmenities } = values;

    let amenities = [...amenitiesLookup];
    amenities.forEach(item => { if (item.amenitiesId === Number(value)) { item.isChecked = checked } });
    setNewAmenities(amenities);

    if (checked) { setValues({ ...values, availableAmenities: [...availableAmenities, value], }); }
    else { setValues({ ...values, availableAmenities: availableAmenities.filter((e) => e !== value), }); }
  }

  return (
    <>
      <h6 className="tab_title">{appTheme.amenitiesDetails.title}</h6>
      <div className="tab_content">
        <Row className="mt-4">
          <Col md={5}>
            <Form.Group>
              <Form.Label className="form_label">{appTheme.amenitiesDetails.bathroom} *</Form.Label>
              <div className="inc_dec_box border">
                <Button size="sm" variant="light" className="dec_btn" onClick={bathroomDecrementHandle}>
                  <span>-</span>
                </Button>
                <span className="increment_input" >{values.numberOfBathrooms}</span>
                <Button size="sm" variant="light" className="inc_btn" onClick={bathroomIncrementHandle}>
                  <span>+</span>
                </Button>
              </div>
              {errors.numberOfBathrooms && touched.numberOfBathrooms ? (<p className="form-error">{errors.numberOfBathrooms}</p>) : null}
            </Form.Group>
          </Col>
          <Col md={5} className="mt-4 mt-md-0">
            <Form.Group>
              <Form.Label className="form_label">{appTheme.amenitiesDetails.balcony}</Form.Label>
              <div className="inc_dec_box border">
                <Button size="sm" variant="light" className="dec_btn" onClick={balconyDecrementHandle}>
                  <span>-</span>
                </Button>
                <span className="increment_input" >{values.numberOfBalcony}</span>
                <Button size="sm" variant="light" className="inc_btn" onClick={balconyIncrementHandle}>
                  <span>+</span>
                </Button>
              </div>
              {errors.numberOfBalcony && touched.numberOfBalcony ? (<p className="form-error">{errors.numberOfBalcony}</p>) : null}
            </Form.Group>
          </Col>

        </Row>
        <Row className="mt-4">
          <Col md={5}>
            <Form.Group>
              <Form.Label className="form_label">{appTheme.amenitiesDetails.propertyShown} *</Form.Label>
              <Form.Select size="sm" className="form_select" aria-label="Default select example" name="propertyShownBy" value={values.propertyShownBy} onChange={handleChange} onBlur={handleBlur}>
                <option value="">Select</option>
                {propertyShownBy.map((items) => {
                  return (
                    <option key={items.propertyShownById} value={items.propertyShownById}>{items.person}</option>
                  )
                })}
              </Form.Select>
              {errors.propertyShownBy && touched.propertyShownBy ? (<p className="form-error">{errors.propertyShownBy}</p>) : null}
            </Form.Group>
          </Col>
          <Col md={5} className="mt-4 mt-md-0">
            <Form.Group>
              <Form.Label className="form_label">{appTheme.amenitiesDetails.waterSpply}</Form.Label>
              <Form.Select size="sm" className="form_select" aria-label="Default select example" name="waterSupplies" value={values.waterSupplies} onChange={handleChange} onBlur={handleBlur} >
                <option value="">Select</option>
                {waterSupplies.map((items) => {
                  return (
                    <option key={items.waterSupplyId} value={items.waterSupplyId}>{items.type}</option>
                  )
                })}
              </Form.Select>
              {errors.waterSupplies && touched.waterSupplies ? (<p className="form-error">{errors.waterSupplies}</p>) : null}
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={10}>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label className="form_label">{appTheme.amenitiesDetails.addDirection}</Form.Label>
              <Form.Control size="sm" as="textarea" className="form_input" rows={5} placeholder="please enter direction." name="directionsTips" value={values.directionsTips} onChange={handleChange} onBlur={handleBlur} />
            </Form.Group>
          </Col>
        </Row>
        <h6 className="form_label mt-4">{appTheme.amenitiesDetails.availableAmenities}</h6>
        <Row className="mt-4">
          {newAmenities.map((items) => {
            return (
              <Col md={4} key={items.amenitiesId} className="form_check mb-2">
                <Form.Check type="checkbox" inline
                  label={items.type}
                  id={items.type}
                  name="availableAmenities"
                  value={items.amenitiesId}
                  onChange={handleCheckbox}
                  checked={items.isChecked}
                />
              </Col>
            )
          })}
        </Row>
        <div className="next_btn">
          <Button size="sm" className="mt-5 save" onClick={handleSubmit}>
            {showLoader ? <Spinner animation="border" className='spinner_sm' /> : <>{appTheme.amenitiesDetails.submitButton}</>}
          </Button>
          <Button size="sm" variant="primary" className="ms-auto mt-5 next" onClick={nextNavigate}>{appTheme.amenitiesDetails.nextButton}</Button>
        </div>
      </div>
    </>
  );
};

export default AmenitiesComp;
