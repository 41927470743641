import React from 'react';
import PropertyDetails from '../../components/property-listing/PropertyDetails';

export const PropertyDetailTab = () => {
    return (
        <div>
            <PropertyDetails />
        </div>
    )
}

