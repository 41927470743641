import axios from 'axios';
import AppSetting from '../../../appConfig.json';
import { errorHandler } from '../validation/error-handler';
import { config, httpHeaders, httpHeadersWithToken } from '../../utils/http-requests';


export const PropertyAndLocalityDetail = async (payload, isLoader, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoader(true);
        try {
            const url = AppSetting.SERVER_URL + "/ResidentialRent/PropertyAndLocalityDetail";
            const res = await axios.post(url, payload, httpHeadersWithToken());
            isLoader(false);
            return res.data;
        } catch (error) {
            isLoader(false);
            return errorHandler(error);
        }
    }
}

export const GetAll = async (isLoading, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true) }
    else {
        isLoading(true);
        try {
            const url = AppSetting.SERVER_URL + "/ResidentialRent/GetAll";
            const res = await axios.get(url, httpHeadersWithToken());
            isLoading(false);
            return res.data;
        } catch (error) {
            isLoading(false);
            return errorHandler(error);
        }
    }

}

export const GetById = async (payload, isLoading) => {
    isLoading(true);
    try {
        const url = AppSetting.SERVER_URL + `/ResidentialRent/GetById?propertyListingId=${payload}`;
        const res = await axios.get(url, httpHeaders);
        isLoading(false);
        return res.data;
    } catch (error) {
        isLoading(false);
        return errorHandler(error);
    }
}

export const GetNearProperties = async (payload) => {
    try {
        const url = AppSetting.SERVER_URL + `/ResidentialRent/GetNearProperties?propertyListingId=${payload}`;
        const res = await axios.get(url, httpHeaders);
        return res.data;
    } catch (error) {
        return errorHandler(error);
    }
}

export const RentalDetail = async (payload, isLoader, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoader(true);
        try {
            const url = AppSetting.SERVER_URL + "/ResidentialRent/RentalDetail";
            const res = await axios.post(url, payload, httpHeadersWithToken());
            isLoader(false);
            return res.data;
        } catch (error) {
            isLoader(false);
            return errorHandler(error);
        }
    }
}
export const AmenitiesDetail = async (payload, isLoader, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoader(true);
        try {
            const url = AppSetting.SERVER_URL + "/ResidentialRent/AmenitiesDetail";
            const res = await axios.post(url, payload, httpHeadersWithToken());
            isLoader(false);
            return res.data;
        } catch (error) {
            isLoader(false);
            return errorHandler(error);
        }
    }
}

export const UploadGallery = async (payload, isLoader, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoader(true);
        try {
            const url = AppSetting.SERVER_URL + "/ResidentialRent/UploadGallery";
            const res = await axios.post(url, payload, config());
            isLoader(false);
            return res.data;
        } catch (error) {
            isLoader(false);
            return errorHandler(error);
        }
    }
}

export const GetGallery = async (payload, isLoading, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoading(true);
        try {
            const url = AppSetting.SERVER_URL + `/ResidentialRent/GetGallery?propertyId=${payload}`;
            const res = await axios.get(url, httpHeadersWithToken());
            isLoading(false);
            return res.data;
        } catch (error) {
            isLoading(false);
            return errorHandler(error);
        }
    }
}

export const DeleteGallery = async (payload, isLoader, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoader(true);
        try {
            const url = AppSetting.SERVER_URL + `/ResidentialRent/DeleteGallery`;
            const res = await axios.post(url, payload, httpHeadersWithToken());
            isLoader(false);
            return res.data;
        } catch (error) {
            isLoader(false);
            return errorHandler(error);
        }
    }
}

export const Publish = async (payload, isLoader, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoader(true);
        try {
            const url = AppSetting.SERVER_URL + `/ResidentialRent/Publish?propertyListingId=${payload}`;
            const res = await axios.post(url, payload, httpHeadersWithToken());
            isLoader(false);
            return res.data;
        } catch (error) {
            isLoader(false);
            return errorHandler(error);
        }
    }
}

export const MarkDraft = async (payload, isLoader, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoader(true);
        try {
            const url = AppSetting.SERVER_URL + `/ResidentialRent/MarkDraft?propertyListingId=${payload}`;
            const res = await axios.post(url, payload, httpHeadersWithToken());
            isLoader(false);
            return res.data;
        } catch (error) {
            isLoader(false);
            return errorHandler(error);
        }
    }
}

export const MarkActive = async (payload, isLoader, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoader(true);
        try {
            const url = AppSetting.SERVER_URL + `/ResidentialRent/MarkActive?propertyListingId=${payload}`;
            const res = await axios.post(url, payload, httpHeadersWithToken());
            isLoader(false);
            return res.data;
        } catch (error) {
            isLoader(false);
            return errorHandler(error);
        }
    }
}

export const MarkInactive = async (payload, isLoader, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoader(true);
        try {
            const url = AppSetting.SERVER_URL + `/ResidentialRent/MarkInactive?propertyListingId=${payload}`;
            const res = await axios.post(url, payload, httpHeadersWithToken());
            isLoader(false);
            return res.data;
        } catch (error) {
            isLoader(false);
            return errorHandler(error);
        }
    }
}

export const GetPublished = async (isLoader, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoader(true);
        try {
            const url = AppSetting.SERVER_URL + `/ResidentialRent/GetPublished`;
            const res = await axios.get(url, httpHeadersWithToken());
            isLoader(false);
            return res.data;
        } catch (error) {
            isLoader(false);
            return errorHandler(error);
        }
    }
}

export const GetDraft = async (isLoader, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoader(true);
        try {
            const url = AppSetting.SERVER_URL + `/ResidentialRent/GetDraft`;
            const res = await axios.get(url, httpHeadersWithToken());
            isLoader(false);
            return res.data;
        } catch (error) {
            isLoader(false);
            return errorHandler(error);
        }
    }
}

export const GetInactive = async (isLoader, isTokenExpired, redirectModal) => {
    if (isTokenExpired() === true || isTokenExpired() === undefined) { redirectModal(true); }
    else {
        isLoader(true);
        try {
            const url = AppSetting.SERVER_URL + `/ResidentialRent/GetInactive`;
            const res = await axios.get(url, httpHeadersWithToken());
            isLoader(false);
            return res.data;
        } catch (error) {
            isLoader(false);
            return errorHandler(error);
        }
    }
}

