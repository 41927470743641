import React from "react";
import Banner from "../../../components/common/banner";
import TermsAndCondition from "./components/term-and-condition";
import appTheme from "../../../../app-theme.json";
import BottomTopButton from "../../../components/common/bottom-top-button";

export const TermAndConditionPage = () => {

    const addTandCComponent = (pageComponentItem) => {
        switch (pageComponentItem.componentName) {
            case "Banner":
                return { ...pageComponentItem, component: <Banner bannerContent={appTheme.termAndConditionPage.tAndCBannerContents} /> }
            case "TermsAndCondition":
                return { ...pageComponentItem, component: <TermsAndCondition /> }
            case "BottomTopButton":
                return { ...pageComponentItem, component: <BottomTopButton /> }

            default:
                break;
        }
    };

    let termAndConditionComponents = appTheme.termAndConditionPage.termAndConditionComponents.map((pageComponentItem) => addTandCComponent(pageComponentItem));
    termAndConditionComponents.sort((a, b) => a.position - b.position);


    return (
        <>
            {termAndConditionComponents.map((items) => {
                if (items && items.isPublished)
                    return (
                        <section key={items.position}>{items.component}</section>
                    )
            })}
        </>
    )
};
