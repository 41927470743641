import React from 'react';
import { Button, Modal, Image, Spinner, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../state';
import { useFormik } from 'formik';
import isTokenExpired from '../../../utils/jwt-auth';
import convertToBase64 from "../../../utils/convert-image/base-64";
import { changeDocumentInitial } from '../../../helper/profile/documents/initial-document';
import { changeDocumentSchema } from '../../../helper/validations/change-document-schema';
import appTheme from "../../../../app-theme.json";

const ChangeDocument = ({ document }) => {

    const dispatch = useDispatch();
    const { isLoader, redirectModal, changeDocument } = bindActionCreators(actionCreator, dispatch);
    const showModal = useSelector((state) => state.changeDocument);
    const showLoader = useSelector((state) => state.isLoader);


    const handleClose = () => { changeDocument(false); resetForm(); }

    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setValues, resetForm } = useFormik({
        initialValues: changeDocumentInitial,
        validationSchema: changeDocumentSchema,
        onSubmit: async (values) => {
            console.log(values);
            handleClose();
        },
    });

    const handleImage = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertToBase64(file);
        setValues({ changeDocment: base64 });
    }

    return (
        <Modal show={showModal} onHide={handleClose} className='change_image_modal'>
            <Modal.Header closeButton>
                <Modal.Title>{appTheme.documentKycPage.modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="change_image">
                    {
                        values.changeDocment ? <Image src={values.changeDocment} />
                            : <Image src={document} />
                    }
                    {errors.changeDocment && touched.changeDocment ? (<p className="form-error mt-2">{errors.changeDocment}</p>) : null}
                </div>
                <div className="btn_box">
                    <Form.Group>
                        <Form.Label className='profile_pics'>{appTheme.documentKycPage.changeButton}<Form.Control size='sm' type='file' accept=".jpeg, .png, .jpg" onChange={handleImage} /></Form.Label>
                    </Form.Group>
                    <Button size='sm' className='submit_btn' variant='primary' onClick={handleSubmit} disabled={showLoader}>
                        {showLoader ? <Spinner animation="border" className='spinner_sm' /> : <>{appTheme.documentKycPage.submitButton}</>}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ChangeDocument
