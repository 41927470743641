import React, { useEffect, useState } from 'react';
import "../styles/common/property-layout.scss";
import "../styles/common/addEdit-property.scss";
import { Container } from 'react-bootstrap';
import { NavLink, Outlet, useParams } from "react-router-dom";
import RouteLinks from '../utils/route';
import { FaCamera, FaHandshake, FaMapMarkerAlt, FaWallet, FaUniversity } from "react-icons/fa";
import { MdPublishedWithChanges } from "react-icons/md";
import UserNameAndImage from '../components/common/user-image';
import { GetById } from '../services/api/residential-rent';
import { toast } from 'react-toastify';
import { toastStyle } from '../utils/http-requests';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../state';
import appTheme from "../../app-theme.json";


export const EditPropertyLayout = () => {

    const [localityData, setLocalityData] = useState({});
    const [propertyListingData, setPropertyListingData] = useState({});
    const [pathName, setPathName] = useState();
    const [currentStatus, setCurrentStatus] = useState("Draft");
    const propertyListingId = useParams();

    const dispatch = useDispatch();
    const { hideFooter, isLoading, showPropertyPanel } = bindActionCreators(actionCreator, dispatch);

    const showPanel = useSelector((state) => state.showPropertyPanel);
    const loader = useSelector((state) => state.isLoader);

    const isLoadingFun = () => { }
    const getDataById = async (payload) => {
        const response = await GetById(payload, isLoadingFun);
        if (response != null) {
            const { isSuccess, data, errorMessages } = response;
            if (isSuccess) { setPropertyListingData(data); }
            else { errorMessages.forEach((error) => { toast.error(error.message, toastStyle); }); }
        }
    }

    useEffect(() => {
        getDataById(propertyListingId.id);
    }, [propertyListingId.id, loader]);

    useEffect(() => {
        if (propertyListingData) {
            const { isDraft, isActive, isPublished } = propertyListingData;
            if (isDraft && isActive && !isPublished) { setCurrentStatus("Draft"); }
            else if (isActive && isPublished && !isDraft) { setCurrentStatus("Published"); }
            else if (!isDraft && !isActive && !isPublished) { setCurrentStatus("Inactive") }
        }
    }, [propertyListingData]);

    useEffect(() => {
        hideFooter(true);
        return () => { hideFooter(false); }
    }, []);

    useEffect(() => {

        const interval = setInterval(() => {
            setPathName(window.location.pathname);
        }, 100);
        showPropertyPanel(false);
        return () => clearInterval(interval);
    }, [pathName]);

    const panelClass = showPanel ? "tab_btn show_panel" : "tab_btn";


    return (
        <>
            <div className="property_layout">
                <Container fluid className='px-0'>
                    <div className={panelClass}>
                        <UserNameAndImage />
                        <div className="nav_links">
                            <NavLink className='tab_link' to={`${RouteLinks.PropertyListing}/${propertyListingId.id}${RouteLinks.PropertyLocality}`}><FaMapMarkerAlt />{appTheme.propertyLayout.localityDetails}</NavLink>
                            <NavLink className='tab_link' to={`${RouteLinks.PropertyListing}/${propertyListingId.id}${RouteLinks.PropertyDetail}`}><FaUniversity />{appTheme.propertyLayout.propertyDetails}</NavLink>
                            <NavLink className='tab_link' to={`${RouteLinks.PropertyListing}/${propertyListingId.id}${RouteLinks.RentalDetail}`}><FaWallet />{appTheme.propertyLayout.rentalDetails}</NavLink>
                            <NavLink className='tab_link' to={`${RouteLinks.PropertyListing}/${propertyListingId.id}${RouteLinks.Amenities}`}><FaHandshake />{appTheme.propertyLayout.amenities}</NavLink>
                            <NavLink className='tab_link' to={`${RouteLinks.PropertyListing}/${propertyListingId.id}${RouteLinks.Gallery}`}><FaCamera />{appTheme.propertyLayout.gallery}</NavLink>
                            <NavLink className='tab_link' to={`${RouteLinks.PropertyListing}/${propertyListingId.id}${RouteLinks.Publish}`}><MdPublishedWithChanges />
                                {appTheme.propertyLayout.publish} ({currentStatus})
                            </NavLink>
                        </div>
                    </div>
                    <div className="property_tabs">
                        <Outlet context={[localityData, setLocalityData, propertyListingData, propertyListingId]} />
                    </div>
                </Container>
            </div>

            {showPanel && <div className='back_drop' onClick={() => showPropertyPanel(!showPanel)} />}
        </>
    )
}

