import React, { useEffect, useState } from 'react';
import { OtherProperty, Owner } from './index';
import { Button, Form, Image, Spinner, } from 'react-bootstrap';
import { FaPhoneAlt, FaArrowLeft } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreator } from '../../../../state';
import appTheme from "../../../../../app-theme.json";
import { initialSendQuery } from '../../../../helper/profile/property-details/send-query';
import { sendQuerySchema } from '../../../../helper/validations/profile/property-details/send-query';
import { useFormik } from 'formik';
import { SendQuery } from '../../../../services/api/send-query';
import { Link, useNavigate } from 'react-router-dom';
import RouteLinks from '../../../../utils/route';
import { toast } from 'react-toastify';
import { GetChatUsers } from '../../../../services/api/chat';
import { toastStyle } from '../../../../utils/http-requests';
import isTokenExpired from "../../../../utils/jwt-auth";


export const OwnerProfile = ({ nearProperty, propertyId, messageId }) => {

    const [chatUser, setChatUser] = useState({});
    const [chatLoader, setChatLoader] = useState(false);

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { showOwnerDetail, isLoader, recentPath } = bindActionCreators(actionCreator, dispatch);
    const showOwner = useSelector((state) => state.showOwnerDetail);
    const showLoader = useSelector((state) => state.isLoader);
    const authentication = useSelector((state) => state.isAuthenticated);

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm, setValues } = useFormik({
        initialValues: initialSendQuery,
        validationSchema: sendQuerySchema,
        onSubmit: async (values) => {
            const payload = {
                propertyListingId: propertyId.id,
                name: values.name,
                email: values.email,
                phoneNumber: `${values.phoneNumber}`,
                message: values.message,
            }
            const response = await SendQuery(payload, isLoader);
            if (response != null) {
                if (response.isSuccess) {
                    resetForm();
                    if (authentication) { navigate(`${RouteLinks.Chats}/${messageId}`); }
                    else { toast.success("Your query has been submitted", toastStyle); }
                }
            }
        }
    });

    const _GetChatUsers = async (userId) => {
        const response = await GetChatUsers(userId, setChatLoader, isTokenExpired);
        if (response != null) {
            const owner = response.data.find(x => x.isActiveChatUser);
            setChatUser(owner);
        }
    }

    useEffect(() => {
        if (messageId) { _GetChatUsers(messageId); }
        return () => setChatUser({});
    }, [messageId]);

    const navigateHandler = (to) => {
        recentPath(to);
        navigate(RouteLinks.Login);
    }

    const ownerDetails = showOwner ? "owner_details show_details" : "owner_details";

    return (
        <>
            <div className={ownerDetails}>
                <p className='arrow_left' onClick={() => showOwnerDetail(false)}><FaArrowLeft /></p>
                <div className="owner_content">
                    <Owner chatUser={chatUser} chatLoader={chatLoader} />
                    <div className="agent_form">
                        <div className="form_heading">
                            <FaPhoneAlt />
                            <p>{appTheme.propertySearchDetails.ownerDetails.formTitle}</p>
                        </div>
                        <Form className='form_container'>
                            <Form.Control type="text" placeholder="name" size='sm' value={values.name} name="name" onChange={handleChange} onBlur={handleBlur} />
                            {errors.name && touched.name ? (<p className="form_error mb-2">{errors.name}</p>) : null}
                            <Form.Control type="email" name="email" placeholder="Email ID" size='sm' value={values.email} onChange={handleChange} onBlur={handleBlur} />
                            {errors.email && touched.email ? (<p className="form_error mb-2">{errors.email}</p>) : null}
                            <Form.Control type="number" name="phoneNumber" placeholder="Phone Number" size='sm' value={values.phoneNumber} onChange={handleChange} onBlur={handleBlur} />
                            {errors.phoneNumber && touched.phoneNumber ? (<p className="form_error mb-2">{errors.phoneNumber}</p>) : null}
                            <Form.Control as="textarea" rows={3} placeholder='Ask Anything' value={values.message} name="message" onChange={handleChange} onBlur={handleBlur} />
                            {errors.message && touched.message ? (<p className="form_error my-2">{errors.message}</p>) : null}
                            <Button className="contact_now" onClick={handleSubmit}>
                                {showLoader ? <Spinner animation="border" className='spinner_sm' /> : "Contact Owner"}
                            </Button>
                        </Form>
                    </div>

                    <div className="owner_other_property">
                        <h4 className='title'>{appTheme.propertySearchDetails.ownerDetails.propertylistTitle}</h4>
                        {
                            <OtherProperty nearProperty={nearProperty} />
                        }

                    </div>
                </div>
                <div className="contact_owner">
                    {authentication ? <Button as={Link} to={`${RouteLinks.Chats}/${messageId}`}>{appTheme.propertySearchDetails.ownerDetails.submitButton}</Button>
                        : <Button size='sm' onClick={() => navigateHandler(`${RouteLinks.Chats}/${messageId}`)}>{appTheme.propertySearchDetails.ownerDetails.submitButton}</Button>
                    }
                </div>
            </div>

            {showOwner && <div className='back_drop' onClick={() => showOwnerDetail(false)} />}
        </>

    )
}

