import ReducerActions from "../../utils/reducer-actions";

const reducer = (state = false, action) => {
  if (action.type === ReducerActions.toggleAppLoader) {
    return action.payload;
  }
  return state;
};

export default reducer;
