import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { FaBed, FaBath } from "react-icons/fa";
import appConfig from "../../../../../appConfig.json";
import { Link } from 'react-router-dom';
import RouteLinks from '../../../../utils/route';

export const OtherProperty = ({ nearProperty }) => {

    const [bhkType, setBhkType] = useState("");

    const allLookups = JSON.parse(localStorage.getItem("lookups"));
    useEffect(() => {
        if (nearProperty && allLookups) {
            allLookups.bhkTypes.forEach((item) => {
                if (item.bhkTypeId === nearProperty.bhkTypeId)
                    setBhkType(item.type);
            });

        }

    }, [nearProperty]);

    if (!nearProperty) {
        return;
    }

    return (
        <div className="property">
            <Row className="text-decoration-none" as={Link} to={`${RouteLinks.PropertyDetail}/${nearProperty.propertyListingId}`}>
                <Col xs={5}>
                    <Image src={`${appConfig.GALLERY_URL}/${nearProperty.galleries[0].name}`} />
                </Col>
                <Col xs={7}>
                    <div className="short_info">
                        <div className='price'><span>₹ </span>{nearProperty.rentalDetails.expectedRent}</div>
                        <div className='address'>{nearProperty.locality.slice(0, 21)}{nearProperty.locality.length > 21 ? "..." : ""}</div>
                        <div className="bed_bath">
                            <div className='bed'><FaBed /> <span>{bhkType}</span></div>
                            <div className='bath'><FaBath /> <span>{nearProperty.amenities.numberOfBathrooms}</span></div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
